import React, { useState, useEffect } from 'react';
import './MobileNav.css';
import {Link, useHistory} from 'react-router-dom';
import Logo from '../Assets/Eval-logo.png';
import firebase from '../Firebase/Firebase';

export default function MobileNav(props) {

    const history = useHistory();

    const logoutHandler = () => {
        firebase.auth().signOut().then(() => {
            history.push('/home');
        });
      };

    return(
        <div className={"mobile_nav"}>
            <div className="mobile_nav_header">
                <img src={Logo} alt="Eval logo"/>
                <button className="cross_button" onClick={() =>  props.setOpen(false)} />
            </div>
            <p className="mobile_nav_title">Menu</p>
            <div className="mobile_nav_main_links">
                <Link to="/tests-info">Tests</Link>
                <Link to="/reports-info">Reports</Link>
                <Link to="/about">About</Link>
                <Link to="/contact">Contact</Link>
                <Link to="/blogs">Blogs</Link>
                {props.user? <Link to="/profile">My Account</Link> : <Link to="/login">Sign In</Link>}
                {props.user? <button onClick={logoutHandler}>Log Out</button> : null}
            </div>
            <div className="mobile_nav_side_links">
                <a href="https://www.uemsventures.com/" className="uems_link">by UEMS Ventures</a>    
            </div>
        </div>
    )
}