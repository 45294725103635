import React, {useEffect, useState} from 'react';
import './TrustedBy.css';
import StJohn from '../../Assets/TrustedBy/St-Johns-Logo.png';
import NtEdusys from '../../Assets/TrustedBy/NT-Edusys-logo.jpg';
import Pharmacad from '../../Assets/TrustedBy/pharmacad-logo.png';
import ProfAcademy from '../../Assets/TrustedBy/Professors-academy.jpg';
import Vcura from '../../Assets/TrustedBy/Vcura.JPG';
import Careerwise from '../../Assets/TrustedBy/Careerwise.png';
import {animated, useTransition} from "react-spring";

// Array containing all the logos
const imageArr = [{image: StJohn, id: 1}, {image: NtEdusys, id: 2},
    {image: Pharmacad, id: 3}, {image: ProfAcademy, id: 4}, {image: Vcura, id: 5}, {image: Careerwise, id: 6}];

// Container height
const HEIGHT = 200;

export default function TrustedBy() {
    const [currentItem, setCurrentItem] = useState(0); // The index of the item that disappeared to the left
    const [height, setHeight] = useState(200);

    useEffect(() => {
        if(window.innerWidth >= ((HEIGHT + 30) * 4)){
            setHeight(HEIGHT);
        } else{
            setHeight(((window.innerWidth)/3) - 15);
        }
        const windowResizeHandler = () => {
            if(window.innerWidth >= ((HEIGHT + 30) * 4)){
                setHeight(HEIGHT);
            } else{
                setHeight(((window.innerWidth)/3) - 15);
            }
        };
        window.addEventListener('resize', windowResizeHandler);
    },[]);

    useEffect(( )=> {
        setCurrentItem(item => {
            return (item + 1)%(imageArr.length);
        });
        const id = setInterval(() => {
            setCurrentItem(item => {
                return (item + 1)%(imageArr.length);
            })
        }, 3000);
        return(() => clearInterval(id))
    },[]);


    const transitions = useTransition(currentItem, item => item, {
        from: {left: "100%"},
        leave: {left: (window.innerWidth >= ((HEIGHT + 30) * 4))? "-25%" : "-35%"},
        config: {duration: (window.innerWidth >= ((HEIGHT + 30) * 4))? 12000 : 9000}
    });

    return (
        <div className="home_trusted_by_container" 
            style={(window.innerWidth >= ((HEIGHT + 30) * 4))?{width: ((height + 30) * 4 ) + "px", height: height + "px"}
            :{width: ((height + 15) * 3 ) + "px", height: height + "px"}}>
            {transitions.map(({item, props, key}) =>
                <animated.img className="home_trusted_by_item" src={imageArr[item].image} key={key} style={{...props, width: height}}/>
            )}
        </div>
    )
}