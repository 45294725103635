import React, {useEffect, useState} from "react";
import './ProfilePage.css';
import Cross from '../Assets/TestIcons/cross-button-icon.png';
import generalIcon from "../Assets/Homepage/general-eval-icon.png";
import humanitiesIcon from "../Assets/Homepage/humanities-eval-icon.png";
import engineeringIcon from "../Assets/Homepage/engineering-eval-icon.png";
import scienceIcon from "../Assets/Homepage/science-eval-icon.png";
import commerceIcon from "../Assets/Homepage/commerce-eval-icon.png";
import {useApolloClient, useMutation, useQuery} from "@apollo/client";
import gql from 'graphql-tag';
import firebase from "../Firebase/Firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import { format } from 'date-fns';
import {useHistory} from 'react-router-dom';
import {Snackbar} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import APP from '../services/app-service'
const GLOBAL = require('../services/global-constant');
const axios = require('axios').default;

const PURCHASED_TESTS_QUERY = gql`
    query PurchasedTests($email: String!) {
        purchases(where: {purchase_status: {id: {_eq: 2}}, user: {email: {_eq: $email}}}, order_by: {testId: asc}) {
            testId
            id
            updatedAt
            responses_aggregate {
                aggregate {
                    count
                }
            }
        }
        responses(where: {purchase: {user: {email: {_eq: $email}}}}, order_by: {createdAt: desc}) {
            id
            createdAt
            purchase {
                testId
            }           
        }
    }`;

const USER_INFO_QUERY = gql`
    query UserInfoQuery($email: String!) {
        users(where: {email: {_eq: $email}}) {
            name
            phoneNumber
        }
    }`;

const USER_INFO_UPDATE_MUTATION = gql`
    mutation UpdateUserInfo($email: String!, $name: String!, $phoneNumber: String!) {
        update_users(where: {email: {_eq: $email}}, _set: {name: $name, phoneNumber: $phoneNumber}) {
            returning {                
                name
                phoneNumber
              }
        }
    }`;

//HARDCODED TEST IDS

const TEST_ID = [
    {name: "General Eval", id: 1, icon: generalIcon},
    {name: "Eval for Humanities", id: 2, icon: humanitiesIcon},
    {name: "Eval for Engineering", id: 3, icon: engineeringIcon},
    {name: "Eval for Science", id: 4, icon: scienceIcon},
    {name: "Eval for Commerce", id: 5, icon: commerceIcon},
];

// HARDCODED LANGUAGE IDS

const LANGUAGE_ID = [
    {name: "English", id: 1}, {name: "हिंदी", id: 3}, {name: "मराठी", id: 4}, {name: "ગુજરાતી", id: 5},
];

const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
});

const snackbarButtonTheme = createMuiTheme({
    palette: {
        primary: {500: "#C0EEF9"},
    },
    overrides: {
        MuiButton: {
            root: {
                letterSpacing: "0",
                fontFamily: "Gibson-light, sans-serif",
                textTransform: "capitalize",
                fontSize: "1rem"
            }
        }
    }
});

// TODO: IMPLEMENT REPORTS AND MAKE THEM BE DOWNLOADABLE FROM THE REPORTS TAB HERE
export default function ProfilePage() {
    const [email, setEmail] = "Email"//useState(firebase.auth().currentUser.email);
    const [emailVerified, setEmailVerified] = useState(true);  //useState(firebase.auth().currentUser.emailVerified);
    const [tabNumber, setTabNumber] = useState(0); //Number to denote selected tab, 0 for tests, 1 for reports, 2 for personal info
    const [purchaseData, setPurchaseData] = useState(null); // Data of purchased tests
    const [purchaseDataLoading, setPurchaseDataLoading] = useState(true); // Loading state to show circular progress
    const [availableTests, setAvailableTests] = useState(null); // Data of tests not purchased
    const [reportData, setReportData] = useState(null);
    const [downloadLoading, setDownloadLoading] = useState(false); // Indicates whether a pdf is being downloaded, to render button or pdfdownloadlink
    // Modal states
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTestId, setModalTestId] = useState(null);
    const [modalReportId, setModalReportId] = useState(null);
    const [modalLanguageId, setModalLanguageId] = useState(1); // Initial state is set to id of language english

    // Snackbar states
    const [snackBarOpen, setSnackbarOpen] = useState(false);
    const [buttonSnackbarOpen, setButtonSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [buttonSnackbarMessage, setButtonSnackbarMessage] = useState("");

    // Snackbar close function for snackbar without button
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);    
    };

    // Snackbar close function for snackbar with button
    const handleButtonSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setButtonSnackbarOpen(false);    
    };


    const snackbarButtonHandler = () => {
        const user = firebase.auth().currentUser;
        user.sendEmailVerification().then(res => {
            console.log(res);
            setSnackbarMessage("An email with a verification link has been sent to " + user.email);
            setSnackbarOpen(true);
        }).catch(err => {
            console.log(err);
            if(err.code === "auth/too-many-requests"){
                setSnackbarMessage("You have sent too many requests from this device, please try again later");
                setSnackbarOpen(true);
            }
        });
    };

    const history = useHistory();


    // --------------------------------- MODAL BODY ------------------------------------------

    const modalBody = (<div className="profile_page_modal">
        <div className="profile_page_modal_row-1">
            <h2>Select report language</h2>
            <button onClick={() => setModalOpen(false)}><img src={Cross} alt="Close button"/></button>
        </div>
        <div className="profile_page_modal_row-2">
            {LANGUAGE_ID.map((languageItem, index) => {
                return(
                    <button key={"profile_page_modal_button" + index} className={(modalLanguageId === languageItem.id)? "selected" : ""} onClick={() => {
                        if(modalLanguageId !== languageItem.id){
                            setModalLanguageId(languageItem.id);
                        }
                    }}>{languageItem.name}</button>
                )
            })}
        </div>
        <button onClick={() => {
            history.push("/view-report-pdf/" + modalReportId + "/" + modalLanguageId);
            setModalOpen(false);
            }} className="profile_page_modal_action_button">PROCEED</button>
    </div>);

    // -------------------------------------- /MODAL BODY ----------------------------------

    useEffect(() => {
        setPurchaseDataLoading(true);
        axios.get(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getUserPurchases,APP.getToken())
        .then(function (response) {
            console.log("getUserPurchasesR",response);
            if(!response.data.error){
                const activeTestData = response.data.purchases.filter(item => !item.responses_aggregate);
                const testResponses = response.data.responses;
                setPurchaseData(activeTestData);
                //setPurchaseData(response.data.purchases);
                const testNotPurchased = TEST_ID.filter((testItem) => {
                    const matchIndex = activeTestData.findIndex((purchaseItem) => {
                        //console.log('purchaseItem',purchaseItem.testId === testItem.id);
                        return purchaseItem.testId === testItem.id
                    });
                    return matchIndex === -1;
                });
                setAvailableTests(testNotPurchased);
                setReportData(testResponses);
            }else{
            }
            setPurchaseDataLoading(false);
        })
        .catch(function (error) {
            console.log("error",error);
        });
    },[email]);

    console.log('availableTests',(true))

    //const client = useApolloClient();

    // LOAD ALL DATA ON FIRST LOAD
    // useEffect(() => {
    //     client.query({
    //         query: PURCHASED_TESTS_QUERY,
    //         variables: {
    //             email: email
    //         }
    //     }).then(res => {
    //             if(!res.loading){
    //                 setPurchaseDataLoading(false);
    //             }
    //             const activeTestData = res.data.purchases.filter(item => !item.responses_aggregate.aggregate.count);
    //             const testResponses = res.data.responses;
    //             setPurchaseData(activeTestData);
    //             const testNotPurchased = TEST_ID.filter((testItem) => {
    //                 const matchIndex = activeTestData.findIndex((purchaseItem) => {
    //                     return purchaseItem.testId === testItem.id
    //                 });
    //                 return matchIndex === -1;
    //             });
    //             setAvailableTests(testNotPurchased);
    //             setReportData(testResponses);
    //         }
    //     ).catch(err =>
    //         console.log(err)
    //     );        
    // },[email, client]);


    // Button click handler for unpurchased test items action button, opens razor pay and allows user to purchase test.
    const buyTestHandler = (testId) => {
        history.push("/checkout/" + testId);
        // firebase.auth().currentUser.reload().then(res => {
        //     const user = firebase.auth().currentUser;
        //     if(emailVerified){
        //         history.push("/checkout/" + testId);
        //     }else {
        //         setButtonSnackbarMessage("You must verify your email before proceeding");
        //         setButtonSnackbarOpen(true);
        //     }
        // });  
    };

    // Button click handler for report items view button, opens report page
    const reportViewHandler = (reportId) => {
        history.push("/view-report/" + reportId);
    };

    // Button click handler for report items download button, opens report pdf page
    const reportDownloadHandler = (reportId) => {
        // const doc = <ReportPdf reportId={reportId}/>; 
        // const blob = pdf(doc).toBlob();
        // console.log(blob);


        // window.open("/view-report-pdf/" + reportId);
        setModalReportId(reportId);
        setModalOpen(true);
    };

    
    const resendEmailHandler = () => {
        const user = firebase.auth().currentUser;
        user.sendEmailVerification().then(res => {
            console.log(res);
            setSnackbarMessage("An email with a verification link has been sent to " + user.email);
            setSnackbarOpen(true);
        }).catch(err => {
            console.log(err);
            if(err.code === "auth/too-many-requests"){
                setSnackbarMessage("You have sent too many requests from this device, please try again later");
                setSnackbarOpen(true);
            }
        });
    };


    // --------------------------------- CONTENT OF TAB ------------------------------
    const TabContent = () => {
        if(tabNumber===0){
            return(
                <div className="profile_page_tests_tab">
                    {(purchaseData && purchaseData.length)?<div className="profile_page_tests_purchased">
                        <h3>Tests Purchased</h3>
                        <div className="profile_page_items_container">
                            {purchaseData.map((purchaseItem, index) =>{
                                const testItem = TEST_ID.filter(testData => testData.id === purchaseItem.testId)[0];
                                return(
                                    <div className="profile_page_item" key={"purchase"+index}>
                                        <img src={testItem.icon}
                                             alt={testItem.name}/>
                                        <p className="profile_page_item_title">{testItem.name}</p>
                                        <div className="profile_page_item_content">
                                            <p className="profile_page_item_text">purchased on: <br />{format(new Date(purchaseItem.updatedAt), "dd-MM-yyyy")}</p>
                                            <button className="profile_page_item_action-button" onClick={() => {                                                
                                                history.push("/test/" + purchaseItem.testId);
                                            }}>Start</button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>: null}
                    {(availableTests && availableTests.length)?<div className="profile_page_tests_not_purchased">
                        {emailVerified? null: <p className="profile_page_tests_not_purchased_verify_email_text">
                            You will not be able to purchase a test until you verify your Email ID. Check your inbox at {email} for our 
                            verification link. After completing the verification please reload the page and proceed to explore your interests
                            <span onClick={resendEmailHandler}> Resend email</span></p>}
                        <h3>{(purchaseData && purchaseData.length)? "You may also like" : "Eval Tests"}</h3>
                        <div className="profile_page_items_container">
                            {availableTests.map((data, index) =>
                                <div className="profile_page_item" key={"unpurchase" + index}>
                                    <img src={data.icon} alt={data.name}/>
                                    <p className="profile_page_item_title">{data.name}</p>
                                    {/*HARDCODED PRICE OF TEST*/}
                                    <div className="profile_page_item_content">
                                        <button className="profile_page_item_action-button"
                                                onClick={() => buyTestHandler(data.id)}>Get test- ₹500</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>: null}
                </div>
            )
        }
        else if(tabNumber === 1){
            return (
                <div className="profile_page_reports_tab">
                        {reportData.map((reportItem, index) => {
                            const testItem = TEST_ID.filter(testData => testData.id === reportItem.testId)[0];
                            return (<div className="profile_page_item" key={"reports"+index}>
                                    <img src={testItem.icon} alt={testItem.name}/>
                                    <p className="profile_page_item_title">{testItem.name}</p>
                                    <p className="profile_page_item_text">{format(new Date(reportItem.createdAt), "dd-MM-yyyy")}</p>
                                    <div className="profile_page_item_content">
                                        <button className="profile_page_item_action-button"
                                                onClick={() => reportViewHandler(reportItem.id)}>View </button>                                    
                                        <button className="profile_page_item_action-button"
                                                onClick={() => reportDownloadHandler(reportItem.id)}>Download</button>
                                    </div>
                                </div>)
                        }
                    )}
                </div>
            )
         }
        else if(tabNumber === 2){
            return <UserInfoTab email={email}/>                
        }
    };

    return(
        <div className="profile_page">
            <Dialog maxWidth={false} open={modalOpen} onClose={() => setModalOpen(false)}>
                {modalBody}
            </Dialog>
            <div className="profile_page_tab_selection">
                <h2 className={tabNumber===0? "selected" : ""} onClick={() => setTabNumber(0)}>Your tests</h2>
                {reportData && reportData.length?<h2 className={tabNumber===1? "selected" : ""} onClick={() => setTabNumber(1)}>Past Reports</h2> : null}
                <h2 className={tabNumber===2? "selected" : ""} onClick={() => setTabNumber(2)}>Your info</h2>
            </div>
            <div className="profile_page_tab_content">
                {purchaseDataLoading? <div className="profile_page_progress_container">
                    <ThemeProvider theme={progressTheme}>
                        <CircularProgress size={30} color="primary"/>
                    </ThemeProvider>
                </div>:null}
                {purchaseData?<TabContent/> : null}
            </div>
            <ThemeProvider theme={snackbarButtonTheme}>
                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }} open={buttonSnackbarOpen} autoHideDuration={3000} onClose={handleButtonSnackbarClose} message={buttonSnackbarMessage}
                    action={
                        <Button color="primary" size="small" onClick={snackbarButtonHandler}>Resend Email</Button>
                    }/>
                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }} open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} message={snackbarMessage}/>
            </ThemeProvider>
        </div>
    )
}

// ------------------------------------------- Tab content when tabIndex = 2 i.e. "your info" section ------------------------------------
const UserInfoTab = (props) => {

    // Personal Info states
    // const [userInfoLoading, setUserInfoLoading] = useState(true);
    const [email, setEmail] = useState(props.email);
    const [username, setUsername] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [infoActionBtnDisabled, setInfoActionBtnDisabled] = useState(true);
    const [infoUpdateLoading, setInfoUpdateLoading] = useState(false);

    // Mutation that updates user personal info from the "your info" tab
    const [updateUser] = useMutation(USER_INFO_UPDATE_MUTATION);

    // Snackbar states
    const [snackBarOpen, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [loading, setloading] = useState(true);
    // Snackbar close function
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    // Use snackbar function
    const openSnackbarWithMessage = (message) => {
        setSnackbarMessage(message);
        setOpen(true);
    }

    axios.get(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getUserDetails,APP.getToken())
    .then(function (response) {
        console.log("response",response);
        if(!response.data.error){
            setEmail(response.data.users.email);
            setUsername(response.data.users.name);
            setPhoneNumber(response.data.users.phoneNumber);
            setloading(false);
        }else{
        }
    })
    .catch(function (error) {
        console.log("error",error);
    });


    // Button click handler for the action button in the "your info" tab, active when any user info is changed, sets the user info to new values
    const infoActionBtnHandler = () => {
        setInfoUpdateLoading(true);
        if(!username){
            openSnackbarWithMessage("Username cannot be empty");        
            setInfoUpdateLoading(false);
        }else if(/([a-z]|[A-Z])/.test(phoneNumber)){
            openSnackbarWithMessage("Invalid phone number");
            setInfoUpdateLoading(false);
        }else {
            // TODO: FOLLOWING MANUAL PHONE VALIDATION HARDCODES REQUIREMENT FOR INDIAN NUMBERS
            // USE SAME LOGIC IN LOGIN PAGE sign up SECTION FOR PHONE NUMBER VALIDATION
            let newPhone = "";
            if(phoneNumber.length === 10){
                newPhone = "+91" + phoneNumber;
            } else if((phoneNumber.length === 11 && phoneNumber.substr(0,1) !== "+")||(phoneNumber.length === 12 && phoneNumber.substr(0,1) !== "+")){
                newPhone = "+" + phoneNumber;
            } else if(phoneNumber.length === 13 || (phoneNumber.length === 12 && phoneNumber.substr(0,1) === "+")){
                newPhone = phoneNumber;
            } else {
                openSnackbarWithMessage("Invalid phone number, please enter your number without any dashes or special symbols");
                setInfoUpdateLoading(false);
                return null;
            }
            updateUser({
                variables: {
                    email,
                    name: username,
                    phoneNumber: newPhone,
                }
            }).then(res => {
                const userData = res.data.update_users.returning[0];
                setUsername(userData.name);
                setPhoneNumber(userData.phoneNumber);
                setInfoUpdateLoading(false);
                openSnackbarWithMessage("Updated successfully");
                setInfoActionBtnDisabled(true);
            }).catch(err => {
                console.log(err);
                setInfoUpdateLoading(false);
                openSnackbarWithMessage("Something went wrong");
            });
        }          
    };

    //TODO: IMPLEMENT ERROR

    if(!loading) {
        return (
            <div className="profile_page_info_tab">
                <div className="profile_page_info_tab_row">
                    <div className="profile_page_info_tab_row_label">
                        <p>Email</p>
                    </div>
                    <div className="profile_page_info_tab_row_content">
                        <p>{email}</p>
                    </div>
                </div>
                <div className="profile_page_info_tab_row">
                    <div className="profile_page_info_tab_row_label">
                        <p>Username</p>
                    </div>
                    <div className="profile_page_info_tab_row_content">
                        <input value={username} onChange={(e) => {
                            setUsername(e.target.value);
                            setInfoActionBtnDisabled(false);
                        }}/>
                    </div>
                </div>
                <div className="profile_page_info_tab_row">
                    <div className="profile_page_info_tab_row_label">
                        <p>Phone</p>
                    </div>
                    <div className="profile_page_info_tab_row_content">
                        <input value={phoneNumber} onChange={(e) => {
                            setPhoneNumber(e.target.value);
                            setInfoActionBtnDisabled(false);
                        }}/>
                    </div>
                </div>
                {infoUpdateLoading? <div className="profile_page_info_tab_progress_container">
                    <ThemeProvider theme={progressTheme}>
                        <CircularProgress size={"2rem"} color="primary"/>
                    </ThemeProvider>
                </div>:<button className="profile_page_info_tab_action_button" disabled={infoActionBtnDisabled}
                    onClick={infoActionBtnHandler}>SAVE CHANGES</button>}
                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }} open={snackBarOpen} style={{position: "fixed"}} autoHideDuration={3000} 
                    onClose={handleSnackbarClose} message={snackbarMessage} key="snackbar"                 
                />
            </div>
        )
    }else return <div className="profile_page_progress_container">
            <ThemeProvider theme={progressTheme}>
                <CircularProgress size={30} color="primary"/>
            </ThemeProvider>
        </div>
    
}