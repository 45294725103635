import React from 'react';
import './ViewBlog.css';
import CareerVsSkills from '../Assets/BlogsPage/careervsskills.jpg';
import Ikigai from '../Assets/BlogsPage/ikigai.png';
import CareerAfter12th from '../Assets/BlogsPage/career-after-12th.jpg';
import CareerInterestAssessments from '../Assets/BlogsPage/career-interest-assessments.png';
import TopFiveFactors from '../Assets/BlogsPage/top-5-factors-career-selection.jpg';
import SafetyNet from '../Assets/BlogsPage/education-safety-net.jpg';
import { Switch, Route } from 'react-router';

export default function ViewBlog(){
    return(
        <div className="view_blog_page">
            <Switch>

                {/* --------------------- Career Exploration vs. Skills -------------------------------------- */}
                <Route path="/blogs/read/career-vs-skills" exact>
                    <h1>Introduction to Career Exploration</h1>
                    
                    <img className="view_blog_page_contain" src={CareerVsSkills} alt="Career Exploration versus Skills"/>
                    
                    <p><strong>Your profession is the primary identity you develop as an adult.</strong> Reflect on this—while you are 
                    still in school or college every time you meet someone new, you share your name and other details, and soon after you 
                    share that you are a student. Once you complete your formal education, the most common way to greet people in the adult 
                    world shifts to sharing your profession—people bond over their professions, build networks with people, and make it a 
                    central part of their and other people’s identity and remember them based on that as well.</p>

                    <p>Apart from career’s importance in identity formation, it is also <strong>anessential factor in helping
                    someone fulfil their life goals</strong>, whether it is to save lives by being a Doctor or to work in the 
                    field of technology and innovation by being a Data Engineer. We derive deep satisfaction from our profession 
                    and so picking a career is one of the most important decisions we make in our lives.</p>

                    <p>However, without career guidance it is unrealistic to expect that every person that picks their subjects in Grade 11 
                    with a particular career path in mind, will be satisfied with that decision when they are 20 years old, or 35, or even 50! 
                    <strong>We pick subjects in high school and college based on our interest, our friends’ interests, our parents’ 
                    expectations, and our decision making is muddled with fear and insecurity about our capability</strong>. We are simply 
                    too young to know for sure which career path we want to pursue for the rest of our lives.</p>

                    <p>Career Psychologists acknowledge this and thus they describe <strong>career exploration as a life-
                    long process</strong>. As our knowledge and understanding about our strengths, weaknesses, interests, 
                    life goals evolves, our career goals can also evolve. Moreover, in this rapidly changing world, there are 
                    new types of career paths emerging every few years and so many times when we are picking a career, we pick 
                    based on our knowledge of the existing job market and not based on hypotheses about emerging job fields. There 
                    are several YouTubers today that grew up in a time where nothing even close to digital or social media existed.</p>

                    <p>One can never be too sure of the career they pick, however, if you pick your career and make your education decisions 
                    based on your interests rather than the job market, you will be equipped with skills and knowledge relevant and 
                    transferable to any job context. It is thus important that <strong>when selecting subjects in schools you select 
                    them based on skills you wish to acquire, instead of based on a job you want</strong>, because we cannot predict 
                    the job market, but we can be prepared to deal with its evolution.</p>
                </Route>


                {/* ----------------------------------------- Ikigai --------------------------------------- */}

                <Route path="/blogs/read/how-to-find-your-career-path" exact>
                    <h1>How to find your career path?</h1>
                    
                    <img className="view_blog_page_contain" src={Ikigai} alt="How to find your career path"/>
                    
                    <p>The ancient Japanese concept Ikigai is an insightful model to explore one’s life’s purpose and understand what kind 
                    of impact they want to have in the world. While Ikigai is a broader concept which connects to lifestyle and 
                    spirituality, in this context we are only exploring it from a career perspective.</p>

                    <p>The image above is commonly used to explain Ikigai and visually represents that your purpose of being on earth is 
                    an amalgamation of your passion, mission, vocation and profession all of which are also relevant when picking the right 
                    career path. It is important to understand that as the world evolves, careers, professions, and vocations evolve as well, 
                    to keep up with the new demands of the dynamic world we live in. Hence, it is essential that you understand that careers 
                    are not static and that they evolve over time. It is thus ideal to <strong>pick a profession in a field that you 
                    love</strong>; because it is only with genuine interest in the field that you can keep up with the changing demands 
                    that field will undergo and continue to be passionate about the work you do.</p>

                    <p>While it is difficult to know for sure what is it that you love doing, a simple way to decipher it is to 
                    <strong>observe your behaviour to find out when you feel most at peace and joyful</strong>. Even if the task that 
                    you are performing is not career worthy, it will give you insight into the kind of tasks you would love to perform 
                    as a professional. For example, you might enjoy listening to people’s experiences, their pain points and thinking 
                    of ways to improve their experience and eliminate the pain-points. While this task does not directly relate to a 
                    profession, it is a principle that is common across several vocational activities. From a Hotel Manager, to a UX 
                    Designer, or even a Psychologist, all three career paths require the skill mentioned in the example above. This shows 
                    that one task/ behaviour—<i>listening to people’s experiences and pain points and working towards improving it</i>—is 
                    related to careers in the field of commerce, science, and humanities. It is thus up to you, to embark on the journey 
                    inward, requiring <strong>self-exploration, self-awareness, and observation</strong> to identify what are those tasks 
                    that give you joy so counsellors or mentors can help you connect them to practical professions in the world.</p>

                    <p>Digging deep within yourself to learn about your interests is easier said than done, which is why a career 
                    guidance test like Eval exists - which helps you to systematically approach your passions, interests, and 
                    professional goals, to gain a clear understanding of what your career and education path can and should be.</p>
                </Route>

                {/* ----------------------------------------- Which Career to Choose after 12th --------------------------------------- */}

                <Route path="/blogs/read/which-career-to-choose-after-12th" exact>
                    <h1>Which Career to Choose after 12th?</h1>
                    
                    <img className="view_blog_page_contain" src={CareerAfter12th} alt="Which career to choose after 12th"/>
                    
                    <p>Picking your career is one of the most important and daunting decisions you will make as a student. It will set the 
                    course of your adult life and will become an integral part of your identity. Career selection requires self-reflection 
                    and a willingness to explore options so that you can pick what is best for you. We cannot tell you <strong>what</strong> to pick, but we 
                    can help you with understanding <strong>how</strong> to pick the right career path.</p>

                    <p>There are several factors that can affect career decisions, like parental pressure, education qualification, life goals 
                        etc. But today, we will share <strong>3 important factors</strong> that need to be considered when selecting a career:</p>

                    <ol>
                        <li><strong>Interest</strong>: Picking a career in the field that you are interested in allows you the privilege of 
                        enjoying your work and feeling content. It makes it easier to study subjects for your degree because you will enjoy 
                        what you are studying and it also leads to a happier life because you are doing work that you like.</li>
                        <li><strong>Personality</strong>: It is important to find a career that fits your personality. If you are someone that 
                        likes to sit at a desk with a computer and work alone, and end up in a career path that requires frequent people 
                        interaction, then you will not enjoy your work. It is thus important that you take your personality into consideration 
                        when picking a career like, do you like working alone or with people? Do you like working in high pressure environments? 
                        Do you enjoy working outdoors or in an office?</li>
                        <li><strong>Skills</strong>:  It is essential to consider what skills and knowledge you have while picking a career. 
                        For example, if you have poor analytical skills and find it hard to work with numbers, you should avoid working in the 
                        finance sector. Similarly, if you have very strong communication skills and can talk to anybody effectively, then you 
                        should strongly consider working in jobs that require client interaction. You can also develop your skills by selecting 
                        the right education courses.</li>
                    </ol>       

                    <p>The right career for you is the one where your <strong>skills, interests, and personality combine and match well with the 
                        requirements and responsibilities of the job</strong>. It can be confusing to understand how your skills, interests, and 
                        personality relate to careers, but it is important that you gain clarity on it before picking a career path.</p>

                    <p>Eval test is a career interest test that aims to provide career help to students by clarifying their confusion about 
                    career selection. This is how it works, it asks you simple questions to logically calculate your interests and suggest 
                    ideal career paths. Try Eval today to relieve yourself from Career Confusion! </p>                                        
                </Route>

                {/* ----------------------------------------- Career Interest Assessments --------------------------------------- */}

                <Route path="/blogs/read/what-are-career-interest-assessments" exact>
                    <h1>What are career interest assessments?</h1>
                    
                    <img className="view_blog_page_contain" src={CareerInterestAssessments} alt="What are career interest assessments?"/>
                    
                    <p>Given the complexity and significance of career decisions, several psychometric, intuitive, and neurological tools 
                    exist, to aid with the career decision-making process. Career Interest Assessments (CIA) are a type of tool used to 
                    help people seeking clarity on which career path to pick. Unlike an IQ test or aptitude test, CIAs do not gauge 
                    capability or ability. They purely evaluate the test taker’s interest in a particular career field.</p>

                    <p>Just like any assessment tool, interest based testing has its benefits and limitations. However, one reason CIAs are 
                    valuable is that they help quantify your interest and make it easier to rank career fields you are most and least 
                    interested in. Interest assessments are particularly helpful because <strong>they do not rate your ability to do something and 
                    end up discouraging you because you lack natural ability in something</strong>. Instead they <strong>help you understand which fields 
                    would be most interesting to you and help you identify a career path that would be satisfying.</strong></p>

                    <p>Eval Career Clarity Test is an interest based test and it helps students identify which career options are most 
                    interesting to them. It is built on the belief that <strong>success in any field is a result of sincerity, consistency and 
                    hard work, and if you are interested in something, you are more likely to work hard for it</strong>, than for something you 
                    are not passionate about. If you are genuinely interested in a field, you will put in the effort required to succeed 
                    in that field. So, natural ability or capability need not be the most appropriate indicator for your ideal career path.</p>

                    <p>Thus, a career interest test like Eval, can help you understand which field you want to build your career in, by 
                    helping you identify your genuine interests and passion in a field, and encourage you to take a more active and 
                    intentional role in evaluating your interests and selecting the most satisfying career path.</p>
                </Route>

                {/* ----------------------------------------- Top 5 factors to consider --------------------------------------- */}

                <Route path="/blogs/read/top-5-factors-to-consider-when-selecting-your-career" exact>
                    <h1>Top 5 factors to consider when selecting your career</h1>
                    
                    <img className="view_blog_page_contain" src={TopFiveFactors} alt="Top 5 factors to consider when selecting your career"/>
                    
                    <h2>1. How much do you wish to study?</h2>
                    <p>Formal higher education is challenging, expensive, and time consuming. It is essential to reflect on how many years of 
                    your life you wish to devote to formal education as you narrow in on a career path. If you choose to work in the 
                    healthcare field, the amount of time you will need to study to become a doctor, varies from how long it would take 
                    you to become a pathologist, or a pharmacist. Even when you have an industry that you are interested in, your long 
                    term career goals need to be aligned with how long you wish to study for.</p>

                    <h2>2. Work-Life Balance</h2>
                    <p>The career path you choose will dictate your lifestyle in terms of the amount of free time you have, how long your 
                    working hours are, and even the amount of stress you will experience at your job. It is thus essential to consider what 
                    kind of life you wish to build for yourself. Do you want to be someone that does a 9 to 5 job, has low risk at work and 
                    wants to be an employee, or do you want to be an entrepreneur with limitless working hours, high risk and high reward? 
                    Do you wish to work in a fast paced environment, under pressure and be constantly challenged or want a relatively relaxed 
                    job with little responsibility, where you can work for a few hours and come home. Your ideal work-life balance might 
                    evolve over time, where in your 20s and 30s you wish to work a lot, while in your 40s and 50s you want to have less 
                    responsibilities and relax more, or vice versa. There are no right answers to this, you have to decide what is best 
                    for you, and as your idea of ideal work-life balance evolves, your career goals and responsibilities will most likely 
                    also evolve to align better with it.</p>                    

                    <h2>3. Financial Goals</h2>
                    <p>Your financial goals will be a strong motivating factor in the career you choose. If you are someone that wishes to 
                    earn a lot of money then you will most likely gravitate towards industries that pay a lot to their employees and will 
                    most likely have to be in high-paced work environments that require high educational qualification. The quality of life 
                    you want to build for yourself and the amount of money you wish to earn will influence the kind of subjects you wish to 
                    study in school and college, and also impact the kind of jobs you seek. Everyone has a different financial aim, not 
                    everyone wants to be a multi-millionaire. You need to reflect and ask yourself what are your personal financial goals 
                    as you weigh professional and educational options. But also remember that regardless of the field you pick, if you excel 
                    in it, then you will be financially stable.</p>

                    <h2>4. Impact</h2>
                    <p>As you pick your career, it is important to ask yourself what mark do you want to leave in this world? What is the 
                    legacy you want to leave behind?</p>
                    <p>Do you want to contribute to the innovation in the tech field? Save people’s lives? Create products or services that 
                    help people and solve problems? Educate people and children? Entertain people? These are just some leading questions. 
                    You will have to ask yourself many such questions to identify what is the purpose you want to fulfil. You might realise 
                    that you wish to create an impact in several fields and your goals will evolve over time. But it is necessary to consider 
                    your intended impact in the world as you are selecting a career path.</p>


                    <h2>5. Industry Growth</h2>
                    <p>As you select a career path to pursue, it is important to study industry growth trends. It is important to understand 
                    the direction an industry, of your interest, is going in, so as a student you are developing relevant skills. For example, 
                    the HR industry is making a shift to using HR analytics and using quantitative analysis, so if you are interested in 
                    building a career in HR it would be advisable for you to focus on gaining data analysis skills. You might also realise that 
                    the industry you are interested in is going to significantly grow in the next 8 to 10 years, so by the time you complete 
                    your formal education, you will enter a job market that has a high need for certain skills, and you can focus on developing 
                    those skills in your formal education.</p>
                    
                    <p>These 5 factors are just suggestions from team Eval for students to consider as they navigate the stressful and 
                    confusing career exploration path. Your goals and preferences within these 5 factors will also evolve over time, but 
                    these are good factors to reflect on as you start career exploration.</p>
                </Route>

                {/* ----------------------------------------- Education Safety Net--------------------------------------- */}

                <Route path="/blogs/read/how-to-make-education-your-safety-net" exact>
                    <h1>How to make Education your safety net?</h1>
                    
                    <img className="view_blog_page_contain" src={SafetyNet} alt="How to make Education your safety net?"/>
                    
                    <p>Many times when one’s passion does not directly align with a formal education stream, they find their higher education 
                    journey redundant and irrelevant. However, education can be used mindfully to <strong>acquire transferable skills that are 
                    essential towards ensuring you’re employable in multiple industries and can adapt to a variety of roles</strong>. Transferable 
                    skills simply mean skills that can be applied to a variety of occupations. For example, strong communication, attention 
                    to detail, analytical and critical thinking etc. As a student, if you focus on developing these skills, you will be able 
                    to work in a variety of roles, in different industries. Even if you choose to give up a traditional career path and become 
                    an artist or start a business, these transferable skills and an ability and willingness to think critically and adapt to 
                    change will help you. Moreover, transferable skills will always remain relevant in the job market so even if you take a 
                    break from a certain career journey, you will always be able to fall back on your skills as you re-enter the job market.</p>

                    <p>Eval the Career Personality test identifies 4 work style indicators which give insight on:</p>
                    
                    <ul>
                        <li>A person’s interest in transferable skills like collaboration and communication.</li>
                        <li>It helps you understand how comfortable and willing you would be to work with a team or express yourself, ask questions etc.</li>
                        <li>It assists you in finding out your strengths so you could take advantages of your strong area.</li>
                        <li>It also helps you to know your weaknesses and the best part of this Career Tet is that it suggests ways in which you 
                        can develop those weak areas so you are better prepareed for the job market</li>
                    </ul>

                    <p>Your education is not limited to the subject knowledge you learn in the classroom. Skills that you can tangibly apply 
                    to occupations, thus allowing you to add value wherever you go are best the way to increase your employability.</p>

                </Route>
            </Switch>
        </div>
        
        
    )
}