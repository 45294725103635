import React from 'react';
import './Footer.css';
import Logo from '../Assets/Eval-logo.png';
import {Link} from "react-router-dom";
import Instagram from '../Assets/Footer/instagram.svg';
import Facebook from '../Assets/Footer/facebook.svg';
import Twitter from '../Assets/Footer/twitter.svg';
import LinkedIn from '../Assets/Footer/linkedin.svg';
import Youtube from '../Assets/Footer/youtube.svg';

export default function Footer(){
    return(
        <div className="footer">
            <div className="footer_columns">
                <div className="footer_logo-box_desktop">
                    <Link to={"/home"}><img src={Logo} alt={"eval logo"} /></Link>
                </div>
                <div className="footer_column">
                    <p className="footer_column_title">Tests</p>
                    <Link to="/tests-info/#general">General Eval</Link>
                    <Link to="/tests-info/#humanities">Eval for Humanities</Link>
                    <Link to="/tests-info/#engineering">Eval for Engineering</Link>
                    <Link to="/tests-info/#science">Eval for Science</Link>
                    <Link to="/tests-info/#commerce">Eval for Commerce</Link>
                </div>
                <div className="footer_column">
                    <p className="footer_column_title">Company</p>
                    <Link to="/about">About</Link>
                    <Link to="/blogs">Blogs</Link>
                    <Link to="/contact">Contact</Link>
                </div>
                <div className="footer_column">
                    <p className="footer_column_title">Resources</p>
                    <Link to="/terms-of-service">Terms of Service</Link>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
                <div className="footer_column">
                    <p className="footer_column_title">Become a partner</p>
                    <Link to="/contact">For Education Institutions</Link>
                    <Link to="/contact">For Coaching Classes</Link>
                    <Link to="/contact">For Others</Link>
                </div>                                
            </div>
            <div className="footer_socials">
                <div className="footer_logo-box_mobile">
                    <Link to={"/home"}><img src={Logo} alt={"eval logo"} /></Link>
                </div>
                <a href="https://www.facebook.com/525797737822547/" target="_blank">
                    <img src={Facebook} alt="facebook logo"/>
                </a>
                <a href="https://instagram.com/uemsventures?igshid=xe5hhetxhpsj" target="_blank">
                    <img src={Instagram} alt="instagram logo"/>
                </a>
                <a href="https://www.youtube.com/channel/UCfXEUNgFxdm5hosAmR31igw" target="_blank">
                    <img src={Youtube} alt="youtube logo"/>
                </a>
                <a href="https://www.linkedin.com/in/shalini-menon-3a0a4b12b" target="_blank">
                    <img src={LinkedIn} alt="linkedin logo"/>
                </a>                
                {/* <a href="https://www.twitter.com/">
                    <img src={Twitter} alt="twitter logo"/>
                </a> */}
            </div>
        </div>
    )
}