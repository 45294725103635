import React, {useState} from 'react';
import './Header.css';
import Hamburger from '../Assets/hamburger.png';
import {Link, NavLink, useRouteMatch} from "react-router-dom";
import firebase from '../Firebase/Firebase';
import {useHistory} from "react-router";
import APP from '../services/app-service'
const GLOBAL = require('../services/global-constant');
const axios = require('axios').default;
const activeStyle = {color: "#024E85"};

export default function Header(props) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const loginMatch = useRouteMatch("/login");
    const checkoutMatch = useRouteMatch("/checkout");
    const profileMatch = useRouteMatch("/profile");
    const history = useHistory();

    const [isTokenAvl, setisTokenAvl] = useState(props.user);
    //const isTokenAvl = APP.getRouteComponentData(GLOBAL.STORAGE.ls_token);
    console.log("isTokenAvl",isTokenAvl);
    console.log("props.user",props.user);

    // const username = props.user && props.user.displayName.trim().slice(0, (props.user.displayName.indexOf(" ") !== -1)? props.user.displayName.indexOf(" ") : props.user.displayName.length);

    const logoutHandler = () => {
        axios.get(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.logout,APP.getToken())
        .then(function (response) {
            console.log("response",response);
            if(!response.data.error){
                APP.removeRouteComponentData(GLOBAL.STORAGE.ls_token);
                setisTokenAvl(false);
                history.push('/home');
            }
        })
        .catch(function (error) {
            console.log("error",error);
        });
    };

    const hamburgerHandler = () => {
        props.setNavOpen(navOpen => !navOpen);
    };

    return (
        <div className="header mui-fixed" style={(loginMatch||checkoutMatch)&&{backgroundColor: "white"}}>
            <div className="header-content">
                <div className="header_left-half">
                    <NavLink className="header_logo" to="/home"/>
                    <NavLink to="/tests-info" activeStyle={activeStyle}>Tests</NavLink>
                    <NavLink to="/reports-info" activeStyle={activeStyle}>Reports</NavLink>
                    <NavLink to="/about" activeStyle={activeStyle}>About</NavLink>
                    <NavLink to="/contact" activeStyle={activeStyle}>Contact</NavLink>
                    <NavLink to="/blogs" activeStyle={activeStyle}>Blogs</NavLink>
                </div>
                <div className="header_right-half">
                    <a className="header_right-half_link-text"
                       target="_blank" rel="noreferrer"
                       href="https://www.uemsventures.com/">Visit UEMS Ventures</a>
                    {props.user?
                        (profileMatch?
                            <button className="header_right-half_link-button_opaque" onClick={logoutHandler}>Log out</button>:
                            <Link className="header_right-half_link-button_opaque" to="/profile">My Account</Link>
                        ) :[<Link className="header_right-half_link-button_opaque" to="/login/sign-in">Sign In</Link>,
                        <Link className="header_right-half_link-button_opaque" to="/login/sign-up">Sign Up</Link>]
                    }
                    <button className="header_right_hamburger" onClick={hamburgerHandler}/>
                </div>
            </div>
        </div>
    )
}