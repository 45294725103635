import React, {useEffect, useState} from "react";
import {PDFViewer, Page, View, Document, Text, StyleSheet, Image, BlobProvider} from "@react-pdf/renderer";
import firebase from "../Firebase/Firebase";
import {useHistory, useParams} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import reportTexts from "./ReportTexts";
import {format} from "date-fns";
import gql from "graphql-tag";
import GeneralLogo from "../Assets/Homepage/general-eval-icon.png";
import HumanitiesLogo from "../Assets/Homepage/humanities-eval-icon.png";
import EngineeringLogo from "../Assets/Homepage/engineering-eval-icon.png";
import ScienceLogo from "../Assets/Homepage/science-eval-icon.png";
import CommerceLogo from "../Assets/Homepage/commerce-eval-icon.png";
import ReportTopImage from "../Assets/report-top-image.png";
import EvalLogo from "../Assets/Eval-logo.png";
import VcuraLogo from "../Assets/Vcura-no-background.png"
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from './ReportPdfStyles';
import ReportBotImage from "../Assets/report-bottom-image.png";
import axios from 'axios';
import APP from '../services/app-service'
const GLOBAL = require('../services/global-constant');
const axios2 = require('axios').default;



const RESPONSE_QUERY = gql`
    query PurchaseQuery($responseId: Int!, $email: String!) {
        responses(where: {id: {_eq: $responseId}, purchase: {user: {email: {_eq: $email}}}}) {
            scores {
            score
            category {
                id
                name
            }
            createdAt
        }
        purchase {
            testId
            user {
                name
            }
        }
        createdAt
        }
    }`;

const SET_RESPONSE_SENT_MUTATION = gql`
    mutation SetResponseSent($id: Int!) {
        update_responses(where: {id: {_eq: $id}}, _set: {reportSent: "true"}) {
            affected_rows
        }
    }`;


// CONSTANT HARDCODED VALUES OF TEST ID'S
const TEST_ID = [
    {name: "General Eval", id: 1, icon: GeneralLogo},
    {name: "Eval for Humanities", id: 2, icon: HumanitiesLogo},
    {name: "Eval for Engineering", id: 3, icon: EngineeringLogo},
    {name: "Eval for Science", id: 4, icon: ScienceLogo},
    {name: "Eval for Commerce", id: 5, icon: CommerceLogo},
];

// COSNSTANT HARDCODED LANGUAGE IDS
const LANGUAGE_ID = [
    {name: "English", id: 1, index: 0}, 
    {name: "हिंदी", id: 3, index: 1}, 
    {name: "मराठी", id: 4, index: 2}, 
    {name: "ગુજરાતી", id: 5, index: 3},
];

//Circular progress theme
const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
});

export default function ReportPdf(props) {
    const {responseId, languageId} = useParams();
    //Report text states
    const [name, setName] = useState(props.name);
    const [testId, setTestId] = useState(null);
    const [testItem, setTestItem] = useState(null);
    const [date, setDate] = useState(null);
    const [langId, setLangId] = useState(LANGUAGE_ID.filter(item => item.id === parseInt(languageId))[0].index);

    // Recommendation states
    const [sortedScores, setSortedScores] = useState(null); // Named the way it is because it used to hold scores sorted in descending order, now it only contains scores
    const [filteredScores, setFilteredScores] = useState(null); // Contains scores in descending order from the highest to the 5th highest score
    const [extraScores, setExtraScores] = useState(null); // Scores of the four work style indicators categories, not included in sortedScores or filteredScores
    const [sortedReportTexts, setSortedReportTexts] = useState(null); // Redundant
    const [filteredReportTexts, setFilteredReportTexts] = useState(null); // Recommendations texts for the filteredScores to be shown in the recommendations page
    const [noInterest, setNoInterest] = useState(false); // Boolean that is true when all non work style indicators categories have their scores equal to 0
    // Boolean that is true when more than one category has >=75 score
    const [multipleHighInterest, setMultipleHighInterest] = useState(false);

    // Summary states
    const [interestAreas, setInterestAreas] = useState(""); // Interest areas text that appears at the top of every summary sectionn regaredless of the test
    const [generalQualities, setGeneralQualities] = useState(null); // qualities object to be shown in a table
    const [generalStreams, setGeneralStreams] = useState(null); // streams object to be shown in a table
    const [careerOptions, setCareerOptions] = useState(null); // an array containing all the career options to be shown to the user.
    const [qualitiesArray, setQualitiesArray] = useState(null); // An array containing all the qualities required

    // Blob loading states
    const [blob, setBlob] = useState(null);
    const [blobLoading, setBlobLoading] = useState(true);

    const [responseData, setResponseData] = useState(null);
    const [responseLoading, setResponseLoading] = useState(null);
    const [responseError, setResponseError] = useState(null);

    useEffect(() => {
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getViewReport, {
            'responseId':responseId
        },APP.getToken())
        .then(function (response) {
            console.log("getViewReport response",response);
            if(!response.data.error){
                setResponseData(response.data);
                console.log("setResponseData",responseData);
            }else{
            }
        })
        .catch(function (error) {
        });
    },[langId]);

    // const {data: responseData, error: responseError, loading: responseLoading} = useQuery(RESPONSE_QUERY, {
    //     variables: {
    //         responseId,
    //         email: firebase.auth().currentUser.email
    //     }
    // });

    // Set test text states when data arrives
    useEffect(() => {
        if(responseData && responseData.responses.length) {
            // SORT THE RESPONSE ACCORDING TO SCORE VALUES IN DESCENDING ORDER
            // RETURN SORTED ARRAY WITH ALL CATEGORIES EXCEPT THE SPECIAL 4 COMMON THROUGHOUT ALL TESTS. SPECIAL 4 CATEGORIES WILL BE STORED IN extraScores ARRAY
            const sortedScores = [];
            responseData.responses[0].scores.filter((scoreItem, index) => {
                return !(scoreItem.category.name === "Client Facing" || scoreItem.category.name === "Collaborative" || scoreItem.category.name === "Communication"
                    || scoreItem.category.name === "Outdoors");
            }).forEach((item, index) => {
                sortedScores.push({
                    category: item.category.name,
                    score: item.score
                });
            });            
            
            const extraScores = responseData.responses[0].scores.filter((scoreItem, index) => {
                return (scoreItem.category.name === "Client Facing" || scoreItem.category.name === "Collaborative" || scoreItem.category.name === "Communication"
                    ||scoreItem.category.name === "Outdoors");
            }).map((scoreItem, index) => {
                if(scoreItem.category.name === "Client Facing"||scoreItem.category.name === "Collaborative"||scoreItem.category.name === "Communication"||scoreItem.category.name === "Outdoors"){
                    return {
                        category: scoreItem.category.name,
                        score: scoreItem.score
                    }
                } else return null
            });

            // FILTER THE SCORES TO SHOW ONLY THE BEST SCORES
            let filteredScores;
            const threshold = 5;
            if(sortedScores.filter(x => x.score > 75).length >= threshold){
                filteredScores = sortedScores.filter(x => x.score > 75).sort((a, b) => b.score - a.score);
            } else if(sortedScores.filter(x => x.score > 75).length){
                if(sortedScores.filter(x => x.score > 50).length >= threshold){
                    filteredScores = sortedScores.filter(x => x.score > 50).sort((a, b) => b.score - a.score).slice(0, threshold);
                } else filteredScores = sortedScores.filter(x => x.score > 50).sort((a, b) => b.score - a.score);
            } else {
                if(sortedScores.filter(x => x.score > 50).length >= threshold) {
                    filteredScores = sortedScores.filter(x => x.score > 50).sort((a, b) => b.score - a.score).slice(0, threshold);
                } else if(sortedScores.filter(x => x.score > 50).length){
                    if(sortedScores.filter(x => x.score > 25).length >= threshold){
                        filteredScores = sortedScores.filter(x => x.score > 25).sort((a, b) => b.score - a.score).slice(0, threshold);
                    }else filteredScores = sortedScores.filter(x => x.score > 25).sort((a, b) => b.score - a.score);
                } else {
                    if(sortedScores.filter(x => x.score > 25).length >= threshold) {
                        filteredScores = sortedScores.filter(x => x.score > 25).sort((a, b) => b.score - a.score).slice(0, threshold);
                    }else if(sortedScores.filter(x => x.score > 25).length){
                        filteredScores = sortedScores.filter(x => x.score > 25).sort((a, b) => b.score - a.score);
                    } else {
                        filteredScores = sortedScores.filter(x => x.score > 0).sort((a, b) => b.score - a.score).slice(0, threshold);
                    }
                }
            }           

            if(sortedScores.filter(x => x.score).length === 0){
                setNoInterest(true);
            }else if(sortedScores.filter(x => x.score > 75).length > 1){
                setMultipleHighInterest(true);
            }

            const testTexts = reportTexts.filter(x => x.testId === responseData.responses[0].purchase.testId)[0];

            // Sorted reportTexts for classifications
            const sortedReportTexts = sortedScores.map((score, index) => {
                return testTexts.texts.categories.filter(y => y.name === score.category)[0];
            });
            

            // Filter reportTexts to have only categories that are in filteredScores array
            const filteredReportTexts = filteredScores.map((score, index) => {
                return testTexts.texts.categories.filter(y => y.name === score.category)[0];
            });

            //SET OVERALL TEST STATES
            setTestId(responseData.responses[0].purchase.testId); // SET TEST ID TO USE FOR SEARCHING OTHER STRINGS
            setName(responseData.responses[0].purchase.user.name); // SET THE USER NAME FROM DATA
            setDate(format(new Date(responseData.responses[0].createdAt), "MMMM d, yyyy")); // SET DATE OF THE TEST
            setTestItem(TEST_ID.filter(x => x.id === responseData.responses[0].purchase.testId)[0]); // TEST ITEM FROM THE CONSTANT ARRAY

            // SET TEST TEXT STATES
            // RECOMMENDATIONS TEXT STATES
            setSortedScores(sortedScores); // ALL SCORES EXCEPT WORK STYLE INDICATORS
            setFilteredScores(filteredScores); // FILTERED SCORES LIMITED TO THE 5TH RANK OF SCORES
            setExtraScores(extraScores); // WORK STYLE INDICATORS SCORES
            setSortedReportTexts(sortedReportTexts); // RECOMMENDATION TEXTS FOR ALL THE CATEGORIES
            setFilteredReportTexts(filteredReportTexts); // RECOMMENDATION TEXTS FOR ONLY THE FILTERED CATEGORIES

            // SUMMARY TEXT STATES
            // Text at the top of the summary, common for all tests
            let interestAreas;
            if(langId === 0){
                interestAreas = "Your interest areas are: ";
            } else if(langId === 1){
                interestAreas = "आपकी रुचि के क्षेत्र हैं: ";
            }  else if(langId === 2){
                interestAreas = "आपली आवड असणारी क्षेत्रे आहेत: ";
            } else if(langId === 3){
                interestAreas = "તમારા રુચિના ક્ષેત્રો છે: ";
            }

            filteredScores.forEach((scoreItem, index, arr) => {            
                console.log(filteredReportTexts.filter((textItem) => textItem.name === scoreItem.category)[0]);
                const name = filteredReportTexts.filter((textItem) => textItem.name === scoreItem.category)[0].languages[langId].title.trim();
                if((arr.length - 1) === index){
                    if(langId === 0){
                        interestAreas = interestAreas + " and " + name + "."
                    }else if(langId === 1){
                        interestAreas = interestAreas + " और " + name + ".         "
                    }else if(langId === 2){
                        interestAreas = interestAreas + " आणि " + name + ".         "
                    }else if(langId === 3){
                        interestAreas = interestAreas + " અને " + name + ".         "
                    }
                } else{
                    interestAreas = interestAreas + " " + name + ","
                }
            });

             // Career options array for summary
            let careerOptions = [];
            filteredReportTexts.forEach((textItem, textIndex) => {
                textItem.languages[langId].recommendations.careers.points.forEach((careerOptionItem, optionIndex) => {
                    if(careerOptions.indexOf(careerOptionItem) === -1){
                        careerOptions.push(careerOptionItem);
                    }

                });
            });

            // Qualities array for summary
            let qualitiesArray = [];
            filteredReportTexts.forEach((textItem, textIndex) => {
                qualitiesArray.push({category: textItem.languages[langId].title, qualities: textItem.languages[langId].summary.qualities});
            });


            setQualitiesArray(qualitiesArray);
            setCareerOptions(careerOptions);
            setInterestAreas(interestAreas);
        }
        else if(responseData && responseData.responses.length === 0){
            history.push("/profile");
        }
    },[responseData, langId]);

    const history = useHistory();

    useEffect(() => {
        if(blob){
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            if(testId === 1){
                a.download = 'general.pdf';
            }else if(testId === 2){
                a.download = 'humanities.pdf';
            }else if(testId === 3){
                a.download = 'engineering.pdf';
            }else if(testId === 4){
                a.download = 'science.pdf';
            }else if(testId === 5){
                a.download = 'commerce.pdf';
            }        
            document.body.appendChild(a);
            a.click();
            history.push("/profile");
        }else{
            return
        }
    },[blob, blobLoading]);


    if(responseData && responseData.responses.length && sortedScores && sortedScores.length &&
        filteredScores && filteredReportTexts && extraScores ) {
        return(
            <BlobProvider document={<ReportPdfAsDocument name={name} testId={testId} testItem={testItem} date={date}
                sortedScores={sortedScores} filteredScores={filteredScores} extraScores={extraScores} sortedReportTexts={sortedReportTexts}
                filteredReportTexts={filteredReportTexts} noInterest={noInterest} multipleHighInterest={multipleHighInterest}
                interestAreas={interestAreas} generalStreams={generalStreams} generalQualities={generalQualities} careerOptions={careerOptions} qualitiesArray={qualitiesArray}
                 langId={LANGUAGE_ID.filter(item => item.id === parseInt(languageId))[0].index} {...props}/>}>
                {({ blob, loading, error }) => {
                    if(loading){
                        return null
                    } else if (error){
                        console.log(error);
                    }else {
                         setBlobLoading(false);
                         setBlob(blob);
                    }
                    return <div className="questions_page_progress_container">
                        <ThemeProvider theme={progressTheme}>
                            <CircularProgress size={56} color="primary"/>
                        </ThemeProvider>
                    </div>                
                }}
            </BlobProvider>
            // <PDFViewer width="100%" height="100vh">
            //     <ReportPdfAsDocument name={name} testId={testId} testItem={testItem} date={date}
            //     sortedScores={sortedScores} filteredScores={filteredScores} extraScores={extraScores} sortedReportTexts={sortedReportTexts}
            //     filteredReportTexts={filteredReportTexts} noInterest={noInterest} multipleHighInterest={multipleHighInterest}
            //     interestAreas={interestAreas} generalStreams={generalStreams} generalQualities={generalQualities} careerOptions={careerOptions} qualitiesArray={qualitiesArray}
            //      langId={LANGUAGE_ID.filter(item => item.id === parseInt(languageId))[0].index} {...props}/>   
            // </PDFViewer>            
        )
    }else return null
}

export function ReportPdfAsDocument({responseData, vcura, ...props}){   //Report text states

    const [name, setName] = useState(props.name);
    const [testId, setTestId] = useState(props.testId);
    const [testItem, setTestItem] = useState(props.testItem);
    const [date, setDate] = useState(props.date);
    const [langId, setLangId] = useState(props.langId);

    // Recommendation states
    const [sortedScores, setSortedScores] = useState(props.sortedScores); // Named the way it is because it used to hold scores sorted in descending order, now it only contains scores
    const [filteredScores, setFilteredScores] = useState(props.filteredScores); // Contains scores in descending order from the highest to the 5th highest score
    const [extraScores, setExtraScores] = useState(props.extraScores); // Scores of the four work style indicators categories, not included in sortedScores or filteredScores
    const [sortedReportTexts, setSortedReportTexts] = useState(props.sortedReportTexts); // Redundant
    const [filteredReportTexts, setFilteredReportTexts] = useState(props.filteredReportTexts); // Recommendations texts for the filteredScores to be shown in the recommendations page
    const [noInterest, setNoInterest] = useState(props.noInterest); // Boolean that is true when all non work style indicators categories have their scores equal to 0
    // Boolean that is true when more than one category has >=75 score
    const [multipleHighInterest, setMultipleHighInterest] = useState(props.multipleHighInterest);

    // Summary states
    const [interestAreas, setInterestAreas] = useState(props.interestAreas); // Interest areas text that appears at the top of every summary sectionn regaredless of the test
    const [generalQualities, setGeneralQualities] = useState(props.generalQualities); // qualities object to be shown in a table
    const [generalStreams, setGeneralStreams] = useState(props.generalStreams); // streams object to be shown in a table
    const [careerOptions, setCareerOptions] = useState(props.careerOptions); // an array containing all the career options to be shown to the user.
    const [qualitiesArray, setQualitiesArray] = useState(props.qualitiesArray); // An array containing all the qualities required 
    return (
            <Document title={testItem&&(testItem.name + " Report")}>
                {/*---------------------------PAGE 1----------------------------------*/}
                <Page size="A4" style={styles.view_report_page_1} orientation="landscape">
                    <View style={styles.view_report_fixed_icon_row}>
                        {vcura? <Image style={styles.view_report_fixed_icon_1} src={EvalLogo}
                            alt="Eval logo"/>:null}
                        {vcura? <Image style={styles.view_report_fixed_icon_2_page_1} src={VcuraLogo}
                            alt="Eval logo"/>:null} 
                    </View>                    
                    {/* <View style={styles.view_report_page_1_svg}/> */}
                    <View style={styles.view_report_page_1_image_container}>
                        <Image style={styles.view_report_page_1_image_container_image_large} src={ReportTopImage}/>                        
                        {/* DONT SHOW LOGO IN IMAGE IF USER IS FROM VCURA, SHOW BOTH LOGOS ON CORNERS OF THE SCREEN INSTEAD */}
                        {vcura? null:<Image style={styles.view_report_page_1_image_container_logo} src={EvalLogo}
                                alt="Eval logo"/>} 
                    </View>
                    <View style={styles.view_report_page_1_text_container}>
                        <View style={styles.view_report_page_1_textbox}>
                            <Text style={styles.view_report_page_1_textbox_h2}>{testItem&&testItem.name + " Report"}</Text>
                            <Text style={styles.view_report_page_1_textbox_p_subtitle}>NAME</Text>
                            <Text style={styles.view_report_page_1_textbox_p}>{name}</Text>
                            <Text style={styles.view_report_page_1_textbox_p_subtitle}>DATE</Text>
                            <Text style={styles.view_report_page_1_textbox_p}>{date}</Text>
                        </View>
                    </View>
                </Page>
                {/*--------------------------PAGE 2------------------------------------*/}
                <Page size="A4" style={styles.view_report_page_2} orientation="landscape">
                    {(langId === 0)? <View style={styles.view_report_page_2_first_half}>
                        <Text style={styles.view_report_page_2_first_half_title}>The eval report has four features</Text>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle}>Your Career Interest Profile</Text>
                                <Text style={styles.view_report_page_2_first_half_text}>This section has the test taker’s interest score in each category. The score only
                                    represents the interest that a person has in that category and not their aptitude
                                    or cognitive ability to excel in that field.</Text>
                            </View>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle}>Recommendations</Text>
                                <Text style={styles.view_report_page_2_first_half_text}>This section includes information about your top interest areas like, the
                                jobs that you can pursue under them, education qualifications required for those jobs, and qualities you must possess.</Text>
                            </View>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle}>Work Style Indicators</Text>
                                <Text style={styles.view_report_page_2_first_half_text}>This section includes information about your preference for four working styles and
                                ways you can develop skills to be a more effective professional.</Text>
                            </View>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle}>Summary</Text>
                                <Text style={styles.view_report_page_2_first_half_text}>This section sums up your top recommended career options, work style preferences and further education options.</Text>
                            </View>
                            <Text style={styles.view_report_page_2_first_half_note_text}>
                                Note - Your Career Interest Profile and Recommendations
                                section will only give you detailed information about categories
                                that you scored high in and showed higher interest.
                            </Text>
                        </View>: null
                    }
                    {(langId === 1)? <View style={styles.view_report_page_2_first_half}>
                            <Text style={styles.view_report_page_2_first_half_title_hindi}>एवल रिपोर्ट में चार विशेषताएं हैं         </Text>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle_hindi}>आपकी कैरियर रुचि प्रोफ़ाइल             </Text>
                                <Text style={styles.view_report_page_2_first_half_text_hindi}>इस अनुभाग में प्रत्येक श्रेणी में परीक्षार्थी की रुचि है। स्कोर केवल उस ब्याज का प्रतिनिधित्व करता है जो किसी व्यक्ति की उस श्रेणी में है और उस क्षेत्र में उत्कृष्टता प्राप्त करने के लिए उनकी योग्यता या संज्ञानात्मक क्षमता नहीं है।                                                      </Text>
                            </View>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle_hindi}>अनुशंसाएँ</Text>
                                <Text style={styles.view_report_page_2_first_half_text_hindi}>इस खंड में आपके शीर्ष रुचि वाले क्षेत्रों के बारे में जानकारी शामिल है, जैसे कि आप जिन नौकरियों के तहत अपना सकते हैं, उन नौकरियों के लिए आवश्यक शिक्षा योग्यताएं, और गुण जो आपके पास होने चाहिए।                                                 </Text>
                            </View>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle_hindi}>कार्य शैली संकेतक            </Text>
                                <Text style={styles.view_report_page_2_first_half_text_hindi}>इस अनुभाग में चार कार्यशैली और आपकी पसंद के बारे में जानकारी शामिल है जिससे आप एक अधिक प्रभावी पेशेवर बनने के लिए कौशल विकसित कर सकते हैं।                                                  </Text>
                            </View>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle_hindi}>सारांश</Text>
                                <Text style={styles.view_report_page_2_first_half_text_hindi}>यह खंड आपके शीर्ष अनुशंसित कैरियर विकल्पों, कार्य शैली वरीयताओं और आगे की शिक्षा के विकल्पों को पूरा करता है।                                        </Text>
                            </View>
                            <Text style={styles.view_report_page_2_first_half_note_text_hindi}>
                            नोट - आपका कैरियर ब्याज प्रोफ़ाइल और सिफारिशें अनुभाग आपको केवल उन श्रेणियों के बारे में विस्तृत जानकारी देगा, जिन्हें आपने उच्च स्कोर किया था और उच्च ब्याज दिखाया था।                                                                                                                              </Text>
                        </View>: null
                    }
                    {(langId === 2)? <View style={styles.view_report_page_2_first_half}>
                        <Text style={styles.view_report_page_2_first_half_title_hindi}>इव्हल अहवालात चार वैशिष्ट्ये आहेत              </Text>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle_hindi}>आपले करियर आवडीचे  प्रोफाइल            </Text>
                                <Text style={styles.view_report_page_2_first_half_text_hindi}>या विभागात चाचणी घेणार्‍याचा प्रत्येक वर्गात रस आहे. स्कोअर केवळ एखाद्या व्यक्तीस त्या श्रेणीमध्ये असलेले स्वारस्य दर्शवितो आणि त्यांची क्षमता किंवा त्या क्षेत्रात उत्तीर्ण होण्याची संज्ञानात्मक क्षमता नव्हे.                                                                           </Text>
                            </View>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle_hindi}>शिफारसी     </Text>
                                <Text style={styles.view_report_page_2_first_half_text_hindi}>या विभागात जसे की आपल्या सर्वोच्च व्याज क्षेत्राविषयी माहिती, जसे की आपण त्यांच्याद्वारे पाठपुरावा करू शकता अशा नोकर्‍या, त्या आवश्यक शैक्षणिक पात्रता आणि आपल्याकडे असणे आवश्यक असलेले गुण.                                                                    </Text>
                            </View>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle_hindi}>कार्य शैली निर्देशक         </Text>
                                <Text style={styles.view_report_page_2_first_half_text_hindi}>या विभागात चार कार्यशैलीसाठी आपल्या पसंतीविषयी आणि अधिक प्रभावी व्यावसायिक होण्यासाठी आपण कौशल्ये विकसित करू शकता याबद्दलची माहिती समाविष्ट आहे.                                                     </Text>
                            </View>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle_hindi}>सारांश     </Text>
                                <Text style={styles.view_report_page_2_first_half_text_hindi}>हा विभाग आपल्या कारकीर्दीतील मुख्य शिफारसी, कार्यशैली प्राधान्ये आणि पुढील शिक्षण पर्यायांची पूर्तता करतो.                                        </Text>
                            </View>
                            <Text style={styles.view_report_page_2_first_half_note_text_hindi}>टीप - आपले करियर इंटरेस्ट प्रोफाइल आणि शिफारसी विभाग आपल्याला केवळ अशा श्रेणींविषयी तपशीलवार माहिती देईल ज्यामध्ये आपण उच्चांक बनविला आहे आणि जास्त रस दर्शविला आहे.                                                                                              </Text>
                        </View>: null
                    }
                    {(langId === 3)? <View style={styles.view_report_page_2_first_half}>
                        <Text style={styles.view_report_page_2_first_half_title_hindi}>ઈવલ રિપોર્ટમાં ચાર સુવિધાઓ છે.        </Text>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle_hindi}>તમારી કારકિર્દી રસ રૂપરેખા            </Text>
                                <Text style={styles.view_report_page_2_first_half_text_hindi}>આ વિભાગમાં દરેક કેટેગરીમાં ટેસ્ટ ટેકરનો રુચિ સ્કોર છે. સ્કોર ફક્ત તે કેટેગરીમાં વ્યક્તિને જે રસ છે તે રજૂ કરે છે અને તે ક્ષેત્રમાં શ્રેષ્ઠતા મેળવવાની તેમની યોગ્યતા અથવા જ્ઞાનાત્મક ક્ષમતાને નહીં.                                    </Text>
                            </View>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle_hindi}>ભલામણો</Text>
                                <Text style={styles.view_report_page_2_first_half_text_hindi}>આ વિભાગમાં તમારા ટોચના હિત ક્ષેત્રો જેવી માહિતી, તેમના હેઠળ તમે જે નોકરીઓ મેળવી શકો છો, તે નોકરીઓ માટે જરૂરી શિક્ષણ લાયકાતો અને તમે ધરાવતા હોવા જોઈએ તેવા ગુણો વિશેની માહિતી શામેલ છે.                                    </Text>
                            </View>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle_hindi}>કાર્ય શૈલી સૂચકાંકો    </Text>
                                <Text style={styles.view_report_page_2_first_half_text_hindi}>આ વિભાગમાં ચાર કાર્યકારી શૈલીઓ અને વધુ અસરકારક વ્યાવસાયિક બનવા માટે સ્કિલ્સ વિકસાવી શકો છો તે રીતો માટે તમારી પસંદગી વિશેની માહિતી શામેલ છે.                       </Text>
                            </View>
                            <View style={styles.view_report_page_2_first_half_title_subtitle_item}>
                                <Text style={styles.view_report_page_2_first_half_subtitle_hindi}>સારાંશ </Text>
                                <Text style={styles.view_report_page_2_first_half_text_hindi}>આ વિભાગ તમારા ટોચના ભલામણ કરેલા કરિયર વિકલ્પો, કાર્ય શૈલીની પસંદગીઓ અને વધુ શિક્ષણ વિકલ્પોનો સરવાળો કરે છે.આ વિભાગ તમારા ટોચના ભલામણ કરેલા કરિયર વિકલ્પો, કાર્ય શૈલીની પસંદગીઓ અને વધુ શિક્ષણ વિકલ્પોનો સરવાળો કરે છે.                             </Text>
                            </View>
                            <Text style={styles.view_report_page_2_first_half_note_text_hindi}>
                            નોંધ - તમારી કરિયરની રુચિ પ્રોફાઇલ અને ભલામણો વિભાગ તમને ફક્ત તે કેટેગરીઓ વિશે વિગતવાર માહિતી આપશે જેમાં તમે ઉચ્ચ ગુણ પ્રાપ્ત કર્યા છે અને વધુ રસ દાખવ્યો છે.                                                                .
                            </Text>
                        </View>: null
                    }

                    {(langId === 0)? 
                        <View style={styles.view_report_page_2_second_half}>
                            <Text style={styles.view_report_page_2_second_half_title}>Before you read your report, here are some things to remember:</Text>
                            <Text style={styles.view_report_page_2_second_half_text}>1. This test report is based on the answers provided by the test
                                taker and so its recommendations are based on the test taker’s responses.</Text>
                            <Text style={styles.view_report_page_2_second_half_text}>2. The test only serves as a guiding tool for the test taker’s career or
                                educational decisions and does not intend to dictate career or
                                educational decisions.</Text>
                            <Text style={styles.view_report_page_2_second_half_text}>3. While aptitude is important while making career decisions, interest in
                                a subject can serve as a strong motivator to be successful and so our test
                                focuses on interest. It is possible that you do not possess the technical
                                knowledge to pursue a career in the categories where you have the highest
                                score. That is because the test is gauging interest and not technical knowledge
                                for a category.</Text>
                            <Text style={styles.view_report_page_2_second_half_text}>4. The information in this report is confidential and will be stored in a secure
                                place.</Text>
                        </View> : null
                    }
                    {(langId === 1)? 
                        <View style={styles.view_report_page_2_second_half}>
                            <Text style={styles.view_report_page_2_second_half_title_hindi}>इससे पहले कि आप अपनी रिपोर्ट पढ़ें, यहाँ कुछ बातें याद रखने योग्य हैं:                           </Text>
                            <Text style={styles.view_report_page_2_second_half_text_hindi}>१. यह परीक्षण रिपोर्ट परीक्षार्थी द्वारा दिए गए उत्तरों पर आधारित है और इसलिए इसकी सिफारिशें परीक्षार्थी की प्रतिक्रियाओं पर आधारित हैं।                                                  </Text>
                            <Text style={styles.view_report_page_2_second_half_text_hindi}>२. परीक्षण केवल परीक्षार्थी के कैरियर या शैक्षिक निर्णयों के लिए एक मार्गदर्शक उपकरण के रूप में कार्य करता है और कैरियर या शैक्षिक निर्णयों को निर्धारित करने का इरादा नहीं करता है।                                                                 </Text>
                            <Text style={styles.view_report_page_2_second_half_text_hindi}>३. जबकि कैरियर के निर्णय लेते समय योग्यता महत्वपूर्ण है, किसी विषय में रुचि सफल होने के लिए एक मजबूत प्रेरक के रूप में काम कर सकती है और इसलिए हमारा परीक्षण ब्याज पर केंद्रित है। यह संभव है कि आप उन श्रेणियों में कैरियर बनाने के लिए तकनीकी ज्ञान के अधिकारी न हों, जहां आपका उच्चतम स्कोर है। ऐसा इसलिए है क्योंकि परीक्षण एक श्रेणी के लिए ब्याज और तकनीकी ज्ञान नहीं है।                                                                                                                                   </Text>
                            <Text style={styles.view_report_page_2_second_half_text_hindi}>४. इस रिपोर्ट की जानकारी गोपनीय है और इसे सुरक्षित स्थान पर संग्रहीत किया जाएगा।                              </Text>
                        </View> : null
                    }
                    {(langId === 2)? 
                        <View style={styles.view_report_page_2_second_half}>
                            <Text style={styles.view_report_page_2_second_half_title_hindi}>आपण आपला अहवाल वाचण्यापूर्वी येथे लक्षात ठेवण्यासारख्या काही गोष्टी येथे आहेत:                              </Text>
                            <Text style={styles.view_report_page_2_second_half_text_hindi}>१. हा चाचणी अहवाल चाचणी घेणार्‍याने दिलेल्या उत्तरांवर आधारित आहे आणि म्हणूनच या शिफारसी चाचणी घेणार्‍याच्या प्रतिसादावर आधारित आहेत.                                                 </Text>
                            <Text style={styles.view_report_page_2_second_half_text_hindi}>२. चाचणी केवळ चाचणी घेणार्‍याच्या कारकीर्दीसाठी किंवा शैक्षणिक निर्णयांसाठी मार्गदर्शक साधन म्हणून काम करते आणि करिअर किंवा शैक्षणिक निर्णय ठरविण्याचा त्यांचा हेतू नाही.                                                              </Text>
                            <Text style={styles.view_report_page_2_second_half_text_hindi}>३ . करिअरचे निर्णय घेताना योग्यता महत्त्वाची असली तरी एखाद्या विषयाची आवड यशस्वी होण्यासाठी यशस्वी प्रेरणा देणारी ठरू शकते आणि म्हणूनच आमची परीक्षा व्याजवर केंद्रित आहे. हे शक्य आहे की आपणास ज्या श्रेणींमध्ये सर्वोच्च स्थान आहे त्यामध्ये करिअर करण्यासाठी आपल्याकडे तांत्रिक ज्ञान नाही. याचे कारण म्हणजे ही चाचणी एखाद्या वर्गासाठी तांत्रिक ज्ञान नाही तर रूची वाढवित आहे.                                                                                                                                    </Text>
                            <Text style={styles.view_report_page_2_second_half_text_hindi}>४. या अहवालातील माहिती गोपनीय आहे आणि ती एका सुरक्षित ठिकाणी ठेवली जाईल.                            </Text>
                        </View> : null
                    }
                    {(langId === 3)? 
                        <View style={styles.view_report_page_2_second_half}>
                            <Text style={styles.view_report_page_2_second_half_title_hindi}>તમે તમારો અહેવાલ વાંચતા પહેલા, અહીં કેટલીક બાબતો યાદ રાખવા જેવી છે:</Text>
                            <Text style={styles.view_report_page_2_second_half_text_hindi}>1. આ ટેસ્ટ રિપોર્ટ ટેસ્ટ ટેકર દ્વારા આપવામાં આવેલા જવાબો પર આધારિત છે અને તેથી તેની ભલામણો ટેસ્ટ ટેકરના પ્રતિભાવો પર આધારિત છે.</Text>
                            <Text style={styles.view_report_page_2_second_half_text_hindi}>2. પરીક્ષણ માત્ર પરીક્ષણ લેનારની કારકિર્દી અથવા શૈક્ષણિક નિર્ણયો માટે માર્ગદર્શક સાધન તરીકે કામ કરે છે અને તેનો ઇરાદો કરિયર અથવા શૈક્ષણિક નિર્ણયો નક્કી કરવાનો નથી.</Text>
                            <Text style={styles.view_report_page_2_second_half_text_hindi}>3. કરિયરના નિર્ણયો કરતી વખતે યોગ્યતા મહત્વપૂર્ણ છે, ત્યારે કોઈ વિષયમાં રસ સફળ બનવા માટે એક મજબૂત પ્રેરક તરીકે કામ કરી શકે છે અને તેથી અમારી કસોટી રસ પર ધ્યાન કેન્દ્રિત કરે છે. શક્ય છે કે તમારી પાસે જે કેટેગરીમાં સર્વોચ્ચ સ્કોર છે ત્યાં કરિયર બનાવવા માટે તમારી પાસે તકનીકી જ્ઞાન ન હોય. તે એટલા માટે છે કારણ કે પરીક્ષણ કોઈ કેટેગરી માટે તકનીકી જ્ઞાન નહીં પરંતુ રસને આગળ લઈ રહ્યું છે.</Text>
                            <Text style={styles.view_report_page_2_second_half_text_hindi}>4. આ અહેવાલમાં માહિતી ગુપ્ત છે અને તેને સુરક્ષિત જગ્યાએ સંગ્રહિત કરવામાં આવશે.</Text>
                        </View> : null
                    }
                </Page>
                
                {/*----------------------------------PAGE 3------------------------------------*/}
                <Page size="A4" style={styles.view_report_page_3} orientation="landscape">
                    <View style={styles.view_report_fixed_icon_row}>
                        {vcura? <Image style={styles.view_report_fixed_icon_1} src={EvalLogo}
                            alt="Eval logo"/>:null}
                        {vcura? <Image style={styles.view_report_fixed_icon_2} src={VcuraLogo}
                            alt="Eval logo"/>:null} 
                    </View>  
                    <View style={styles.view_report_page_3_top_row}>
                        <Text style={styles.view_report_page_3_top_row_h2}>Your Career Interest Profile</Text>
                        <Text style={styles.view_report_page_3_top_row_p}>Your interests indicate your preference for work in different categories. Interests can change over time based on
                            exposure to information, experiences and the people around us. The following graph represents your interest levels
                            in different categories, based on the answers you provided.</Text>
                    </View>
                    <View style={styles.view_report_page_3_graphs}>
                        <View style={styles.view_report_page_3_graphs_column}>
                            {sortedScores.map((scoreItem, index, arr) => {
                                const reportText = sortedReportTexts.filter((textItem, textIndex) => {
                                    return textItem.name === scoreItem.category
                                });
                                if(index < arr.length/2){
                                    let color;
                                    if(scoreItem.score <26){
                                        color = "#F2B5B5";
                                    }else if (scoreItem.score < 51){
                                        color = "#F2E6B5";
                                    }else if (scoreItem.score < 76){
                                        color = "#B5D0F2";
                                    }else color = "#B5F2DB";
                                    return(
                                        <View style={styles.view_report_page_3_graphs_item}>
                                            <Text style={styles.view_report_page_3_graphs_text_hindi}>{reportText[0].languages[langId].title}</Text>
                                            <View style={styles.view_report_page_3_graphs_bar}>
                                                <View style={StyleSheet.create({bar: {
                                                        width: scoreItem.score + "%",
                                                        backgroundColor: color, height: "100%", position: "absolute",
                                                        top: "0",
                                                        left: "0",}}).bar} />
                                                        {/*width={scoreItem.score + "%"} style={{backgroundColor: color}}/>*/}
                                                <Text style={styles.view_report_page_3_graphs_number}>{scoreItem.score}</Text>
                                            </View>
                                        </View>
                                    )
                                }else return null;

                            })}
                        </View>
                        <View style={styles.view_report_page_3_graphs_column}>
                            {sortedScores.map((scoreItem, index, arr) => {
                                const reportText = sortedReportTexts.filter((textItem, textIndex) => {
                                    return textItem.name === scoreItem.category
                                });
                                if(index < arr.length/2){
                                    return null
                                }else{
                                    let color;
                                    if(scoreItem.score <26){
                                        color = "#F2B5B5";
                                    }else if (scoreItem.score < 51){
                                        color = "#F2E6B5";
                                    }else if (scoreItem.score < 76){
                                        color = "#B5D0F2";
                                    }else color = "#B5F2DB";
                                    return(
                                        <View style={styles.view_report_page_3_graphs_item}>
                                            <Text style={styles.view_report_page_3_graphs_text_hindi}>{reportText[0].languages[langId].title}</Text>
                                            <View style={styles.view_report_page_3_graphs_bar}>
                                                <View style={StyleSheet.create({bar: {
                                                        width: scoreItem.score + "%",
                                                        backgroundColor: color, height: "100%", position: "absolute",
                                                        top: "0",
                                                        left: "0",}}).bar} />
                                                <Text style={styles.view_report_page_3_graphs_number}>{scoreItem.score}</Text>
                                            </View>
                                        </View>
                                    )
                                }
                            })}
                        </View>
                    </View>
                    {(langId === 0)?
                        <View style={styles.view_report_page_3_graphs_interpretation} wrap={false}>
                            <Text style={styles.view_report_page_3_graphs_interpretation_title}>Graph Interpretation</Text>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text}>High Interest (Above 75%)</Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_green} />
                            </View>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text}>Moderate Interest (51%-75%)</Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_blue} />
                            </View>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text}>Slight Interest (26%-50%)</Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_yellow} />
                            </View>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text}>Low Interest (25% and below)</Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_red} />
                            </View>
                        </View>: null
                    }
                    {(langId === 1)?
                        <View style={styles.view_report_page_3_graphs_interpretation} wrap={false}>
                            <Text style={styles.view_report_page_3_graphs_interpretation_title_hindi}>ग्राफ व्याख्या</Text>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text_hindi}>उच्च ब्याज (७५% से ऊपर)</Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_green} />
                            </View>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text_hindi}>मध्यम ब्याज (५१% -७५%)</Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_blue} />
                            </View>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text_hindi}>थोड़ा ब्याज (२६% -५०%)</Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_yellow} />
                            </View>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text_hindi}>कम ब्याज (२५% और नीचे)</Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_red} />
                            </View>
                        </View>: null
                    }
                    {(langId === 2)?
                        <View style={styles.view_report_page_3_graphs_interpretation} wrap={false}>
                            <Text style={styles.view_report_page_3_graphs_interpretation_title_hindi}>आलेख व्याख्या       </Text>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text_hindi}>उच्च व्याज (७५% पेक्षा जास्त)             </Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_green} />
                            </View>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text_hindi}>मध्यम व्याज (५१% -७५%)          </Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_blue} />
                            </View>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text_hindi}>जरा व्याज (२६% -५०%)         </Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_yellow} />
                            </View>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text_hindi}>कमी व्याज (२५% आणि खाली)           </Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_red} />
                            </View>
                        </View>: null
                    }
                    {(langId === 3)?
                        <View style={styles.view_report_page_3_graphs_interpretation} wrap={false}>
                            <Text style={styles.view_report_page_3_graphs_interpretation_title_hindi}>ગ્રાફ અર્થઘટન</Text>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text_hindi}>ઉચ્ચ રુચિ છે (75% થી ઉપર)</Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_green} />
                            </View>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text_hindi}>મધ્યમ રુચિ છે (51%-75%)</Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_blue} />
                            </View>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text_hindi}>થોડી રુચિ છે (26%-50%)</Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_yellow} />
                            </View>
                            <View style={styles.view_report_page_3_graphs_interpretation_item}>
                                <Text style={styles.view_report_page_3_graphs_interpretation_item_text_hindi}>ઓછી રુચિ છે (25% અને નીચે)</Text>
                                <View style={styles.view_report_page_3_graphs_interpretation_item_color_box_red} />
                            </View>
                        </View>: null
                    }
                </Page>

                {/*---------------------- PAGE 4 -------------------------------*/}
                <Page size="A4" style={styles.view_report_page_4_orange} orientation="landscape">
                    <View style={styles.view_report_fixed_icon_row}>
                        {vcura? <Image style={styles.view_report_fixed_icon_1} src={EvalLogo}
                            alt="Eval logo"/>:null}
                        {vcura? <Image style={styles.view_report_fixed_icon_2} src={VcuraLogo}
                            alt="Eval logo"/>:null} 
                    </View>  
                    <View style={styles.view_report_page_4_top_row}>
                        <Text style={styles.view_report_page_4_top_row_h2_orange}>Recommendations</Text>
                        <Text style={styles.view_report_page_4_top_row_p}>Career Categories with the highest interest scores are further 
                        explained in this section. You are given detailed information about What to Study, Career options you can 
                        pursue, and the Qualities you must have or need to develop to build a successful career in your interest 
                        areas.</Text>
                    </View>
                    {(noInterest && (langId === 0))? <Text style={{...styles.view_report_page_4_paragraph, marginTop: "4rem", marginBottom: "15rem"}}>
                            Your results show that you do not have interest in any of the career categories identified by the Eval Test. Please feel free to contact Eval on uniqueausmum@gmail.com for further information or to avail career counseling.
                        </Text> : null }
                    {(noInterest && (langId === 1))? <Text style={{...styles.view_report_page_4_paragraph, marginTop: "4rem", marginBottom: "15rem"}}>आपके परिणाम दिखाते हैं कि आपके पास एवल टेस्ट द्वारा पहचाने गए किसी भी कैरियर श्रेणी में कोई दिलचस्पी नहीं है। अधिक जानकारी के लिए या करियर काउंसलिंग का लाभ उठाने के लिए कृपया uniqueausmum@gmail.com पर  संपर्क करें।                                                                             </Text> : null }
                    {(noInterest && (langId === 2))? <Text style={{...styles.view_report_page_4_paragraph, marginTop: "4rem", marginBottom: "15rem"}}>आपले निकाल दर्शवतात की आपल्याला एव्हल टेस्टद्वारे ओळखल्या जाणार्‍या कोणत्याही करिअर प्रकारात रस नाही. अधिक माहितीसाठी किंवा करिअर समुपदेशनासाठी uniqueausmum@gmail.com मोकळ्या मनाने संपर्क साधा.                                                                      </Text> : null }
                    {(noInterest && (langId === 3))? <Text style={{...styles.view_report_page_4_paragraph, marginTop: "4rem", marginBottom: "15rem"}}>તમારા પરિણામો દર્શાવે છે કે તમને ઈવલ ટેસ્ટ દ્વારા ઓળખવામાં આવેલા કારકિર્દીની કોઈ પણ કેટેગરીમાં રસ નથી. કૃપા કરીને વધુ માહિતી માટે અથવા કારકિર્દીની સલાહ uniqueausmum@gmail.com પર ઈવલનો સંપર્ક કરવા માટે મુક્ત અનુભવો.                                                                             </Text> : null }

                    {!noInterest?
                        [
                            ((filteredScores[0].score >= 50 && langId === 0)? <Text style={styles.view_report_page_4_top_row_p_large_text}>Based on your Interest Scores, Eval has identified the following as your Top Interest Areas: </Text> : null),
                            ((filteredScores[0].score >= 50 && langId === 1)? <Text style={styles.view_report_page_4_top_row_p_large_text_hindi}>आपके ब्याज स्कोर के आधार पर, इव्हल ने आपके शीर्ष रूचि क्षेत्रों के रूप में निम्नलिखित की पहचान की है:                                       </Text> : null),
                            ((filteredScores[0].score >= 50 && langId === 2)? <Text style={styles.view_report_page_4_top_row_p_large_text_hindi}>आपल्या आवडीच्या स्कोअरच्या आधारावर, इव्हलने आपले शीर्ष व्याज क्षेत्र म्हणून खालील गोष्टी ओळखल्या आहेत:                                       </Text> : null),
                            ((filteredScores[0].score >= 50 && langId === 3)? <Text style={styles.view_report_page_4_top_row_p_large_text_hindi}>તમારા રુચિના સ્કોરના આધારે, ઈવલે નીચેનાને તમારા ટોચના રુચિવાળા ક્ષેત્રો તરીકે ઓળખ આપી છેઃ                                   </Text> : null),

                            ((filteredScores[0].score < 50 && langId === 0)?<Text style={styles.view_report_page_4_top_row_p_large_text}>Eval has identified the following choices based on your answers to the questions asked. These choices are not of high or moderate interest. Get in 
                            touch with our counsellors for further assistance.</Text> : null),
                            ((filteredScores[0].score < 50 && langId === 1)?<Text style={styles.view_report_page_4_top_row_p_large_text_hindi}>इव्हाल्व ने पूछे गए प्रश्नों के आपके उत्तरों के आधार पर निम्नलिखित विकल्पों की पहचान की है। ये विकल्प उच्च या मध्यम ब्याज के नहीं हैं। आगे की सहायता के लिए हमारे काउंसलर्स से संपर्क करें।</Text> : null),
                            ((filteredScores[0].score < 50 && langId === 2)?<Text style={styles.view_report_page_4_top_row_p_large_text_hindi}>विचारलेल्या प्रश्नांच्या उत्तरांच्या आधारे एवलने खालील निवडी ओळखल्या आहेत. या निवडी उच्च किंवा मध्यम स्वारस्याच्या नाहीत. पुढील सहाय्यासाठी आमच्या सल्लागारांशी संपर्क साधा.                                                               </Text> : null),
                            ((filteredScores[0].score < 50 && langId === 3)?<Text style={styles.view_report_page_4_top_row_p_large_text_hindi}>ઈવલે પૂછાયેલ પ્રશ્નોના તમારા જવાબોને આધારે નીચેની પસંદગીઓ ઓળખી છે. આ પસંદગીઓ ઉચ્ચ અથવા મધ્યમ હિતની નથી. વધુ સહાય માટે અમારા સલાહકારો સાથે સંપર્ક કરો.</Text> : null),

                            filteredReportTexts.map((data, index) => {
                                return(
                                    <View style={styles.view_report_page_4_recommendation_item}>
                                        {(langId === 0)?<Text style={styles.view_report_page_4_recommendation_item_h2_orange}>{(index + 1) + ". " + data.languages[langId].title + " (" + filteredScores[index].score + "%)      "}</Text> : null} 
                                        {(langId === 1)?<Text style={styles.view_report_page_4_recommendation_item_h2_orange_hindi}>{(index + 1) + ". " + data.languages[langId].title.trim() + " (" + filteredScores[index].score + "%)           "}</Text> : null} 
                                        {(langId === 2)?<Text style={styles.view_report_page_4_recommendation_item_h2_orange_hindi}>{(index + 1) + ". " + data.languages[langId].title.trim() + " (" + filteredScores[index].score + "%)           "}</Text> : null} 
                                        {(langId === 3)?<Text style={styles.view_report_page_4_recommendation_item_h2_orange_hindi}>{(index + 1) + ". " + data.languages[langId].title.trim() + " (" + filteredScores[index].score + "%)           "}</Text> : null} 
                                        <View style={styles.view_report_page_4_recommendation_subitem}>
                                            {(langId === 0)?<Text style={styles.view_report_page_4_recommendation_subitem_subtitle}>What to Study</Text> : null}
                                            {(langId === 1)?<Text style={styles.view_report_page_4_recommendation_subitem_subtitle_hindi}>क्या अध्ययन करें</Text> : null}
                                            {(langId === 2)?<Text style={styles.view_report_page_4_recommendation_subitem_subtitle_hindi}>काय अभ्यास करा</Text> : null}
                                            {(langId === 3)?<Text style={styles.view_report_page_4_recommendation_subitem_subtitle_hindi}>શું ભણવું જોઈએ</Text> : null}
                                            {data.languages[langId].recommendations.whatToStudy.main.map((item, studyMainIndex) => {
                                                return <Text style={styles.view_report_page_4_recommendation_subitem_paragraph_hindi}>{item}</Text>
                                            })}
                                            {data.languages[langId].recommendations.whatToStudy.points &&
                                            data.languages[langId].recommendations.whatToStudy.points.length &&
                                            data.languages[langId].recommendations.whatToStudy.points.map((item, studyPointIndex) => {
                                                return <Text style={styles.view_report_page_4_recommendation_subitem_paragraph_nomargin_hindi}>{item}</Text>
                                            })}                                                
                                        </View>
                                        <View style={styles.view_report_page_4_recommendation_subitem}>
                                            {(langId === 0)? <Text style={styles.view_report_page_4_recommendation_subitem_subtitle}>Careers</Text> : null}
                                            {(langId === 1)? <Text style={styles.view_report_page_4_recommendation_subitem_subtitle_hindi}>करियर        </Text> : null}
                                            {(langId === 2)? <Text style={styles.view_report_page_4_recommendation_subitem_subtitle_hindi}>करियर        </Text> : null}
                                            {(langId === 3)? <Text style={styles.view_report_page_4_recommendation_subitem_subtitle_hindi}>કરિયર્સ        </Text> : null}

                                            {data.languages[langId].recommendations.careers.main.map(careerData => {
                                                return(
                                                    <Text style={styles.view_report_page_4_recommendation_subitem_paragraph_hindi}>{careerData}</Text>
                                                )
                                            })}
                                            {data.languages[langId].recommendations.careers.points &&
                                            data.languages[langId].recommendations.careers.points.length &&
                                            data.languages[langId].recommendations.careers.points.map(careerData => {
                                                return(
                                                    <Text style={styles.view_report_page_4_recommendation_subitem_paragraph_nomargin_hindi}>{careerData}</Text>
                                                )
                                            })}
                                        </View>
                                        <View style={styles.view_report_page_4_recommendation_subitem}>
                                            {(langId === 0)? <Text style={styles.view_report_page_4_recommendation_subitem_subtitle}>Qualities</Text> : null}
                                            {(langId === 1)? <Text style={styles.view_report_page_4_recommendation_subitem_subtitle_hindi}>गुण  </Text> : null}
                                            {(langId === 2)? <Text style={styles.view_report_page_4_recommendation_subitem_subtitle_hindi}>गुण    </Text> : null}
                                            {(langId === 3)? <Text style={styles.view_report_page_4_recommendation_subitem_subtitle_hindi}>ગુણવત્તાઓ        </Text> : null}
                                            <Text style={styles.view_report_page_4_recommendation_subitem_paragraph_hindi}>{data.languages[langId].recommendations.qualities}</Text>
                                        </View>
                                    </View>
                                )
                            })
                        ] : null
                    }    
                </Page>        
                {/* --------------------------- WORK STYLE INDICATORS------------------------------ */}
                <Page size="A4" style={styles.view_report_page_4} orientation="landscape">  
                    <View style={styles.view_report_fixed_icon_row}>
                        {vcura? <Image style={styles.view_report_fixed_icon_1} src={EvalLogo}
                            alt="Eval logo"/>:null}
                        {vcura? <Image style={styles.view_report_fixed_icon_2} src={VcuraLogo}
                            alt="Eval logo"/>:null} 
                    </View>  
                    <View style={styles.view_report_page_4_top_row}>
                        <Text style={styles.view_report_page_4_top_row_h2}>Work Style Indicators</Text>
                        <Text style={styles.view_report_page_4_top_row_p}>Your interest in the following Work Style Indicators shows 
                        your preference for certain working conditions and environments. They can help you narrow down what kind of job you 
                        would like to pursue within your interest areas.</Text>
                    </View>          
                    {(langId === 0)?<Text style={styles.view_report_page_4_top_row_p_large_text}>Based on your responses we have evaluated your interest in the following work style indicators: </Text> : null}
                    {(langId === 1)?<Text style={styles.view_report_page_4_top_row_p_large_text_hindi}>आपकी प्रतिक्रियाओं के आधार पर हमने निम्नलिखित कार्य शैली संकेतकों में आपकी रुचि का मूल्यांकन किया है: </Text> : null}
                    {(langId === 2)?<Text style={styles.view_report_page_4_top_row_p_large_text_hindi}>आपल्या प्रतिसादाच्या आधारे आम्ही खालील कार्यशैली निर्देशकांमधील आपल्या स्वारस्याचे मूल्यांकन केले आहे:                                      </Text> : null}
                    {(langId === 3)?<Text style={styles.view_report_page_4_top_row_p_large_text_hindi}>તમારા પ્રતિભાવોના આધારે અમે નીચેની કાર્ય શૈલીના સૂચકાંકોમાં તમારી રુચિનું મૂલ્યાંકન કર્યું છે: </Text> : null}
                    <View style={styles.view_reports_page_4_graphs}>
                        {extraScores.map((scoreItem, index, arr) => {
                            const reportText = reportTexts.filter((text, index) => text.name === "Extra")[0].texts.categories.filter((textItem, textIndex) => {
                                return textItem.name === scoreItem.category
                            })[0];
                            return <View style={styles.view_reports_page_4_graph_item}>
                                <Text style={styles.view_reports_page_4_graph_text_hindi}>{reportText.languages[langId].title}</Text>
                                <View style={styles.view_reports_page_4_graph_bar}>
                                    <View style={StyleSheet.create({bar: {
                                            width: scoreItem.score + "%",
                                            backgroundColor: "#AAE6BC",
                                            height: "100%", position: "absolute",
                                            top: "0",
                                            left: "0",}}).bar} />
                                    <Text style={styles.view_reports_page_4_graph_number}>{scoreItem.score}</Text>
                                </View>
                            </View>
                        })}                        
                    </View>
                    {
                        reportTexts.filter((text, index) => text.name === "Extra")[0].texts.categories.map((item, index) => {
                            return <View style={styles.view_report_page_4_recommendation_item}>
                                <Text style={styles.view_report_page_4_recommendation_item_h2_hindi}>{(index+1) + ". " + item.languages[langId].title.trim() + " (" + extraScores[index].score + "%)            "}</Text>
                                <Text style={styles.view_report_page_4_recommendation_subitem_paragraph_hindi}>{item.languages[langId].main}</Text>
                                <View style={styles.view_report_page_4_recommendation_subitem}>
                                    {(langId === 0)? <Text style={styles.view_report_page_4_recommendation_subitem_subtitle}>Tips to improve</Text> : null}
                                    {(langId === 1)? <Text style={styles.view_report_page_4_recommendation_subitem_subtitle_hindi}>सुधारने के टिप्स            </Text> : null}
                                    {(langId === 2)? <Text style={styles.view_report_page_4_recommendation_subitem_subtitle_hindi}>सुधारण्यासाठी टिपा            </Text> : null}
                                    {(langId === 3)? <Text style={styles.view_report_page_4_recommendation_subitem_subtitle_hindi}>સુધારવા માટેની ટિપ્સ            </Text> : null}
                                    <Text style={styles.view_report_page_4_recommendation_subitem_paragraph_hindi}>{item.languages[langId].tips}</Text>
                                </View>
                            </View>
                        })
                    }                        
                </Page>

                {/* --------------------------- SUMMARY -------------------------------- */}
                {
                    noInterest?
                    null : <Page size="A4" style={styles.view_report_page_5} orientation="landscape">
                            <View style={styles.view_report_fixed_icon_row}>
                                {vcura? <Image style={styles.view_report_fixed_icon_1} src={EvalLogo}
                                    alt="Eval logo" fixed/>:null}
                                {vcura? <Image style={styles.view_report_fixed_icon_2} src={VcuraLogo}
                                    alt="Eval logo" fixed/>:null} 
                            </View>  
                            <View style={styles.view_report_page_5_top_row}>
                                <Text style={styles.view_report_page_5_top_row_h2}>Summary</Text>
                            </View>
                            {(langId === 0)?<Text style={styles.view_report_page_5_top_row_p_large_text}>{interestAreas}</Text>: null} 
                            {(langId === 1)?<Text style={styles.view_report_page_5_top_row_p_large_text_hindi}>{interestAreas}</Text>: null} 
                            {(langId === 2)?<Text style={styles.view_report_page_5_top_row_p_large_text_hindi}>{interestAreas}</Text>: null} 
                            {(langId === 3)?<Text style={styles.view_report_page_5_top_row_p_large_text_hindi}>{interestAreas}</Text>: null} 
                            {(langId === 0)? <Text style={styles.view_report_page_5_p_note_text}>Please Note: You would be able to pursue a satisfying career in any of these
                            categories or a combination of these categories. The report has identified them as an interest area for you.</Text> : null}
                            {(langId === 1)? <Text style={styles.view_report_page_5_p_note_text_hindi}>कृपया ध्यान दें: आप इनमें से किसी भी श्रेणी या इन श्रेणियों के संयोजन में एक संतोषजनक कैरियर बनाने में सक्षम होंगे। रिपोर्ट ने उन्हें आपके लिए एक रुचि क्षेत्र के रूप में पहचाना है।</Text> : null}
                            {(langId === 2)? <Text style={styles.view_report_page_5_p_note_text_hindi}>कृपया लक्षात ठेवाः आपण यापैकी कोणत्याही श्रेणीमध्ये समाधानकारक करिअर किंवा या श्रेणींच्या संयोजनात सक्षम असाल. अहवालात ते आपल्यासाठी स्वारस्य असलेले क्षेत्र म्हणून ओळखले गेले आहे.                                                                 </Text> : null}
                            {(langId === 3)? <Text style={styles.view_report_page_5_p_note_text_hindi}>કૃપયા નોંધો: તમે આમાંથી કોઈ પણ કેટેગરીમાં સંતોષકારક કારકિર્દી અથવા આ શ્રેણીઓનું સંયોજન કરી શકશો. અહેવાલમાં તેમને તમારા માટે હિત ક્ષેત્ર તરીકે ઓળખવામાં આવ્યા છે.</Text> : null}
                            <View style={styles.view_report_page_5_summary_item}>
                                {(qualitiesArray&&qualitiesArray.length)?
                                    <View style={styles.view_report_page_5_summary_subitem}>
                                        {(langId === 0)?<Text style={styles.view_report_page_5_summary_item_subtitle}>Your profile further speaks about you having the following qualities</Text>: null}
                                        {(langId === 1)?<Text style={styles.view_report_page_5_summary_item_subtitle_hindi}>आपकी प्रोफ़ाइल आगे बताती है कि आपके पास निम्नलिखित गुण हैं:</Text>: null}
                                        {(langId === 2)?<Text style={styles.view_report_page_5_summary_item_subtitle_hindi}>आपले प्रोफाइल पुढील गुणांबद्दल आपल्याबद्दल सांगते:                    </Text>: null}
                                        {(langId === 3)?<Text style={styles.view_report_page_5_summary_item_subtitle_hindi}>તમારી પ્રોફાઇલ તમારામાં નીચેના ગુણો હોવા વિશે આગળ બોલે છે:</Text>: null}
                                        <View style={styles.view_report_page_5_subitem_table}>
                                            {(langId === 0)? <View style={styles.view_report_page_5_subitem_table_title_row}>
                                                <View style={styles.view_report_page_5_subitem_table_title_label}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text}>Category</Text>
                                                </View>
                                                <View style={styles.view_report_page_5_subitem_table_title_value}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text}>Qualities</Text>
                                                </View>
                                            </View> : null}
                                            {(langId === 1)? <View style={styles.view_report_page_5_subitem_table_title_row}>
                                                <View style={styles.view_report_page_5_subitem_table_title_label}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text_hindi}>श्रेणी</Text>
                                                </View>
                                                <View style={styles.view_report_page_5_subitem_table_title_value}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text_hindi}>गुण</Text>
                                                </View>
                                            </View> : null}
                                            {(langId === 2)? <View style={styles.view_report_page_5_subitem_table_title_row}>
                                                <View style={styles.view_report_page_5_subitem_table_title_label}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text_hindi}>श्रेणी</Text>
                                                </View>
                                                <View style={styles.view_report_page_5_subitem_table_title_value}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text_hindi}>गुण</Text>
                                                </View>
                                            </View> : null}
                                            {(langId === 3)? <View style={styles.view_report_page_5_subitem_table_title_row}>
                                                <View style={styles.view_report_page_5_subitem_table_title_label}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text_hindi}>કેટેગરી</Text>
                                                </View>
                                                <View style={styles.view_report_page_5_subitem_table_title_value}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text_hindi}>ગુણવત્તાઓ</Text>
                                                </View>
                                            </View> : null}
                                            {qualitiesArray.map((qualitiesItem, qualitiesIndex) => {
                                                return <View style={styles.view_report_page_5_subitem_table_item_row}>
                                                    <View style={styles.view_report_page_5_subitem_table_item_row_label}>
                                                        <Text style={styles.view_report_page_5_subitem_table_text_hindi}>{qualitiesItem.category}</Text>
                                                    </View>
                                                    <View style={styles.view_report_page_5_subitem_table_item_row_value}>
                                                        <Text style={styles.view_report_page_5_subitem_table_text_hindi}>{qualitiesItem.qualities}</Text>
                                                    </View>
                                                </View>
                                            })}
                                        </View>
                                    </View>:null
                                }
                                {(testItem.id === 1)?
                                    <View style={styles.view_report_page_5_summary_subitem}>
                                        {(langId === 0)? <Text style={styles.view_report_page_5_summary_item_subtitle}>Suggested streams and subjects</Text>: null}
                                        {(langId === 1)? <Text style={styles.view_report_page_5_summary_item_subtitle_hindi}>सुझाई गई धाराएँ और विषय</Text>: null}
                                        {(langId === 2)? <Text style={styles.view_report_page_5_summary_item_subtitle_hindi}>सुचविलेले प्रवाह आणि विषय           </Text>: null}
                                        {(langId === 3)? <Text style={styles.view_report_page_5_summary_item_subtitle_hindi}>સૂચવેલ સ્ટ્રીમ્સ અને વિષયો</Text>: null}
                                        <View style={styles.view_report_page_5_subitem_table}>
                                            {(langId === 0)? <View style={styles.view_report_page_5_subitem_table_title_row}>
                                                <View style={styles.view_report_page_5_subitem_table_title_label}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text}>Category</Text>
                                                </View>
                                                <View style={styles.view_report_page_5_subitem_table_title_value}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text}>What to Study</Text>
                                                </View>
                                            </View> : null}
                                            {(langId === 1)? <View style={styles.view_report_page_5_subitem_table_title_row}>
                                                <View style={styles.view_report_page_5_subitem_table_title_label}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text_hindi}>श्रेणी</Text>
                                                </View>
                                                <View style={styles.view_report_page_5_subitem_table_title_value}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text_hindi}>क्या अध्ययन करें</Text>
                                                </View>
                                            </View> : null}
                                            {(langId === 2)? <View style={styles.view_report_page_5_subitem_table_title_row}>
                                                <View style={styles.view_report_page_5_subitem_table_title_label}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text_hindi}>श्रेणी</Text>
                                                </View>
                                                <View style={styles.view_report_page_5_subitem_table_title_value}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text_hindi}>काय अभ्यास करा</Text>
                                                </View>
                                            </View> : null}
                                            {(langId === 3)? <View style={styles.view_report_page_5_subitem_table_title_row}>
                                                <View style={styles.view_report_page_5_subitem_table_title_label}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text_hindi}>કેટેગરી</Text>
                                                </View>
                                                <View style={styles.view_report_page_5_subitem_table_title_value}>
                                                    <Text style={styles.view_report_page_5_subitem_table_text_hindi}>શું ભણવું જોઈએ</Text>
                                                </View>
                                            </View> : null}
                                            {filteredReportTexts.map((reportText, reportTextIndex) => {
                                                return <View style={styles.view_report_page_5_subitem_table_item_row}>
                                                    <View style={styles.view_report_page_5_subitem_table_item_row_label}>
                                                        <Text style={styles.view_report_page_5_subitem_table_text_hindi}>{reportText.languages[langId].title}</Text>
                                                    </View>
                                                    <View style={styles.view_report_page_5_subitem_table_item_row_value}>
                                                        <Text style={styles.view_report_page_5_subitem_table_text_hindi}>{reportText.languages[langId].recommendations.stream}</Text>
                                                    </View>
                                                </View>
                                            })}
                                        </View>
                                        {multipleHighInterest && (langId === 0)?
                                            <Text style={styles.view_report_page_5_top_row_p_large_text}>Since you have high interest in multiple categories. You
                                                might be a fit for more than one stream in your higher school years. To
                                                help you finally select the subjects at school you must read about the
                                                job options in each category to see which seems most exciting to you. After
                                                you narrow down on the jobs of your interest, one of the high-interest categories
                                                can be finalised, hence showing you the subjects you need to focus on.</Text> : null
                                        }
                                        {multipleHighInterest && (langId === 1)?
                                            <Text style={styles.view_report_page_5_top_row_p_large_text_hindi}>क्योंकि आप कई श्रेणियों में उच्च रुचि रखते हैं। आप अपने उच्च विद्यालय के वर्षों में एक से अधिक स्ट्रीम के लिए फिट हो सकते हैं। अंत में आपको स्कूल में उन विषयों का चयन करने में मदद करने के लिए आपको प्रत्येक श्रेणी में नौकरी के विकल्पों के बारे में पढ़ना होगा, जो आपको सबसे रोमांचक लगते हैं। जब आप अपनी रुचि की नौकरियों पर संकीर्ण हो जाते हैं, तो उच्च-ब्याज श्रेणियों में से एक को अंतिम रूप दिया जा सकता है, इसलिए आपको उन विषयों को दिखाना होगा जिन पर आपको ध्यान केंद्रित करने की आवश्यकता है।                                                                                                                                                                              </Text> : null
                                        }
                                        {multipleHighInterest && (langId === 2)?
                                            <Text style={styles.view_report_page_5_top_row_p_large_text_hindi}>आपल्याला एकाधिक श्रेणींमध्ये जास्त रस असल्याने. आपल्या उच्च शालेय वर्षांमध्ये आपण एकापेक्षा जास्त प्रवाहासाठी तंदुरुस्त आहात. शेवटी आपल्याला शाळेत विषय निवडण्यास मदत करण्यासाठी आपल्यासाठी कोणत्या श्रेणीतील नोकरीच्या पर्यायांबद्दल आपण वाचले पाहिजे जे आपल्यासाठी सर्वात रोमांचक वाटेल. आपण आपल्या आवडीच्या नोकर्‍या कमी केल्यावर, उच्च-व्याज श्रेणीपैकी एक निश्चित केली जाऊ शकते, म्हणूनच आपल्याला ज्या विषयांवर लक्ष केंद्रित करण्याची आवश्यकता आहे ते दर्शविते.                                                                                                                                                                 </Text> : null
                                        }
                                        {multipleHighInterest && (langId === 3)?
                                            <Text style={styles.view_report_page_5_top_row_p_large_text_hindi}>તમને બહુવિધ કેટેગરીમાં વધુ રસ હોવાથી તમે તમારી ઉચ્ચ શાળાના વર્ષોમાં એક થી વધુ પ્રવાહ માટે યોગ્ય હોઈ શકો છો. આખરે તમને શાળામાં વિષયો પસંદ કરવામાં મદદ કરવા માટે તમારે દરેક કેટેગરીમાં નોકરીના વિકલ્પો વિશે વાંચવું જોઈએ જે તમને સૌથી રોમાંચક લાગે છે. તમે તમારા હિતની નોકરીઓ પર સંકુચિત થયા પછી, ઉચ્ચ હિતની એક કેટેગરીને અંતિમ સ્વરૂપ આપી શકાય છે, તેથી તમારે જે વિષયો પર ધ્યાન કેન્દ્રિત કરવાની જરૂર છે તે તમને બતાવી શકાય છે.                                                                                                                                                   </Text> : null
                                        }
                                    </View>:null
                                }
                                {(careerOptions&& careerOptions.length)?
                                    <View style={styles.view_report_page_5_summary_subitem}>
                                        {(langId === 0)? <Text style={styles.view_report_page_5_summary_item_subtitle}>Career Options related to your interest:</Text> : null}
                                        {(langId === 1)? <Text style={styles.view_report_page_5_summary_item_subtitle_hindi}>आपकी रुचि से संबंधित कैरियर विकल्प:</Text> : null}
                                        {(langId === 2)? <Text style={styles.view_report_page_5_summary_item_subtitle_hindi}>आपल्या स्वारस्याशी संबंधित करियर पर्यायः                </Text> : null}
                                        {(langId === 3)? <Text style={styles.view_report_page_5_summary_item_subtitle_hindi}>તમારા હિતથી સંબંધિત કરિયર વિકલ્પો:</Text> : null}

                                        <View style={styles.view_report_page_5_summary_subitem_career_options}>
                                            <View style={styles.view_report_page_5_summary_subitem_career_options_column}>
                                                {careerOptions.map((option, index, arr) => {
                                                    if(index <= arr.length/2){
                                                        return <Text style={styles.view_report_page_5_summary_subitem_career_options_p_hindi} key={"careerOption" + index}>{option}</Text>
                                                    }else return null
                                                    })    
                                                }
                                            </View>
                                            <View style={styles.view_report_page_5_summary_subitem_career_options_column}>
                                                {careerOptions.map((option, index, arr) => {
                                                    if(index > arr.length/2){
                                                        return <Text style={styles.view_report_page_5_summary_subitem_career_options_p_hindi} key={"careerOption" + index}>{option}</Text>
                                                    }else return null
                                                    })    
                                                }
                                            </View>                                                
                                        </View>
                                    </View> : null
                                }
                                {(langId === 0)? <Text style={styles.view_report_page_5_summary_item_bold_p}>It is important that you read more about the job options provided in
                                    the career categories above. You will get a clearer understanding of where you see
                                    yourself working and what appeals to you most.</Text> : null}
                                {(langId === 1)? <Text style={styles.view_report_page_5_summary_item_bold_p_hindi}>यह महत्वपूर्ण है कि आप ऊपर दिए गए कैरियर श्रेणियों में प्रदान किए गए नौकरी के विकल्पों के बारे में अधिक पढ़ें। आपको इस बात की स्पष्ट समझ हो जाएगी कि आप अपने आप को कहां काम करते देखते हैं और आप सबसे क्या अपील करते हैं।</Text> : null}
                                {(langId === 2)? <Text style={styles.view_report_page_5_summary_item_bold_p_hindi}>आपण वरील करिअरच्या श्रेणींमध्ये प्रदान केलेल्या नोकरीच्या पर्यायांबद्दल अधिक वाचणे महत्वाचे आहे. आपण स्वत: कोठे काम करत असल्याचे आणि आपल्याला सर्वात जास्त कशाचे आवाहन करतात याचा एक स्पष्ट समज मिळेल.                                                                        </Text> : null}
                                {(langId === 3)? <Text style={styles.view_report_page_5_summary_item_bold_p_hindi}>તે મહત્વપૂર્ણ છે કે તમે ઉપરની કરિયર કેટેગરીમાં પૂરા પાડવામાં આવેલા નોકરીના વિકલ્પો વિશે વધુ વાંચો. તમે તમારી જાતને ક્યાં કામ કરતા જુઓ છો અને તમને સૌથી વધુ શું અપીલ કરે છે તેની સ્પષ્ટ સમજ તમને મળશે.</Text> : null}
                                                                
                                {(langId === 0)? <Text style={styles.view_report_page_5_summary_item_bold_p}>Your work style indicators (Collaborative, Communication,Outdoor and
                                    Client Facing) can also help you narrow down your decision further. It's important to
                                    understand if the career category chosen according to your interest also fits in with your style indicator.</Text> : null}
                                {(langId === 1)? <Text style={styles.view_report_page_5_summary_item_bold_p_hindi}>आपकी कार्यशैली संकेतक (सहयोगात्मक, संचार, आउटडोर और क्लाइंट फ़ेसिंग) भी आपके निर्णय को आगे बढ़ाने में आपकी मदद कर सकते हैं। यह समझना महत्वपूर्ण है कि आपकी रुचि के अनुसार चुना गया कैरियर वर्ग आपकी शैली संकेतक के साथ भी फिट बैठता है या नहीं।</Text> : null}
                                {(langId === 2)? <Text style={styles.view_report_page_5_summary_item_bold_p_hindi}>आपले कार्य शैली निर्देशक (सहयोगी, संप्रेषण, मैदानी आणि क्लायंट फेसिंग) देखील आपला निर्णय कमी करण्यात मदत करतात. आपल्या आवडीनुसार निवडलेली करिअर श्रेणी आपल्या शैली निर्देशकासह देखील बसत असेल तर हे समजणे महत्वाचे आहे.                                                                              </Text> : null}
                                {(langId === 3)? <Text style={styles.view_report_page_5_summary_item_bold_p_hindi}>તમારી કાર્ય શૈલીના સૂચકાંકો (સહયોગી, સંદેશાવ્યવહાર, આઉટડોર અને ક્લાયન્ટ ફેસિંગ) પણ તમને તમારા નિર્ણયને વધુ સંકુચિત કરવામાં મદદ કરી શકે છે. તમારા રુચિ અનુસાર પસંદ કરેલી કરિયરની કેટેગરી પણ તમારી શૈલી સૂચક સાથે બંધ બેસે છે કે નહીં તે સમજવું મહત્વપૂર્ણ છે.</Text> : null}
                                
                                
                                {(langId === 0)? <Text style={styles.view_report_page_5_summary_item_bold_p}>Thank you for Eval….ing and seeking clarity for your career interests.
                                    We wish you all the best for your future endeavours.</Text> : null}
                                {(langId === 1)? <Text style={styles.view_report_page_5_summary_item_bold_p_hindi}>ईवल के लिए धन्यवाद…। अपने कैरियर के हितों के लिए स्पष्टता की मांग करें। हम आपके भविष्य के प्रयासों के लिए आपको शुभकामनाएं देते हैं।</Text> : null}
                                {(langId === 2)? <Text style={styles.view_report_page_5_summary_item_bold_p_hindi}>एव्हइंग केल्याबद्दल धन्यवाद आणि आपल्या करियरच्या आवडींसाठी स्पष्टीकरण शोधत आहात. आपल्या भावी प्रयत्नांसाठी आम्ही सर्व शुभेच्छा देतो.                                                 </Text> : null}
                                {(langId === 3)? <Text style={styles.view_report_page_5_summary_item_bold_p_hindi}>તમારા કરિયર હિતોનું ઈવલિંગ અને સ્પષ્ટતા મેળવવા માટે આભાર. અમે તમને તમારા ભવિષ્યના પ્રયાસો માટે શુભેચ્છા પાઠવીએ છીએ.</Text> : null}
                                
                                
                                {(langId === 0)? <Text style={{...styles.view_report_page_5_summary_item_bold_p, textAlign: "center"}}>“Remember Whatever Path You choose, The Path also chooses you”</Text> : null}
                                {(langId === 1)? <Text style={{...styles.view_report_page_5_summary_item_bold_p_hindi, textAlign: "center"}}>“याद रखें कि आप जो भी रास्ता चुनते हैं, वह रास्ता भी आपको चुनता है”</Text> : null}
                                {(langId === 2)? <Text style={{...styles.view_report_page_5_summary_item_bold_p_hindi, textAlign: "center"}}>“तुम्ही कोणता मार्ग निवडता ते लक्षात ठेवा, पथ तुम्हाला निवडतो”                        </Text> : null}
                                {(langId === 3)? <Text style={{...styles.view_report_page_5_summary_item_bold_p_hindi, textAlign: "center"}}>“યાદ રાખો ગમે તે માર્ગ તમે પસંદ કરો, માર્ગ પણ તમને પસંદ કરે છે!”                         </Text> : null}
                            </View>
                    </Page>
                }
                

                <Page size="A4" style={styles.view_report_page_6} orientation="landscape">
                    <View style={styles.view_report_fixed_icon_row}>
                        {vcura? <Image style={styles.view_report_fixed_icon_1} src={EvalLogo}
                            alt="Eval logo"/>:null}
                        {vcura? <Image style={styles.view_report_fixed_icon_2} src={VcuraLogo}
                            alt="Eval logo"/>:null} 
                    </View>   
                    <Image style={styles.view_report_page_6_img} src={ReportBotImage} alt={"Students using a laptop"}/>
                    {(langId === 0)? <Text style={styles.view_report_page_6_p}>To enquire about a career counselling appointment, please email us at evalcareertest@gmail.com with the title “Career Counselling Inquiry”. You can also call us or message us on WhatsApp on 9833808612.</Text> : null} 
                    {(langId === 1)? <Text style={styles.view_report_page_6_p_hindi}>एक कैरियर परामर्श नियुक्ति के बारे में पूछताछ करने के लिए, हमें “Career Counselling Inquiry” के साथ uniqueausmum@gmail.com पर ईमेल करें। आप हमें 9833808612 पर व्हाट्सएप पर कॉल या मैसेज भी कर सकते हैं।</Text> : null} 
                    {(langId === 2)? <Text style={styles.view_report_page_6_p_hindi}>करिअर समुपदेशन भेटीबद्दल विचारणा करण्यासाठी, कृपया आम्हाला “करिअर समुपदेशन चौकशी” या शीर्षकासह evalcareertest@gmail.com वर ईमेल करा. आपण 9833808612 वर आम्हाला कॉल करू शकता किंवा व्हॉट्सअ‍ॅपवर संदेश देऊ शकता.                                                                           </Text> : null} 
                    {(langId === 3)? <Text style={styles.view_report_page_6_p_hindi}>કરિયર પરામર્શની નિમણૂક વિશે પૂછપરછ કરવા માટે, કૃપા કરીને અમને “Career Counselling Inquiry” શીર્ષક સાથે uniqueausmum@gmail.com પર ઇમેઇલ કરો. તમે અમને 9833808612 પર પણ ફોન કરી શકો છો અથવા અમને વોટ્સએપ મેસેજ કરી શકો છો.</Text> : null} 
                </Page>
            </Document>
    )
} 

export function ReportPdfAsBlob({responseData, ...props}){
    //Report text states
    const [name, setName] = useState(props.name);
    const [testId, setTestId] = useState(null);
    const [testItem, setTestItem] = useState(null);
    const [date, setDate] = useState(null);
    const [langId, setLangId] = useState(props.langId);

    console.log(props.langId);
    console.log(langId);

    // Recommendation states
    const [sortedScores, setSortedScores] = useState(null); // Named the way it is because it used to hold scores sorted in descending order, now it only contains scores
    const [filteredScores, setFilteredScores] = useState(null); // Contains scores in descending order from the highest to the 5th highest score
    const [extraScores, setExtraScores] = useState(null); // Scores of the four work style indicators categories, not included in sortedScores or filteredScores
    const [sortedReportTexts, setSortedReportTexts] = useState(null); // Redundant
    const [filteredReportTexts, setFilteredReportTexts] = useState(null); // Recommendations texts for the filteredScores to be shown in the recommendations page
    const [noInterest, setNoInterest] = useState(false); // Boolean that is true when all non work style indicators categories have their scores equal to 0
    // Boolean that is true when more than one category has >=75 score
    const [multipleHighInterest, setMultipleHighInterest] = useState(false);

    // Summary states
    const [interestAreas, setInterestAreas] = useState(""); // Interest areas text that appears at the top of every summary sectionn regaredless of the test
    const [generalQualities, setGeneralQualities] = useState(null); // qualities object to be shown in a table
    const [generalStreams, setGeneralStreams] = useState(null); // streams object to be shown in a table
    const [careerOptions, setCareerOptions] = useState(null); // an array containing all the career options to be shown to the user.
    const [qualitiesArray, setQualitiesArray] = useState(null); // An array containing all the qualities required

    // Blob loading states
    const [blob, setBlob] = useState(null);
    const [blobLoading, setBlobLoading] = useState(true);

    const [responseSent] = useMutation(SET_RESPONSE_SENT_MUTATION);

    
    // Set test text states when data arrives
    useEffect(() => {
        if(responseData && responseData.responses.length) {
            // SORT THE RESPONSE ACCORDING TO SCORE VALUES IN DESCENDING ORDER
            // RETURN SORTED ARRAY WITH ALL CATEGORIES EXCEPT THE SPECIAL 4 COMMON THROUGHOUT ALL TESTS. SPECIAL 4 CATEGORIES WILL BE STORED IN extraScores ARRAY
            const sortedScores = [];
            responseData.responses[0].scores.filter((scoreItem, index) => {
                return !(scoreItem.category.name === "Client Facing" || scoreItem.category.name === "Collaborative" || scoreItem.category.name === "Communication"
                    || scoreItem.category.name === "Outdoors");
            }).forEach((item, index) => {
                sortedScores.push({
                    category: item.category.name,
                    score: item.score
                });
            });            
            
            const extraScores = responseData.responses[0].scores.filter((scoreItem, index) => {
                return (scoreItem.category.name === "Client Facing" || scoreItem.category.name === "Collaborative" || scoreItem.category.name === "Communication"
                    ||scoreItem.category.name === "Outdoors");
            }).map((scoreItem, index) => {
                if(scoreItem.category.name === "Client Facing"||scoreItem.category.name === "Collaborative"||scoreItem.category.name === "Communication"||scoreItem.category.name === "Outdoors"){
                    return {
                        category: scoreItem.category.name,
                        score: scoreItem.score
                    }
                } else return null
            });

            // FILTER THE SCORES TO SHOW ONLY THE BEST SCORES
            let filteredScores;
            const threshold = 5;
            if(sortedScores.filter(x => x.score > 75).length >= threshold){
                filteredScores = sortedScores.filter(x => x.score > 75).sort((a, b) => b.score - a.score);
            } else if(sortedScores.filter(x => x.score > 75).length){
                if(sortedScores.filter(x => x.score > 50).length >= threshold){
                    filteredScores = sortedScores.filter(x => x.score > 50).sort((a, b) => b.score - a.score).slice(0, threshold);
                } else filteredScores = sortedScores.filter(x => x.score > 50).sort((a, b) => b.score - a.score);
            } else {
                if(sortedScores.filter(x => x.score > 50).length >= threshold) {
                    filteredScores = sortedScores.filter(x => x.score > 50).sort((a, b) => b.score - a.score).slice(0, threshold);
                } else if(sortedScores.filter(x => x.score > 50).length){
                    if(sortedScores.filter(x => x.score > 25).length >= threshold){
                        filteredScores = sortedScores.filter(x => x.score > 25).sort((a, b) => b.score - a.score).slice(0, threshold);
                    }else filteredScores = sortedScores.filter(x => x.score > 25).sort((a, b) => b.score - a.score);
                } else {
                    if(sortedScores.filter(x => x.score > 25).length >= threshold) {
                        filteredScores = sortedScores.filter(x => x.score > 25).sort((a, b) => b.score - a.score).slice(0, threshold);
                    }else if(sortedScores.filter(x => x.score > 25).length){
                        filteredScores = sortedScores.filter(x => x.score > 25).sort((a, b) => b.score - a.score);
                    } else {
                        filteredScores = sortedScores.filter(x => x.score > 0).sort((a, b) => b.score - a.score).slice(0, threshold);
                    }
                }
            }           

            if(sortedScores.filter(x => x.score).length === 0){
                setNoInterest(true);
            }else if(sortedScores.filter(x => x.score > 75).length > 1){
                setMultipleHighInterest(true);
            }

            const testTexts = reportTexts.filter(x => x.testId === responseData.responses[0].purchase.testId)[0];

            // Sorted reportTexts for classifications
            const sortedReportTexts = sortedScores.map((score, index) => {
                return testTexts.texts.categories.filter(y => y.name === score.category)[0];
            });
            

            // Filter reportTexts to have only categories that are in filteredScores array
            const filteredReportTexts = filteredScores.map((score, index) => {
                return testTexts.texts.categories.filter(y => y.name === score.category)[0];
            });

            //SET OVERALL TEST STATES
            setTestId(responseData.responses[0].purchase.testId); // SET TEST ID TO USE FOR SEARCHING OTHER STRINGS
            setName(responseData.responses[0].purchase.user.name); // SET THE USER NAME FROM DATA
            setDate(format(new Date(responseData.responses[0].createdAt), "MMMM d, yyyy")); // SET DATE OF THE TEST
            setTestItem(TEST_ID.filter(x => x.id === responseData.responses[0].purchase.testId)[0]); // TEST ITEM FROM THE CONSTANT ARRAY

            // SET TEST TEXT STATES
            // RECOMMENDATIONS TEXT STATES
            setSortedScores(sortedScores); // ALL SCORES EXCEPT WORK STYLE INDICATORS
            setFilteredScores(filteredScores); // FILTERED SCORES LIMITED TO THE 5TH RANK OF SCORES
            setExtraScores(extraScores); // WORK STYLE INDICATORS SCORES
            setSortedReportTexts(sortedReportTexts); // RECOMMENDATION TEXTS FOR ALL THE CATEGORIES
            setFilteredReportTexts(filteredReportTexts); // RECOMMENDATION TEXTS FOR ONLY THE FILTERED CATEGORIES

            // SUMMARY TEXT STATES
            // Text at the top of the summary, common for all tests
            let interestAreas;
            if(langId === 0){
                interestAreas = "Your interest areas are: ";
            } else if(langId === 1){
                interestAreas = "आपकी रुचि के क्षेत्र हैं: ";
            }  else if(langId === 2){
                interestAreas = "आपली आवड असणारी क्षेत्रे आहेत: ";
            } else if(langId === 3){
                interestAreas = "તમારા રુચિના ક્ષેત્રો છે: ";
            }

            filteredScores.forEach((scoreItem, index, arr) => {            
                const name = filteredReportTexts.filter((textItem) => textItem.name === scoreItem.category)[0].languages[langId].title;
                if((arr.length - 1) === index){
                    if(langId === 0){
                        interestAreas = interestAreas + " and " + name + "."
                    }else if(langId === 1){
                        interestAreas = interestAreas + " और " + name + ".           "
                    }else if(langId === 2){
                        interestAreas = interestAreas + " आणि " + name + ".           "
                    }else if(langId === 3){
                        interestAreas = interestAreas + " અને " + name + ".          "
                    }
                } else{
                    interestAreas = interestAreas + " " + name + ","
                }
            });
            
            
             // Career options array for summary
             let careerOptions = [];
             filteredReportTexts.forEach((textItem, textIndex) => {
                 textItem.languages[langId].recommendations.careers.points.forEach((careerOptionItem, optionIndex) => {
                     if(careerOptions.indexOf(careerOptionItem) === -1){
                         careerOptions.push(careerOptionItem);
                     }
 
                 });
             });
 
             // Qualities array for summary
             let qualitiesArray = [];
             filteredReportTexts.forEach((textItem, textIndex) => {
                 qualitiesArray.push({category: textItem.languages[langId].title, qualities: textItem.languages[langId].summary.qualities});
             });

            setQualitiesArray(qualitiesArray);
            setCareerOptions(careerOptions);
            setInterestAreas(interestAreas);
        }
        else if(responseData && responseData.responses.length === 0){
            history.push("/profile");
        }
    },[responseData, langId]);

    const history = useHistory();

    useEffect(() => {        
        if(blob){
            const filename = props.fileName;
            const file = new File([blob], filename);
            const bodyFormData = new FormData();
            bodyFormData.append('file', file, filename);
            bodyFormData.append("upload_file", true);
            axios({
                method: 'POST',
                url: "https://api.vcura.in/Counselling?UserCareerCounsellingId=" + props.userCareerCounsellingId,
                headers: {
                    "Authorization" : "Bearer 5c91f2e6af5843299a514d7047b495d1:YmI4MDUwZDUzYjU3ZTcxOA==:" + Math.trunc(new Date().getTime()/100),
                    'Content-Type': 'multipart/form-data' 
                },
                data: bodyFormData
            }).then(vcuraPostResponse => {   
                if(vcuraPostResponse.data.IsSuccess){
                    responseSent({
                        variables: {
                            id: responseData.responses[0].id
                        }
                    }).then(() => window.location.href = vcuraPostResponse.data.FileUrl).catch((err) => console.log(err))}
            }).catch(vcuraPostError => {
                console.log(vcuraPostError);
                history.push("/home");
            })  
        }else{
            return
        }
    },[blob, blobLoading]);


    if(responseData && responseData.responses.length && sortedScores && sortedScores.length &&
        filteredScores && filteredReportTexts && extraScores ) {
        return(
            <BlobProvider document={<ReportPdfAsDocument name={name} testId={testId} testItem={testItem} date={date} vcura
                sortedScores={sortedScores} filteredScores={filteredScores} extraScores={extraScores} sortedReportTexts={sortedReportTexts}
                filteredReportTexts={filteredReportTexts} noInterest={noInterest} multipleHighInterest={multipleHighInterest}
                interestAreas={interestAreas} generalStreams={generalStreams} generalQualities={generalQualities} careerOptions={careerOptions} qualitiesArray={qualitiesArray} langId={langId} {...props}/>}>
                {({ blob, loading, error }) => {
                    if(loading){
                        return null
                    } else if (error){
                        console.log(error);
                    }else {
                         setBlobLoading(false);
                         setBlob(blob);
                    }
                    return <div className="questions_page_progress_container">
                        <ThemeProvider theme={progressTheme}>
                            <CircularProgress size={56} color="primary"/>
                        </ThemeProvider>
                    </div>                
                }}
            </BlobProvider>
            
        )
    }else return null
}