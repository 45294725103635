import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import BrowseBlogs from './BrowseBlogs';
import ViewBlog from './ViewBlog';

export default function BlogsPage() {
    return(
        <Switch>
            <Route path="/blogs" exact>
                <BrowseBlogs />
            </Route>
            <Route path="/blogs/read">
                <ViewBlog />
            </Route>
            <Redirect to="/blogs" from="/blogs"/>
        </Switch>
    )
}