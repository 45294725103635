module.exports = {
  //-------LOCAL--------
  //BASE_URL: 'http://127.0.0.1:8000/api/',
  //RAZOR_KEY:'rzp_test_ArGg1vnszTHsIx',
  //-------LOCAL--------
  //----UAT-----
  //BASE_URL: 'http://eval-api.vvsservices.in/api/',
  //RAZOR_KEY:'rzp_test_ArGg1vnszTHsIx',
  //----UAT-----
  //----PROD-----
  BASE_URL: 'https://api.evaltest.com/api/',
  RAZOR_KEY:'rzp_live_neX384jD9FybkK',//prod
  //----PROD-----
  ENDPOINTS: {
    register: 'register',
    login: 'login',
    getUserDetails:'get-user-details',
    getUserPurchases:'get-user-purchases',
    logout:'logout',
    getVoucherCheckData:'get-voucher-check-data',
    createMerchantPurchase:'purchase/create-merchant-purchase',
    createPurchase:'purchase/create-purchase',
    deletePurchase:'purchase/delete-purchase',
    verifyPurchase:'purchase/verify-purchase',
    getTestPurchasedCheck:'get-test-purchased-check',
    getTestCategories:'get-test-categories',
    getTest:'get-test',
    setTest:'set-test',
    getViewReport:'get-view-report',
    getAddContact:'add-contact',
    resetPasswordMailSend:'password/email',
    setResetPassword:'password/set-reset-password'
  },
  ASSETS:{
    noProfilePic:"/app-assets/others/default_user_profile_pic.png"
  },
  STORAGE:{
    ls_token:"ld_token"
  }
};