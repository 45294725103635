import React, {useEffect, useState} from "react";
import './ViewReportPage.css';
import ReportTopImage from '../Assets/report-top-image.png';
import ReportBotImage from '../Assets/report-bottom-image.png';
import EvalLogo from '../Assets/Eval-logo.png';
import CrossButton from '../Assets/TestIcons/cross-button-icon.png';
import GeneralLogo from "../Assets/Homepage/general-eval-icon.png";
import HumanitiesLogo from "../Assets/Homepage/humanities-eval-icon.png";
import EngineeringLogo from "../Assets/Homepage/engineering-eval-icon.png";
import ScienceLogo from "../Assets/Homepage/science-eval-icon.png";
import CommerceLogo from "../Assets/Homepage/commerce-eval-icon.png";
import {Redirect, useHistory, useParams} from "react-router";
// import reportTexts from "./ReportTexts";
import reportTexts from "./ReportTexts";
import gql from 'graphql-tag';
import firebase from '../Firebase/Firebase';
import {useQuery} from "@apollo/client";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {format} from 'date-fns';
import APP from '../services/app-service'
const GLOBAL = require('../services/global-constant');
const axios = require('axios').default;

const RESPONSE_QUERY = gql`
    query ResponseQuery($responseId: Int!, $email: String!) {
        responses(where: {id: {_eq: $responseId}, purchase: {user: {email: {_eq: $email}}}}) {
            scores(order_by: {categoryId: asc}) {
                score
                category {
                    id
                    name
                }
                createdAt
            }
            purchase {
                testId
                user {
                    name
                }
            }
            createdAt
        }
    }`;


// CONSTANT HARDCODED VALUES OF TEST ID'S
const TEST_ID = [
    {name: "General Eval", id: 1, icon: GeneralLogo},
    {name: "Eval for Humanities", id: 2, icon: HumanitiesLogo},
    {name: "Eval for Engineering", id: 3, icon: EngineeringLogo},
    {name: "Eval for Science", id: 4, icon: ScienceLogo},
    {name: "Eval for Commerce", id: 5, icon: CommerceLogo},
];

// COSNSTANT HARDCODED LANGUAGE IDS
const LANGUAGE_ID = [
    {name: "English", id: 1, index: 0}, 
    {name: "हिंदी", id: 3, index: 1}, 
    {name: "मराठी", id: 4, index: 2}, 
    {name: "ગુજરાતી", id: 5, index: 3},
];

const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
});

export default function ViewReportPage2(){
    const [email] = '';//useState(firebase.auth().currentUser.email);

    //Report text states
    const [name, setName] = useState("");
    const [testId, setTestId] = useState(null);
    const [testItem, setTestItem] = useState(null);
    const [date, setDate] = useState(null);
    const [langId, setLangId] = useState(0);

    // Recommendation states
    const [sortedScores, setSortedScores] = useState(null); // Named the way it is because it used to hold scores sorted in descending order, now it only contains scores
    const [filteredScores, setFilteredScores] = useState(null); // Contains scores in descending order from the highest to the 5th highest score
    const [extraScores, setExtraScores] = useState(null); // Scores of the four work style indicators categories, not included in sortedScores or filteredScores
    const [sortedReportTexts, setSortedReportTexts] = useState(null); // Redundant
    const [filteredReportTexts, setFilteredReportTexts] = useState(null); // Recommendations texts for the filteredScores to be shown in the recommendations page
    const [noInterest, setNoInterest] = useState(false); // Boolean that is true when all non work style indicators categories have their scores equal to 0
    // Boolean that is true when more than one category has >=75 score
    const [multipleHighInterest, setMultipleHighInterest] = useState(false);

    // Summary states
    const [interestAreas, setInterestAreas] = useState(""); // Interest areas text that appears at the top of every summary sectionn regaredless of the test
    const [generalQualities, setGeneralQualities] = useState(null); // qualities object to be shown in a table
    const [generalStreams, setGeneralStreams] = useState(null); // streams object to be shown in a table
    const [careerOptions, setCareerOptions] = useState(null); // an array containing all the career options to be shown to the user.
    const [qualitiesArray, setQualitiesArray] = useState(null); // An array containing all the qualities required

    const {responseId} = useParams();

    const [responseData, setResponseData] = useState(null);
    const [responseLoading, setResponseLoading] = useState(null);
    const [responseError, setResponseError] = useState(null);

    // const {data: responseData, loading: responseLoading, error: responseError} = useQuery(RESPONSE_QUERY, {
    //     variables: {
    //         responseId,
    //         email
    //     }
    // });

    useEffect(() => {
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getViewReport, {
            'responseId':responseId
        },APP.getToken())
        .then(function (response) {
            console.log("getViewReport response",response);
            if(!response.data.error){
                setResponseData(response.data);
                console.log("setResponseData",responseData);
            }else{
            }
        })
        .catch(function (error) {
        });
    },[responseId]);

    // Set test text states when data arrives
    useEffect(() => {
        if(responseData && responseData.responses.length) {
            console.log(responseData);
            // SORT THE RESPONSE ACCORDING TO SCORE VALUES IN DESCENDING ORDER
            // RETURN SORTED ARRAY WITH ALL CATEGORIES EXCEPT THE SPECIAL 4 COMMON THROUGHOUT ALL TESTS. SPECIAL 4 CATEGORIES WILL BE STORED IN extraScores ARRAY
            const sortedScores = [];
            responseData.responses[0].scores.filter((scoreItem, index) => {
                return !(scoreItem.category.name === "Client Facing" || scoreItem.category.name === "Collaborative" || scoreItem.category.name === "Communication"
                    || scoreItem.category.name === "Outdoors");
            }).forEach((item, index) => {
                sortedScores.push({
                    category: item.category.name,
                    score: item.score
                });
            });
           
            
            const extraScores = responseData.responses[0].scores.filter((scoreItem, index) => {
                return (scoreItem.category.name === "Client Facing" || scoreItem.category.name === "Collaborative" || scoreItem.category.name === "Communication"
                    ||scoreItem.category.name === "Outdoors");
            }).map((scoreItem, index) => {
                if(scoreItem.category.name === "Client Facing"||scoreItem.category.name === "Collaborative"||scoreItem.category.name === "Communication"||scoreItem.category.name === "Outdoors"){
                    return {
                        category: scoreItem.category.name,
                        score: scoreItem.score
                    }
                } else return null
            });

            // FILTER THE SCORES TO SHOW ONLY THE BEST SCORES
            let filteredScores;
            const threshold = 5;
            if(sortedScores.filter(x => x.score > 75).length >= threshold){
                filteredScores = sortedScores.filter(x => x.score > 75).sort((a, b) => b.score - a.score);
            } else if(sortedScores.filter(x => x.score > 75).length){
                if(sortedScores.filter(x => x.score > 50).length >= threshold){
                    filteredScores = sortedScores.filter(x => x.score > 50).sort((a, b) => b.score - a.score).slice(0, threshold);
                } else filteredScores = sortedScores.filter(x => x.score > 50).sort((a, b) => b.score - a.score);
            } else {
                if(sortedScores.filter(x => x.score > 50).length >= threshold) {
                    filteredScores = sortedScores.filter(x => x.score > 50).sort((a, b) => b.score - a.score).slice(0, threshold);
                } else if(sortedScores.filter(x => x.score > 50).length){
                    if(sortedScores.filter(x => x.score > 25).length >= threshold){
                        filteredScores = sortedScores.filter(x => x.score > 25).sort((a, b) => b.score - a.score).slice(0, threshold);
                    }else filteredScores = sortedScores.filter(x => x.score > 25).sort((a, b) => b.score - a.score);
                } else {
                    if(sortedScores.filter(x => x.score > 25).length >= threshold) {
                        filteredScores = sortedScores.filter(x => x.score > 25).sort((a, b) => b.score - a.score).slice(0, threshold);
                    }else if(sortedScores.filter(x => x.score > 25).length){
                        filteredScores = sortedScores.filter(x => x.score > 25).sort((a, b) => b.score - a.score);
                    } else {
                        filteredScores = sortedScores.filter(x => x.score > 0).sort((a, b) => b.score - a.score).slice(0, threshold);
                    }
                }
            }
                       

            if(sortedScores.filter(x => x.score).length === 0){
                setNoInterest(true);
            }else if(sortedScores.filter(x => x.score > 75).length > 1){
                setMultipleHighInterest(true);
            }

            const testTexts = reportTexts.filter(x => x.testId === responseData.responses[0].purchase.testId)[0];

            // Sorted reportTexts for classifications
            const sortedReportTexts = sortedScores.map((score, index) => {
                return testTexts.texts.categories.filter(y => y.name === score.category)[0];
            });
            

            // Filter reportTexts to have only categories that are in filteredScores array
            const filteredReportTexts = filteredScores.map((score, index) => {
                return testTexts.texts.categories.filter(y => y.name === score.category)[0];
            });

            //SET OVERALL TEST STATES
            setTestId(responseData.responses[0].purchase.testId); // SET TEST ID TO USE FOR SEARCHING OTHER STRINGS
            setName(responseData.responses[0].purchase.user.name); // SET THE USER NAME FROM DATA
            setDate(format(new Date(responseData.responses[0].createdAt), "MMMM d, yyyy")); // SET DATE OF THE TEST
            setTestItem(TEST_ID.filter(x => x.id === responseData.responses[0].purchase.testId)[0]); // TEST ITEM FROM THE CONSTANT ARRAY

            // SET TEST TEXT STATES
            // RECOMMENDATIONS TEXT STATES
            setSortedScores(sortedScores); // ALL SCORES EXCEPT WORK STYLE INDICATORS
            setFilteredScores(filteredScores); // FILTERED SCORES LIMITED TO THE 5TH RANK OF SCORES
            setExtraScores(extraScores); // WORK STYLE INDICATORS SCORES
            setSortedReportTexts(sortedReportTexts); // RECOMMENDATION TEXTS FOR ALL THE CATEGORIES
            setFilteredReportTexts(filteredReportTexts); // RECOMMENDATION TEXTS FOR ONLY THE FILTERED CATEGORIES

            // SUMMARY TEXT STATES
            // Text at the top of the summary, common for all tests
            let interestAreas;
            if(langId === 0){
                interestAreas = "Your interest areas are: ";
            } else if(langId === 1){
                interestAreas = "आपकी रुचि के क्षेत्र हैं: ";
            } else if(langId === 3){
                interestAreas = "તમારા રુચિના ક્ષેત્રો છે: ";
            } else{
                interestAreas = "आपली आवड असणारी क्षेत्रे आहेत: ";
            }

            filteredScores.forEach((scoreItem, index, arr) => {            
                const name = filteredReportTexts.filter((textItem) => textItem.name === scoreItem.category)[0].languages[langId].title;
                if((arr.length - 1) === index){
                    if(langId === 0){
                        interestAreas = interestAreas + " and " + name + "."
                    }else if(langId === 1){
                        interestAreas = interestAreas + " और " + name + "."
                    }else if(langId === 2){
                        interestAreas = interestAreas + " आणि " + name + "."
                    }else if(langId === 3){
                        interestAreas = interestAreas + " અને " + name + "."
                    }
                } else{
                    interestAreas = interestAreas + " " + name + ","
                }
            });

            // Career options array for summary
            let careerOptions = [];
            filteredReportTexts.forEach((textItem, textIndex) => {
                textItem.languages[langId].recommendations.careers.points.forEach((careerOptionItem, optionIndex) => {
                    if(careerOptions.indexOf(careerOptionItem) === -1){
                        careerOptions.push(careerOptionItem);
                    }

                });
            });

            // Qualities array for summary
            let qualitiesArray = [];
            filteredReportTexts.forEach((textItem, textIndex) => {
                qualitiesArray.push({category: textItem.languages[langId].title, qualities: textItem.languages[langId].summary.qualities});
            });


            setQualitiesArray(qualitiesArray);
            setCareerOptions(careerOptions);
            setInterestAreas(interestAreas);
        }else if(responseData && responseData.responses.length === 0){
            history.push("/profile");
        }
    },[responseData, langId]);


    const history = useHistory();

    if(responseData && responseData.responses.length && sortedScores && sortedScores.length &&
        filteredScores && filteredReportTexts && extraScores ){
        return(
            <div className="view_report">
                <div className="view_report_languages">
                    {LANGUAGE_ID.map((langItem, index) => {
                        return <div className={"view_report_language_item" + ((langId === langItem.index)? " selected" : "")}
                                onClick={() => setLangId(langItem.index)}>
                                    <p>{langItem.name}</p>                            
                        </div>
                })}
                </div>
                {/*-------------------------- PAGE 1 TITLE PAGE---------------------------------------*/}
                <div className="view_report_page_1">
                    <svg />
                    <div className="view_report_page_1_image_container">
                        <img className="image_large" src={ReportTopImage} alt="Students studying in a library"/>
                        <img src={EvalLogo} alt="Eval logo" className="logo"/>
                    </div>
                    <div className="view_report_page_1_text_container">
                        <div className="view_report_page_1_textbox">
                            <h2>{testItem.name + " Report"}</h2>
                            <p className="subtitle">NAME</p>
                            <p>{name}</p>
                            <p className="subtitle">DATE</p>
                            <p>{date}</p>
                        </div>
                    </div>
                    <button className="close_button" onClick={() => history.push("/profile")}><img src={CrossButton} alt="cross button"/></button>
                </div>


                {/*--------------------------------- PAGE 2 GENERAL INSTRUCTIONS-------------------------------------*/}
                <div className="view_report_page_2">
                    {(langId === 0)?
                        <div className="view_report_page_2_first_half">                        
                            <p className="title">The Eval Report has four features</p>
                            <div className="title_subtitle_item">
                                <p className="subtitle">Your Career Interest Profile</p>
                                <p className="text">This section has the test taker’s interest score in each category. The score only
                                    represents the interest that a person has in that category and not their aptitude
                                    or cognitive ability to excel in that field.</p>
                            </div>
                            <div className="title_subtitle_item">
                                <p className="subtitle">Recommendations</p>
                                <p className="text">This section includes information about your top interest areas like, the
                                    jobs that you can pursue under them, education qualifications required for those jobs, and qualities you must possess.</p>
                            </div>
                            <div className="title_subtitle_item">
                                <p className="subtitle">Work Style Indicators</p>
                                <p className="text">This section includes information about your preference for four working styles and
                                    ways you can develop skills to be a more effective professional.</p>
                            </div>
                            <div className="title_subtitle_item">
                                <p className="subtitle">Summary</p>
                                <p className="text">This section sums up your top recommended career options, work style preferences and further education options.</p>
                            </div>
                            {/*<div className="title_subtitle_item">*/}
                            {/*    <p className="subtitle">Job interest Classification</p>*/}
                            {/*    <p className="text">This section has personalised recommendations about education, career*/}
                            {/*        prospects, and qualities that will help the test taker build a career in their field*/}
                            {/*        of interest.</p>*/}
                            {/*</div>*/}
                            <p className="note_text">
                                Note - Your Career Interest Profile and Recommendations
                                section will only give you detailed information about categories
                                that you scored high in and showed higher interest.
                            </p>
                        </div>: null
                    }
                    {(langId === 1)?
                        <div className="view_report_page_2_first_half">                        
                            <p className="title">एवल रिपोर्ट में चार विशेषताएं हैं</p>
                            <div className="title_subtitle_item">
                                <p className="subtitle">आपकी कैरियर रुचि प्रोफ़ाइल</p>
                                <p className="text">इस अनुभाग में प्रत्येक श्रेणी में परीक्षार्थी की रुचि है। स्कोर केवल उस ब्याज का प्रतिनिधित्व करता है जो किसी व्यक्ति 
                                    की उस श्रेणी में है और उस क्षेत्र में उत्कृष्टता प्राप्त करने के लिए उनकी योग्यता या संज्ञानात्मक क्षमता नहीं है।</p>
                            </div>
                            <div className="title_subtitle_item">
                                <p className="subtitle">अनुशंसाएँ</p>
                                <p className="text">इस खंड में आपके शीर्ष रुचि वाले क्षेत्रों के बारे में जानकारी शामिल है, जैसे कि आप जिन नौकरियों के तहत अपना 
                                    सकते हैं, उन नौकरियों के लिए आवश्यक शिक्षा योग्यताएं, और गुण जो आपके पास होने चाहिए।</p>
                            </div>
                            <div className="title_subtitle_item">
                                <p className="subtitle">कार्य शैली संकेतक</p>
                                <p className="text">इस अनुभाग में चार कार्यशैली और आपकी पसंद के बारे में जानकारी शामिल है जिससे आप एक अधिक प्रभावी पेशेवर 
                                    बनने के लिए कौशल विकसित कर सकते हैं।</p>
                            </div>
                            <div className="title_subtitle_item">
                                <p className="subtitle">सारांश</p>
                                <p className="text">यह खंड आपके शीर्ष अनुशंसित कैरियर विकल्पों, कार्य शैली वरीयताओं और आगे की शिक्षा के विकल्पों को पूरा करता है।</p>
                            </div>                            
                            <p className="note_text">
                                नोट - आपका कैरियर ब्याज प्रोफ़ाइल और सिफारिशें अनुभाग आपको केवल उन श्रेणियों के बारे में विस्तृत जानकारी देगा, जिन्हें आपने उच्च 
                                स्कोर किया था और उच्च ब्याज दिखाया था।
                            </p>
                        </div> : null
                    }
                    {(langId === 2)?
                        <div className="view_report_page_2_first_half">                        
                            <p className="title">इव्हल अहवालात चार वैशिष्ट्ये आहेत</p>
                            <div className="title_subtitle_item">
                                <p className="subtitle">आपले करियर आवडीचे प्रोफाइल</p>
                                <p className="text">या विभागात चाचणी घेणार्‍याचा प्रत्येक वर्गात रस आहे. स्कोअर केवळ एखाद्या व्यक्तीस त्या श्रेणीमध्ये असलेले स्वारस्य दर्शवितो आणि त्यांची क्षमता किंवा त्या क्षेत्रात उत्तीर्ण होण्याची संज्ञानात्मक क्षमता नव्हे.</p>
                            </div>
                            <div className="title_subtitle_item">
                                <p className="subtitle">शिफारसी</p>
                                <p className="text">या विभागात जसे की आपल्या सर्वोच्च व्याज क्षेत्राविषयी माहिती, जसे की आपण त्यांच्याद्वारे पाठपुरावा करू शकता अशा नोकर्‍या, त्या आवश्यक शैक्षणिक पात्रता आणि आपल्याकडे असणे आवश्यक असलेले गुण.</p>
                            </div>
                            <div className="title_subtitle_item">
                                <p className="subtitle">कार्य शैली निर्देशक</p>
                                <p className="text">या विभागात चार कार्यशैलीसाठी आपल्या पसंतीविषयी आणि अधिक प्रभावी व्यावसायिक होण्यासाठी आपण कौशल्ये विकसित करू शकता याबद्दलची माहिती समाविष्ट आहे.</p>
                            </div>
                            <div className="title_subtitle_item">
                                <p className="subtitle">सारांश</p>
                                <p className="text">हा विभाग आपल्या कारकीर्दीतील मुख्य शिफारसी, कार्यशैली प्राधान्ये आणि पुढील शिक्षण पर्यायांची पूर्तता करतो.</p>
                            </div>                            
                            <p className="note_text">
                            टीप - आपले करियर इंटरेस्ट प्रोफाइल आणि शिफारसी विभाग आपल्याला केवळ अशा श्रेणींविषयी तपशीलवार माहिती देईल ज्यामध्ये आपण उच्चांक बनविला आहे आणि जास्त रस दर्शविला आहे.
                            </p>
                        </div> : null
                    }
                    {(langId === 3)?
                        <div className="view_report_page_2_first_half">                        
                            <p className="title">ઈવલ રિપોર્ટમાં ચાર સુવિધાઓ છે.</p>
                            <div className="title_subtitle_item">
                                <p className="subtitle">તમારી કારકિર્દી રસ રૂપરેખા</p>
                                <p className="text">આ વિભાગમાં દરેક કેટેગરીમાં ટેસ્ટ ટેકરનો રુચિ સ્કોર છે. સ્કોર ફક્ત તે કેટેગરીમાં વ્યક્તિને જે રસ છે તે રજૂ કરે છે 
                                અને તે ક્ષેત્રમાં શ્રેષ્ઠતા મેળવવાની તેમની યોગ્યતા અથવા જ્ઞાનાત્મક ક્ષમતાને નહીં.</p>
                            </div>
                            <div className="title_subtitle_item">
                                <p className="subtitle">ભલામણો </p>
                                <p className="text">આ વિભાગમાં તમારા ટોચના હિત ક્ષેત્રો જેવી માહિતી, તેમના હેઠળ તમે જે નોકરીઓ મેળવી શકો છો, તે નોકરીઓ 
                                માટે જરૂરી શિક્ષણ લાયકાતો અને તમે ધરાવતા હોવા જોઈએ તેવા ગુણો વિશેની માહિતી શામેલ છે.</p>
                            </div>
                            <div className="title_subtitle_item">
                                <p className="subtitle">કાર્ય શૈલી સૂચકાંકો </p>
                                <p className="text">આ વિભાગમાં ચાર કાર્યકારી શૈલીઓ અને વધુ અસરકારક વ્યાવસાયિક બનવા માટે સ્કિલ્સ વિકસાવી શકો છો તે રીતો માટે તમારી પસંદગી વિશેની માહિતી શામેલ છે.</p>
                            </div>
                            <div className="title_subtitle_item">
                                <p className="subtitle">સારાંશ </p>
                                <p className="text">આ વિભાગ તમારા ટોચના ભલામણ કરેલા કરિયર વિકલ્પો, કાર્ય શૈલીની પસંદગીઓ અને વધુ શિક્ષણ વિકલ્પોનો સરવાળો કરે છે.</p>
                            </div>                            
                            <p className="note_text">
                            નોંધ - તમારી કરિયરની રુચિ પ્રોફાઇલ અને ભલામણો વિભાગ તમને ફક્ત તે કેટેગરીઓ વિશે વિગતવાર માહિતી આપશે જેમાં તમે ઉચ્ચ ગુણ પ્રાપ્ત કર્યા છે અને વધુ રસ દાખવ્યો છે.
                            </p>
                        </div> : null
                    }
                    
                    {(langId === 0)?
                        <div className="view_report_page_2_second_half">
                            <p className="title">Before you read your report, <br/>here are some things to remember:</p>
                            <p className="text">1. This test report is based on the answers provided by the test
                                taker and so its recommendations are based on the test taker’s responses.</p>
                            <p className="text">2. The test only serves as a guiding tool for the test taker’s career or
                                educational decisions and does not intend to dictate career or
                                educational decisions.</p>
                            <p className="text">3. While aptitude is important while making career decisions, interest in
                                a subject can serve as a strong motivator to be successful and so our test
                                focuses on interest. It is possible that you do not possess the technical
                                knowledge to pursue a career in the categories where you have the highest
                                score. That is because the test is gauging interest and not technical knowledge
                                for a category.</p>
                            <p className="text">4. The information in this report is confidential and will be stored in a secure
                                place.</p>
                        </div>: null
                    } 
                    {(langId === 1)? 
                        <div className="view_report_page_2_second_half">
                            <p className="title">इससे पहले कि आप अपनी रिपोर्ट पढ़ें, <br/>यहाँ कुछ बातें याद रखने योग्य हैं:</p>
                            <p className="text">१. यह परीक्षण रिपोर्ट परीक्षार्थी द्वारा दिए गए उत्तरों पर आधारित है और इसलिए इसकी सिफारिशें परीक्षार्थी की प्रतिक्रियाओं 
                                पर आधारित हैं।</p>
                            <p className="text">२. परीक्षण केवल परीक्षार्थी के कैरियर या शैक्षिक निर्णयों के लिए एक मार्गदर्शक उपकरण के रूप में कार्य करता है और कैरियर 
                                या शैक्षिक निर्णयों को निर्धारित करने का इरादा नहीं करता है।</p>
                            <p className="text">३. जबकि कैरियर के निर्णय लेते समय योग्यता महत्वपूर्ण है, किसी विषय में रुचि सफल होने के लिए एक मजबूत प्रेरक के 
                                रूप में काम कर सकती है और इसलिए हमारा परीक्षण ब्याज पर केंद्रित है। यह संभव है कि आप उन श्रेणियों में कैरियर बनाने के लिए तकनीकी 
                                ज्ञान के अधिकारी न हों, जहां आपका उच्चतम स्कोर है। ऐसा इसलिए है क्योंकि परीक्षण एक श्रेणी के लिए ब्याज और तकनीकी ज्ञान नहीं है।</p>
                            <p className="text">४. इस रिपोर्ट की जानकारी गोपनीय है और इसे सुरक्षित स्थान पर संग्रहीत किया जाएगा।</p>
                        </div>: null
                    }
                    {(langId === 2)? 
                        <div className="view_report_page_2_second_half">
                            <p className="title">आपण आपला अहवाल वाचण्यापूर्वी येथे लक्षात ठेवण्यासारख्या काही गोष्टी येथे आहेत:</p>
                            <p className="text">१. हा चाचणी अहवाल चाचणी घेणार्‍याने दिलेल्या उत्तरांवर आधारित आहे आणि म्हणूनच या शिफारसी चाचणी घेणार्‍याच्या प्रतिसादावर आधारित आहेत.</p>
                            <p className="text">२. चाचणी केवळ चाचणी घेणार्‍याच्या कारकीर्दीसाठी किंवा शैक्षणिक निर्णयांसाठी मार्गदर्शक साधन म्हणून काम करते आणि करिअर किंवा शैक्षणिक निर्णय ठरविण्याचा त्यांचा हेतू नाही.</p>
                            <p className="text">३ . करिअरचे निर्णय घेताना योग्यता महत्त्वाची असली तरी एखाद्या विषयाची आवड यशस्वी होण्यासाठी यशस्वी प्रेरणा देणारी ठरू शकते आणि म्हणूनच आमची परीक्षा व्याजवर केंद्रित आहे. हे शक्य आहे की आपणास ज्या श्रेणींमध्ये सर्वोच्च स्थान आहे त्यामध्ये करिअर करण्यासाठी आपल्याकडे तांत्रिक ज्ञान नाही. याचे कारण म्हणजे ही चाचणी एखाद्या वर्गासाठी तांत्रिक ज्ञान नाही तर रूची वाढवित आहे.</p>
                            <p className="text">४. या अहवालातील माहिती गोपनीय आहे आणि ती एका सुरक्षित ठिकाणी ठेवली जाईल.</p>
                        </div>: null
                    }
                    {(langId === 3)? 
                        <div className="view_report_page_2_second_half">
                            <p className="title">તમે તમારો અહેવાલ વાંચતા પહેલા, <br/>અહીં કેટલીક બાબતો યાદ રાખવા જેવી છે:</p>
                            <p className="text">1. આ ટેસ્ટ રિપોર્ટ ટેસ્ટ ટેકર દ્વારા આપવામાં આવેલા જવાબો પર આધારિત છે અને તેથી તેની ભલામણો ટેસ્ટ ટેકરના પ્રતિભાવો પર આધારિત છે.</p>
                            <p className="text">2. પરીક્ષણ માત્ર પરીક્ષણ લેનારની કારકિર્દી અથવા શૈક્ષણિક નિર્ણયો માટે માર્ગદર્શક સાધન તરીકે કામ કરે છે અને તેનો ઇરાદો કરિયર અથવા શૈક્ષણિક નિર્ણયો નક્કી કરવાનો નથી.</p>
                            <p className="text">3. કરિયરના નિર્ણયો કરતી વખતે યોગ્યતા મહત્વપૂર્ણ છે, ત્યારે કોઈ વિષયમાં રસ સફળ બનવા માટે એક મજબૂત પ્રેરક તરીકે કામ કરી શકે છે અને તેથી અમારી કસોટી રસ પર ધ્યાન કેન્દ્રિત કરે છે. શક્ય છે કે તમારી પાસે જે કેટેગરીમાં સર્વોચ્ચ સ્કોર છે ત્યાં કરિયર બનાવવા માટે તમારી પાસે તકનીકી જ્ઞાન ન હોય. તે એટલા માટે છે કારણ કે પરીક્ષણ કોઈ કેટેગરી માટે તકનીકી જ્ઞાન નહીં પરંતુ રસને આગળ લઈ રહ્યું છે.</p>
                            <p className="text">4. આ અહેવાલમાં માહિતી ગુપ્ત છે અને તેને સુરક્ષિત જગ્યાએ સંગ્રહિત કરવામાં આવશે.</p>
                        </div>: null
                    }                                       
                </div>
                {/*------------------------------- PAGE 3 INTEREST PROFILE------------------------------------*/}
                <div className="view_report_page_3">
                    <div className="top_row">
                        <h2>Your Career Interest Profile</h2>
                        <p>Your interests indicate your preference for work in different Career Categories. Interests can
                            differ over time based on exposure to information, experiences and the people around us. The
                            following graphs represent your interest levels in different categories, based on the answers you provided.</p>
                    </div>
                    <div className="graphs">
                        <div className="column">
                            {sortedScores.map((scoreItem, index, arr) => {
                                const reportText = sortedReportTexts.filter((textItem, textIndex) => {
                                    return textItem.name === scoreItem.category
                                });
                                if(index < arr.length/2){
                                    let color;
                                    if(scoreItem.score <= 25){
                                        color = "#F2B5B5";
                                    }else if (scoreItem.score <= 50){
                                        color = "#F2E6B5";
                                    }else if (scoreItem.score <= 75){
                                        color = "#B5D0F2";
                                    }else color = "#B5F2DB";
                                    return(
                                        <div className="graph_item">
                                            <p className="graph_text">{reportText[0].languages[langId].title}</p>
                                            <div className="graph_bar">
                                                <svg width={scoreItem.score + "%"} style={{backgroundColor: color}}/>
                                                <p className="graph_number">{scoreItem.score}</p>
                                            </div>
                                        </div>
                                    )
                                }else return null;

                            })}
                        </div>
                        <div className="column">
                            {sortedScores.map((scoreItem, index, arr) => {
                                const reportText = sortedReportTexts.filter((textItem, textIndex) => {
                                    return textItem.name === scoreItem.category
                                });
                                if(index < arr.length/2){
                                    return null
                                }else{
                                    let color;
                                    if(scoreItem.score <= 25){
                                        color = "#F2B5B5";
                                    }else if (scoreItem.score <= 50){
                                        color = "#F2E6B5";
                                    }else if (scoreItem.score <= 75){
                                        color = "#B5D0F2";
                                    }else color = "#B5F2DB";
                                    return(
                                        <div className="graph_item">
                                            <p className="graph_text">{reportText[0].languages[langId].title}</p>
                                            <div className="graph_bar">
                                                <svg width={scoreItem.score + "%"} style={{backgroundColor: color}}/>
                                                <p className="graph_number">{scoreItem.score}</p>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    {(langId === 0)? 
                        <div className="graph_interpretation">
                            <p className="title">Graph Interpretation</p>
                            <div className="graph_interpretation_item">
                                <p className="text">High Interest (Above 75%)</p>
                                <div className="color_box green" />
                            </div>
                            <div className="graph_interpretation_item">
                                <p className="text">Moderate Interest (51%-75%)</p>
                                <div className="color_box blue" />
                            </div>
                            <div className="graph_interpretation_item">
                                <p className="text">Slight Interest (26%-49%)</p>
                                <div className="color_box yellow" />
                            </div>
                            <div className="graph_interpretation_item">
                                <p className="text">Low Interest (25% and below)</p>
                                <div className="color_box red" />
                            </div>
                        </div> : null
                    }
                    {(langId === 1)? 
                        <div className="graph_interpretation">
                            <p className="title">ग्राफ व्याख्या</p>
                            <div className="graph_interpretation_item">
                                <p className="text">उच्च ब्याज (७५% से ऊपर)</p>
                                <div className="color_box green" />
                            </div>
                            <div className="graph_interpretation_item">
                                <p className="text">मध्यम ब्याज (५१% -७५%)</p>
                                <div className="color_box blue" />
                            </div>
                            <div className="graph_interpretation_item">
                                <p className="text">थोड़ा ब्याज (२६% -५०%)</p>
                                <div className="color_box yellow" />
                            </div>
                            <div className="graph_interpretation_item">
                                <p className="text">कम ब्याज (२५% और नीचे)</p>
                                <div className="color_box red" />
                            </div>
                        </div> : null
                    }
                    {(langId === 2)? 
                        <div className="graph_interpretation">
                            <p className="title">आलेख व्याख्या</p>
                            <div className="graph_interpretation_item">
                                <p className="text">उच्च व्याज (७५% पेक्षा जास्त)</p>
                                <div className="color_box green" />
                            </div>
                            <div className="graph_interpretation_item">
                                <p className="text">मध्यम व्याज (५१% -७५%)</p>
                                <div className="color_box blue" />
                            </div>
                            <div className="graph_interpretation_item">
                                <p className="text">जरा व्याज (२६% -५०%)</p>
                                <div className="color_box yellow" />
                            </div>
                            <div className="graph_interpretation_item">
                                <p className="text">कमी व्याज (२५ % आणि खाली)</p>
                                <div className="color_box red" />
                            </div>
                        </div> : null
                    }
                    {(langId === 3)? 
                        <div className="graph_interpretation">
                            <p className="title">ગ્રાફ અર્થઘટન</p>
                            <div className="graph_interpretation_item">
                                <p className="text">ઉચ્ચ રુચિ છે (75% થી ઉપર)</p>
                                <div className="color_box green" />
                            </div>
                            <div className="graph_interpretation_item">
                                <p className="text">મધ્યમ રુચિ છે (51%-75%) </p>
                                <div className="color_box blue" />
                            </div>
                            <div className="graph_interpretation_item">
                                <p className="text">થોડી રુચિ છે (26%-50%) </p>
                                <div className="color_box yellow" />
                            </div>
                            <div className="graph_interpretation_item">
                                <p className="text">ઓછી રુચિ છે (25% અને નીચે)</p>
                                <div className="color_box red" />
                            </div>
                        </div> : null                        
                    }                                   
                </div>


                {/*------------------------- PAGE 4 RECOMMENDATIONS----------------------------*/}
                <div className="view_report_page_4 orange">
                    <div className="top_row orange">
                        <h2>Recommendations</h2>
                        <p>Career Categories with the highest interest scores are further explained in this section. You are given detailed information about <i>What to Study, Career</i> options you can pursue, and the <i>Qualities</i> you must have or need to develop to build a successful career in your interest areas.</p>
                    </div>
                    {(noInterest && (langId === 0))? <p className="paragraph" style={{marginTop: "4rem", marginBottom: "15rem"}}>
                        Your results show that you do not have interest in any of the career categories identified by the Eval Test. Please feel free to contact Eval on uniqueausmum@gmail.com for further information or to avail career counseling.
                    </p> : null}
                    {(noInterest && (langId === 1))? <p className="paragraph" style={{marginTop: "4rem", marginBottom: "15rem"}}>आपके परिणाम दिखाते हैं कि आपके पास एवल टेस्ट द्वारा पहचाने गए किसी भी कैरियर श्रेणी में कोई दिलचस्पी नहीं है। अधिक जानकारी के लिए या करियर काउंसलिंग का लाभ उठाने के लिए कृपया uniqueausmum@gmail.com पर  संपर्क करें।</p> : null}
                    {(noInterest && (langId === 2))? <p className="paragraph" style={{marginTop: "4rem", marginBottom: "15rem"}}>आपले निकाल दर्शवतात की आपल्याला एव्हल टेस्टद्वारे ओळखल्या जाणार्‍या कोणत्याही करिअर प्रकारात रस नाही. अधिक माहितीसाठी किंवा करिअर समुपदेशनासाठी uniqueausmum@gmail.com मोकळ्या मनाने संपर्क साधा.</p> : null}
                    {(noInterest && (langId === 3))? <p className="paragraph" style={{marginTop: "4rem", marginBottom: "15rem"}}>તમારા પરિણામો દર્શાવે છે કે તમને ઈવલ ટેસ્ટ દ્વારા ઓળખવામાં આવેલા કારકિર્દીની કોઈ પણ કેટેગરીમાં રસ નથી. કૃપા કરીને વધુ માહિતી માટે અથવા કારકિર્દીની સલાહ uniqueausmum@gmail.com પર ઈવલનો સંપર્ક કરવા માટે મુક્ત અનુભવો.</p> : null}
                    
                    {!noInterest?[
                            ((filteredScores[0].score >= 50 && langId === 0)? <p className="large_text">Based on your Interest Scores, Eval has identified the following as your Top Interest Areas: </p> : null),
                            ((filteredScores[0].score >= 50 && langId === 1)? <p className="large_text">आपके ब्याज स्कोर के आधार पर, इव्हल ने आपके शीर्ष रूचि क्षेत्रों के रूप में निम्नलिखित की पहचान की है: </p> : null),
                            ((filteredScores[0].score >= 50 && langId === 2)? <p className="large_text">आपल्या आवडीच्या स्कोअरच्या आधारावर, इव्हलने आपले शीर्ष व्याज क्षेत्र म्हणून खालील गोष्टी ओळखल्या आहेत: </p> : null),
                            ((filteredScores[0].score >= 50 && langId === 3)? <p className="large_text">તમારા રુચિના સ્કોરના આધારે, ઈવલે નીચેનાને તમારા ટોચના રુચિવાળા ક્ષેત્રો તરીકે ઓળખ આપી છેઃ </p> : null),
                        
                            ((filteredScores[0].score < 50 && langId === 0)?<p className="large_text">Eval has identified the following choices based on your answers to the questions asked. These choices are not of high or moderate interest. Get in 
                                touch with our counsellors for further assistance.</p> : null),
                            ((filteredScores[0].score < 50 && langId === 1)?<p className="large_text">इव्हाल्व ने पूछे गए प्रश्नों के आपके उत्तरों के आधार पर निम्नलिखित विकल्पों की पहचान की है। ये विकल्प उच्च या मध्यम ब्याज के नहीं हैं। आगे की सहायता के लिए हमारे काउंसलर्स से संपर्क करें।</p> : null),
                            ((filteredScores[0].score < 50 && langId === 2)?<p className="large_text">विचारलेल्या प्रश्नांच्या उत्तरांच्या आधारे एवलने खालील निवडी ओळखल्या आहेत. या निवडी उच्च किंवा मध्यम स्वारस्याच्या नाहीत. पुढील सहाय्यासाठी आमच्या सल्लागारांशी संपर्क साधा.</p> : null),
                            ((filteredScores[0].score < 50 && langId === 3)?<p className="large_text">ઈવલે પૂછાયેલ પ્રશ્નોના તમારા જવાબોને આધારે નીચેની પસંદગીઓ ઓળખી છે. આ પસંદગીઓ ઉચ્ચ અથવા મધ્યમ હિતની નથી. વધુ સહાય માટે અમારા સલાહકારો સાથે સંપર્ક કરો.</p> : null),
                            filteredReportTexts.map((data, index) => {
                                return(
                                    <div className="view_report_page_recommendation_item">
                                        <h2>{(index + 1) + ". " + data.languages[langId].title + " (" + filteredScores[index].score + "%)"}</h2>
                                        <div className="view_report_page_recommendation_subitem">
                                            {(langId === 0)? <p className="subtitle">What to Study</p> : null}
                                            {(langId === 1)? <p className="subtitle">क्या अध्ययन करें</p> : null}
                                            {(langId === 2)? <p className="subtitle">काय अभ्यास करा</p> : null}
                                            {(langId === 3)? <p className="subtitle">શું ભણવું જોઈએ</p> : null}                                            
                                            {data.languages[langId].recommendations.whatToStudy.main.map((item, studyMainIndex) => {
                                                return <p className="paragraph" key={"whattostudymain" + index + "_" + studyMainIndex}>{item}</p>
                                            })}
                                            {data.languages[langId].recommendations.whatToStudy.points &&
                                            data.languages[langId].recommendations.whatToStudy.points.length &&
                                            data.languages[langId].recommendations.whatToStudy.points.map((item, studyPointIndex) => {
                                                return <p className="paragraph no-margin" key={"whattostudypoints" + index + "_" + studyPointIndex}>{item}</p>
                                            })}
                                        </div>
                                        <div className="view_report_page_recommendation_subitem">
                                            {(langId === 1)? <p className="subtitle">करियर</p> : null}
                                            {(langId === 2)? <p className="subtitle">करियर</p> : null}
                                            {(langId === 3)? <p className="subtitle">કરિયર્સ</p> : null}
                                            {(langId === 0)? <p className="subtitle">Careers</p> : null}
                                            {data.languages[langId].recommendations.careers.main.map(careerData => {
                                                return(
                                                    <p className="paragraph">{careerData}</p>
                                                )
                                            })}
                                            {data.languages[langId].recommendations.careers.points.map(careerData => {
                                                return(
                                                    <p className="paragraph no-margin">{careerData}</p>
                                                )
                                            })}
                                        </div>
                                        <div className="view_report_page_recommendation_subitem">
                                            {(langId === 1)? <p className="subtitle">गुण</p> : null}
                                            {(langId === 2)? <p className="subtitle">गुण</p> : null}
                                            {(langId === 3)? <p className="subtitle">ગુણવત્તાઓ</p> : null}
                                            {(langId === 0)? <p className="subtitle">Qualities</p> : null}
                                            <p className="paragraph">{data.languages[langId].recommendations.qualities}</p>
                                        </div>
                                    </div>
                                )
                            }),
                        ] : null
                    }
                </div>

                {/*--------------------------- WORK STYLE INDICATORS-------------------------------------*/}
                <div className="view_report_page_4">
                    <div className="top_row">
                        <h2>Work Style Indicators</h2>
                        <p>Your interest in the following <i>Work Style Indicators</i> shows your preference for certain working conditions and environments. They can help you narrow down what kind of job you would like to pursue within your interest areas.</p>
                    </div>
                    {(langId === 0)? <p className="large_text">Based on your responses we have evaluated your interest in the following Work Style Indicators: </p> : null}
                    {(langId === 1)? <p className="large_text">आपकी प्रतिक्रियाओं के आधार पर हमने निम्नलिखित कार्य शैली संकेतकों में आपकी रुचि का मूल्यांकन किया है:</p> : null}
                    {(langId === 2)? <p className="large_text">आपल्या प्रतिसादाच्या आधारे आम्ही खालील कार्यशैली निर्देशकांमधील आपल्या स्वारस्याचे मूल्यांकन केले आहे:</p> : null}
                    {(langId === 3)? <p className="large_text">તમારા પ્રતિભાવોના આધારે અમે નીચેની કાર્ય શૈલીના સૂચકાંકોમાં તમારી રુચિનું મૂલ્યાંકન કર્યું છે: </p> : null}                    
                    <div className="view_reports_page_4_graphs">
                        {extraScores.map((scoreItem, index, arr) => {
                            const reportText = reportTexts.filter((text, index) => text.name === "Extra")[0].texts.categories.filter((textItem, textIndex) => {
                                return textItem.name === scoreItem.category
                            })[0];
                            return <div className="view_reports_page_4_graph_item" key={"extragraph" + index}>
                                <p className="view_reports_page_4_graph_text">{reportText.languages[langId].title}</p>
                                <div className="view_reports_page_4_graph_bar">
                                    <svg width={scoreItem.score + "%"} />
                                    <p className="view_reports_page_4_graph_number">{scoreItem.score}</p>
                                </div>
                            </div>
                        })}
                    </div>
                    {
                        reportTexts.filter((text, index) => text.name === "Extra")[0].texts.categories.map((item, index) => {
                            return (
                                <div className="view_report_page_recommendation_item" key={"extrarecommendation" + index}>
                                    <h2>{(index+1) + ". " + item.languages[langId].title + " (" + extraScores[index].score + "%)"}</h2>
                                    <p className="paragraph">{item.languages[langId].main}</p>
                                    <div className="view_report_page_recommendation_subitem">
                                        {(langId === 0)? <p className="subtitle">Tips to improve</p> : null}
                                        {(langId === 1)? <p className="subtitle">सुधारने के टिप्स</p> : null}
                                        {(langId === 2)? <p className="subtitle">सुधारण्यासाठी टिपा</p> : null}
                                        {(langId === 3)? <p className="subtitle">સુધારવા માટેની ટિપ્સ</p> : null}                                            
                                        <p className="paragraph">{item.languages[langId].tips}</p>
                                    </div>
                                </div>
                            )
                    })}
                </div>


                {/*--------------------------------------WORK STYLE INDICATORS --------------------------------*/}


                {/*--------------------------------------- SUMMARY ----------------------------------------------*/}
                {
                    noInterest?
                        null : <div className="view_report_page_5">
                            <div className="top_row">
                                <h2>Summary</h2>
                            </div>
                            <p className="large_text">{interestAreas}</p>
                            {(langId === 0)? <p className="note_text">Please Note: You would be able to pursue a satisfying career in any of these
                            categories or a combination of these categories. The report has identified them as an interest area for you.</p> : null}
                            {(langId === 1)? <p className="note_text">कृपया ध्यान दें: आप इनमें से किसी भी श्रेणी या इन श्रेणियों के संयोजन में एक संतोषजनक कैरियर बनाने में सक्षम होंगे। रिपोर्ट ने उन्हें आपके लिए एक रुचि क्षेत्र के रूप में पहचाना है।</p> : null}
                            {(langId === 2)? <p className="note_text">कृपया लक्षात ठेवाः आपण यापैकी कोणत्याही श्रेणीमध्ये समाधानकारक करिअर किंवा या श्रेणींच्या संयोजनात सक्षम असाल. अहवालात ते आपल्यासाठी स्वारस्य असलेले क्षेत्र म्हणून ओळखले गेले आहे.</p> : null}
                            {(langId === 3)? <p className="note_text">કૃપયા નોંધો: તમે આમાંથી કોઈ પણ કેટેગરીમાં સંતોષકારક કારકિર્દી અથવા આ શ્રેણીઓનું સંયોજન કરી શકશો. અહેવાલમાં તેમને તમારા માટે હિત ક્ષેત્ર તરીકે ઓળખવામાં આવ્યા છે.</p> : null}
                            
                            <div className="view_report_page_summary_item">
                                {(qualitiesArray&&qualitiesArray.length)?
                                    <div className="view_report_page_summary_subitem">
                                        {(langId === 0)? <p className="subtitle">Your profile further speaks about you having the following qualities</p> : null}
                                        {(langId === 1)? <p className="subtitle">आपकी प्रोफ़ाइल आगे बताती है कि आपके पास निम्नलिखित गुण हैं:</p> : null}
                                        {(langId === 2)? <p className="subtitle">आपले प्रोफाइल पुढील गुणांबद्दल आपल्याबद्दल सांगते:</p> : null}
                                        {(langId === 3)? <p className="subtitle">તમારી પ્રોફાઇલ તમારામાં નીચેના ગુણો હોવા વિશે આગળ બોલે છે:</p> : null}                                        
                                        <div className="view_report_page_summary_subitem_table">
                                            {(langId === 0)? <div className="table_title_row">
                                                <div className="label">Category</div>
                                                <div className="value">Qualities</div>
                                            </div> : null}
                                            {(langId === 1)? <div className="table_title_row">
                                                <div className="label">श्रेणी</div>
                                                <div className="value">गुण</div>
                                            </div> : null}
                                            {(langId === 2)? <div className="table_title_row">
                                                <div className="label">श्रेणी</div>
                                                <div className="value">गुण</div>
                                            </div> : null}
                                            {(langId === 3)? <div className="table_title_row">
                                                <div className="label">કેટેગરી </div>
                                                <div className="value">ગુણવત્તાઓ</div>
                                            </div> : null}
                                            {qualitiesArray.map((qualitiesItem, qualitiesIndex) => {
                                                return <div className="table_item_row">
                                                    <div className="label">{qualitiesItem.category}</div>
                                                    <div className="value">{qualitiesItem.qualities}</div>
                                                </div>
                                            })}
                                        </div>
                                    </div>:null
                                }
                                {(testItem.id === 1)?
                                    <div className="view_report_page_summary_subitem">
                                        {(langId === 0)? <p className="subtitle">Suggested streams and subjects</p> : null}
                                        {(langId === 1)? <p className="subtitle">सुझाई गई धाराएँ और विषय</p> : null}
                                        {(langId === 2)? <p className="subtitle">सुचविलेले प्रवाह आणि विषय</p> : null}
                                        {(langId === 3)? <p className="subtitle">સૂચવેલ સ્ટ્રીમ્સ અને વિષયો </p> : null}
                                        
                                        <div className="view_report_page_summary_subitem_table">
                                            {(langId === 0)? <div className="table_title_row">
                                                <div className="label">Category</div>
                                                <div className="value">What to Study</div>
                                            </div> : null}
                                            {(langId === 1)? <div className="table_title_row">
                                                <div className="label">श्रेणी</div>
                                                <div className="value">क्या अध्ययन करें</div>
                                            </div> : null}
                                            {(langId === 2)? <div className="table_title_row">
                                                <div className="label">श्रेणी</div>
                                                <div className="value">काय अभ्यास करा</div>
                                            </div> : null}
                                            {(langId === 3)? <div className="table_title_row">
                                                <div className="label">કેટેગરી</div>
                                                <div className="value">શું ભણવું જોઈએ</div>
                                            </div> : null}
                                            {filteredReportTexts.map((reportText, reportTextIndex) => {
                                                return <div className="table_item_row">
                                                    <div className="label">{reportText.languages[langId].title}</div>
                                                    <div className="value">{reportText.languages[langId].recommendations.stream}</div>
                                                </div>
                                            })}
                                        </div>
                                        {multipleHighInterest && (langId === 0)?
                                            <p className="paragraph">Since you have high interest in multiple categories. You
                                                might be a fit for more than one stream in your higher school years. To
                                                help you finally select the subjects at school you must read about the
                                                job options in each category to see which seems most exciting to you. After
                                                you narrow down on the jobs of your interest, one of the high-interest categories
                                                can be finalised, hence showing you the subjects you need to focus on.</p> : null
                                        }
                                        {multipleHighInterest && (langId === 1)?
                                            <p className="paragraph">क्योंकि आप कई श्रेणियों में उच्च रुचि रखते हैं। आप अपने उच्च विद्यालय के वर्षों में एक से अधिक स्ट्रीम के लिए फिट हो सकते हैं। अंत में आपको स्कूल में उन विषयों का चयन करने में मदद करने के लिए आपको प्रत्येक श्रेणी में नौकरी के विकल्पों के बारे में पढ़ना होगा, जो आपको सबसे रोमांचक लगते हैं। जब आप अपनी रुचि की नौकरियों पर संकीर्ण हो जाते हैं, तो उच्च-ब्याज श्रेणियों में से एक को अंतिम रूप दिया जा सकता है, इसलिए आपको उन विषयों को दिखाना होगा जिन पर आपको ध्यान केंद्रित करने की आवश्यकता है।</p> : null
                                        }
                                        {multipleHighInterest && (langId === 2)?
                                            <p className="paragraph">आपल्याला एकाधिक श्रेणींमध्ये जास्त रस असल्याने. आपल्या उच्च शालेय वर्षांमध्ये आपण एकापेक्षा जास्त प्रवाहासाठी तंदुरुस्त आहात. शेवटी आपल्याला शाळेत विषय निवडण्यास मदत करण्यासाठी आपल्यासाठी कोणत्या श्रेणीतील नोकरीच्या पर्यायांबद्दल आपण वाचले पाहिजे जे आपल्यासाठी सर्वात रोमांचक वाटेल. आपण आपल्या आवडीच्या नोकर्‍या कमी केल्यावर, उच्च-व्याज श्रेणीपैकी एक निश्चित केली जाऊ शकते, म्हणूनच आपल्याला ज्या विषयांवर लक्ष केंद्रित करण्याची आवश्यकता आहे ते दर्शविते.</p> : null
                                        }
                                        {multipleHighInterest && (langId === 3)?
                                            <p className="paragraph">તમને બહુવિધ કેટેગરીમાં વધુ રસ હોવાથી તમે તમારી ઉચ્ચ શાળાના વર્ષોમાં એક થી વધુ પ્રવાહ માટે યોગ્ય હોઈ શકો છો. આખરે તમને શાળામાં વિષયો પસંદ કરવામાં મદદ કરવા માટે તમારે દરેક કેટેગરીમાં નોકરીના વિકલ્પો વિશે વાંચવું જોઈએ જે તમને સૌથી રોમાંચક લાગે છે. તમે તમારા હિતની નોકરીઓ પર સંકુચિત થયા પછી, ઉચ્ચ હિતની એક કેટેગરીને અંતિમ સ્વરૂપ આપી શકાય છે, તેથી તમારે જે વિષયો પર ધ્યાન કેન્દ્રિત કરવાની જરૂર છે તે તમને બતાવી શકાય છે.</p> : null
                                        }
                                    </div>:null
                                }
                                {(careerOptions&& careerOptions.length)?
                                    <div className="view_report_page_summary_subitem">
                                        {(langId === 0)? <p className="subtitle">Career Options related to your interest:</p> : null}
                                        {(langId === 1)? <p className="subtitle">आपकी रुचि से संबंधित कैरियर विकल्प:</p> : null}
                                        {(langId === 2)? <p className="subtitle">आपल्या स्वारस्याशी संबंधित करियर पर्यायः</p> : null}
                                        {(langId === 3)? <p className="subtitle">તમારા હિતથી સંબંધિત કરિયર વિકલ્પો:</p> : null}
                                        <div className="career_options">
                                            {careerOptions.map((option, index) => <p className="paragraph no-margin" key={"careerOption" + index}>{option}</p>)}
                                        </div>
                                        {/*<p className="paragraph">{data.recommendations.whatToStudy}</p>*/}
                                    </div> : null
                                }

                                {(langId === 0)? <p className="paragraph"><b>It is important that you read more about the job options provided in
                                    the career categories above. You will get a clearer understanding of where you see
                                    yourself working and what appeals to you most.</b></p> : null }
                                {(langId === 1)? <p className="paragraph"><b>यह महत्वपूर्ण है कि आप ऊपर दिए गए कैरियर श्रेणियों में प्रदान किए गए नौकरी के विकल्पों के बारे में अधिक पढ़ें। आपको इस बात की स्पष्ट समझ हो जाएगी कि आप अपने आप को कहां काम करते देखते हैं और आप सबसे क्या अपील करते हैं।</b></p> : null }
                                {(langId === 2)? <p className="paragraph"><b>आपण वरील करिअरच्या श्रेणींमध्ये प्रदान केलेल्या नोकरीच्या पर्यायांबद्दल अधिक वाचणे महत्वाचे आहे. आपण स्वत: कोठे काम करत असल्याचे आणि आपल्याला सर्वात जास्त कशाचे आवाहन करतात याचा एक स्पष्ट समज मिळेल.</b></p> : null }
                                {(langId === 3)? <p className="paragraph"><b>તે મહત્વપૂર્ણ છે કે તમે ઉપરની કરિયર કેટેગરીમાં પૂરા પાડવામાં આવેલા નોકરીના વિકલ્પો વિશે વધુ વાંચો. તમે તમારી જાતને ક્યાં કામ કરતા જુઓ છો અને તમને સૌથી વધુ શું અપીલ કરે છે તેની સ્પષ્ટ સમજ તમને મળશે.</b></p> : null }
                                
                                {(langId === 0)? <p className="paragraph"><b>Your work style indicators (Collaborative, Communication, Outdoor and
                                    Client Facing) can also help you narrow down your decision further. It's important to
                                    understand if the career category chosen according to your interest also fits in with your style indicator.</b></p> : null}
                                {(langId === 1)? <p className="paragraph"><b>आपकी कार्यशैली संकेतक (सहयोगात्मक, संचार, आउटडोर और क्लाइंट फ़ेसिंग) भी आपके निर्णय को आगे बढ़ाने में आपकी मदद कर सकते हैं। यह समझना महत्वपूर्ण है कि आपकी रुचि के अनुसार चुना गया कैरियर वर्ग आपकी शैली संकेतक के साथ भी फिट बैठता है या नहीं।</b></p> : null}
                                {(langId === 2)? <p className="paragraph"><b>आपले कार्य शैली निर्देशक (सहयोगी, संप्रेषण, मैदानी आणि क्लायंट फेसिंग) देखील आपला निर्णय कमी करण्यात मदत करतात. आपल्या आवडीनुसार निवडलेली करिअर श्रेणी आपल्या शैली निर्देशकासह देखील बसत असेल तर हे समजणे महत्वाचे आहे.</b></p> : null}
                                {(langId === 3)? <p className="paragraph"><b>તમારી કાર્ય શૈલીના સૂચકાંકો (સહયોગી, સંદેશાવ્યવહાર, આઉટડોર અને ક્લાયન્ટ ફેસિંગ) પણ તમને તમારા નિર્ણયને વધુ સંકુચિત કરવામાં મદદ કરી શકે છે. તમારા રુચિ અનુસાર પસંદ કરેલી કરિયરની કેટેગરી પણ તમારી શૈલી સૂચક સાથે બંધ બેસે છે કે નહીં તે સમજવું મહત્વપૂર્ણ છે.</b></p> : null}
                                
                                {(langId === 0)? <p className="paragraph"><b>Thank you for Eval….ing and seeking clarity for your career interests.
                                    We wish you all the best for your future endeavours.</b></p> : null}
                                {(langId === 1)? <p className="paragraph"><b>ईवल के लिए धन्यवाद…। अपने कैरियर के हितों के लिए स्पष्टता की मांग करें। हम आपके भविष्य के प्रयासों के लिए आपको शुभकामनाएं देते हैं।</b></p> : null}
                                {(langId === 2)? <p className="paragraph"><b>एव्हइंग केल्याबद्दल धन्यवाद आणि आपल्या करियरच्या आवडींसाठी स्पष्टीकरण शोधत आहात. आपल्या भावी प्रयत्नांसाठी आम्ही सर्व शुभेच्छा देतो.</b></p> : null}
                                {(langId === 3)? <p className="paragraph"><b>તમારા કરિયર હિતોનું ઈવલિંગ અને સ્પષ્ટતા મેળવવા માટે આભાર. અમે તમને તમારા ભવિષ્યના પ્રયાસો માટે શુભેચ્છા પાઠવીએ છીએ.</b></p> : null}
                                
                                {(langId === 0)? <p className="paragraph" style={{textAlign: "center"}}><b>“Remember Whatever Path You choose, The Path also chooses you”</b></p> : null}
                                {(langId === 1)? <p className="paragraph" style={{textAlign: "center"}}><b>“याद रखें कि आप जो भी रास्ता चुनते हैं, वह रास्ता भी आपको चुनता है”</b></p> : null}                                
                                {(langId === 2)? <p className="paragraph" style={{textAlign: "center"}}><b>“तुम्ही कोणता मार्ग निवडता ते लक्षात ठेवा, पथ तुम्हाला निवडतो”</b></p> : null}                                
                                {(langId === 3)? <p className="paragraph" style={{textAlign: "center"}}><b>“યાદ રાખો ગમે તે માર્ગ તમે પસંદ કરો, માર્ગ પણ તમને પસંદ કરે છે!”</b></p> : null}                                
                                    {/*<div className="view_report_page_recommendation_subitem">*/}
                                    {/*    <p className="subtitle">Careers</p>*/}
                                    {/*    {data.recommendations.careers.main.map(careerData => {*/}
                                    {/*        return(*/}
                                    {/*            <p className="paragraph">{careerData}</p>*/}
                                    {/*        )*/}
                                    {/*    })}*/}
                                    {/*    {data.recommendations.careers.points.map(careerData => {*/}
                                    {/*        return(*/}
                                    {/*            <p className="paragraph no-margin">{careerData}</p>*/}
                                    {/*        )*/}
                                    {/*    })}*/}
                                    {/*</div>*/}
                                    {/*<div className="view_report_page_recommendation_subitem">*/}
                                    {/*    <p className="subtitle">Qualities</p>*/}
                                    {/*    <p className="paragraph">{data.recommendations.qualities}</p>*/}
                                    {/*</div>*/}
                                </div>
                        </div>


                }

                {/*XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX SECTION REMOVED XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/}
                {/*-------------------------PAGE 5 JOB INTEREST CLASSIFICATION ------------------------------------------------------*/}
                {/*<div className="view_report_page_5">*/}
                {/*    <div className="top_row">*/}
                {/*        <h2>Job Interest Classification</h2>*/}
                {/*        <p>General Eval classifies career interests into 18 distinct categories. Each category represents a career field and the*/}
                {/*            questions on the Eval test aim to gauge interest in these fields. Below is a description of these categories, examples*/}
                {/*            of top jobs and the educational background required for those jobs. As you read through this section of the report,*/}
                {/*            note and pay attention to any categories that seem interesting to you, but have not showed up on your Interest Profile.</p>*/}
                {/*    </div>*/}
                {/*    <p className="large_text">Based on your responses we have evaluated the following categories placeholder</p>*/}
                {/*    {sortedReportTexts.map((data, index) => {*/}
                {/*        return(*/}
                {/*            <div className="view_report_page_5_classification_item">*/}
                {/*                <h2>{(index+1) + ". " + data.name}</h2>*/}
                {/*                <div className="view_report_page_5_classification_item_content">*/}
                {/*                    <div className="view_report_page_5_classification_item_content_subitem">*/}
                {/*                        <p className="title">Description</p>*/}
                {/*                        {data.classification.description.texts.map(description => {*/}
                {/*                            return(*/}
                {/*                                <p className="text">{"● " + description}</p>*/}
                {/*                            )*/}
                {/*                        })}*/}
                {/*                    </div>*/}
                {/*                    <div className="view_report_page_5_classification_item_content_subitem">*/}
                {/*                        <p className="title">Career Options</p>*/}
                {/*                        {data.classification.careerOptions.texts.map(careerOption => {*/}
                {/*                            return(*/}
                {/*                                <p className="text">{"● " + careerOption}</p>*/}
                {/*                            )*/}
                {/*                        })}*/}
                {/*                    </div>*/}
                {/*                    <div className="view_report_page_5_classification_item_content_subitem">*/}
                {/*                        <p className="title">Education Requirements</p>*/}
                {/*                        {data.classification.educationRequirements.texts.map(requirement => {*/}
                {/*                            return(*/}
                {/*                                <p className="text">{"● " + requirement}</p>*/}
                {/*                            )*/}
                {/*                        })}*/}
                {/*                        <p className="text"><i>{data.classification.educationRequirements.note}</i></p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        )*/}
                {/*    })}*/}
                {/*</div>*/}

                {/*------------------------------- PAGE 6 ENQUIRE ----------------------------*/}
                <div className="view_report_page_6">
                    <img src={ReportBotImage} alt={"Students using a laptop"}/>
                    {(langId === 0)? <p>To enquire about a career counselling appointment,
                        please email us at <span>uniqueausmum@gmail.com</span> with the
                        title <span>“Career Counselling Inquiry”</span>. You can also call
                        us or message us on WhatsApp on <span>9833808612</span>.</p> : null}
                    {(langId === 1)? <p>एक कैरियर परामर्श नियुक्ति के बारे में पूछताछ करने के लिए, हमें <span>“Career Counselling Inquiry”</span> के साथ <span>uniqueausmum@gmail.com</span> पर ईमेल करें। आप हमें <span>9833808612</span> पर व्हाट्सएप पर कॉल या मैसेज भी कर सकते हैं।</p> : null}
                    {(langId === 2)? <p>करिअर समुपदेशन भेटीबद्दल विचारणा करण्यासाठी, कृपया आम्हाला <span>“Career Counselling Inquiry”</span> या शीर्षकासह <span>evalcareertest@gmail.com</span> वर ईमेल करा. आपण <span>9833808612</span> वर आम्हाला कॉल करू शकता किंवा व्हॉट्सअ‍ॅपवर संदेश देऊ शकता.</p> : null}
                    {(langId === 3)? <p>કરિયર પરામર્શની નિમણૂક વિશે પૂછપરછ કરવા માટે, કૃપા કરીને અમને <span>“Career Counselling Inquiry”</span> શીર્ષક સાથે <span>uniqueausmum@gmail.com</span> પર ઇમેઇલ કરો. તમે અમને <span>9833808612</span> પર પણ ફોન કરી શકો છો અથવા અમને વોટ્સએપ મેસેજ કરી શકો છો.</p> : null}
                </div>
            </div>
        )
    }else if(responseError){
        return <Redirect to="/profile" />
    } else return <div className="view_report_page_progress_container">
        <ThemeProvider theme={progressTheme}>
            <CircularProgress size={50} color="primary"/>
        </ThemeProvider>
    </div>
}