import React, {useEffect, useState} from "react";
import './CheckoutPage.css';
import EngineeringLogo from '../Assets/Homepage/engineering-eval-icon.png';
import {useHistory, useParams} from "react-router";
import GeneralLogo from "../Assets/Homepage/general-eval-icon.png";
import HumanitiesLogo from "../Assets/Homepage/humanities-eval-icon.png";
import ScienceLogo from "../Assets/Homepage/science-eval-icon.png";
import CommerceLogo from "../Assets/Homepage/commerce-eval-icon.png";
import firebase from "../Firebase/Firebase";
import gql from 'graphql-tag';
import {useApolloClient, useMutation, useQuery} from "@apollo/client";
import {Redirect} from "react-router";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Razorpay from "../Razorpay";
import {Snackbar} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import APP from '../services/app-service'
const GLOBAL = require('../services/global-constant');
const axios = require('axios').default;

const TEST_PURCHASED_CHECK = gql`
    query TestPurchasedCheck($email: String!, $testId: Int!) {
        purchases(where: {user: {email: {_eq: $email}}, testId: {_eq: $testId}, purchaseStatusId: {_eq: 2}}) {
            id
            responses {
                id
            }
        }
        users(where: {email: {_eq: $email}}) {
            id
            name
            phoneNumber
        }
    }`;

const VOUCHER_CHECK = gql`
    query VoucherCheck($code: String!, $testId: Int!) {
        merchant_codes_aggregate(where: {code: {_eq: $code}, isRedeemed: {_eq: false}, merchant: {testId: {_eq: $testId}}}) {
            aggregate {
                count
            }
        }
    }`;


const CREATE_PURCHASE = gql`
    mutation CreatePurchase($testId: Int!, $userId: Int!, $merchantCode: String, $promoCode: String) {
        createPurchase(input: {currency: "INR", testId: $testId, userId: $userId, merchantCode: $merchantCode, promoCode: $promoCode}) {
            razorpayOrderId
            purchase {
                amount
                id
            }
        }
    }`;

const UPDATE_PURCHASE = gql`
    mutation UpdatePurchase($purchaseId: Int!, $code: String!, $testId: Int!) {
        update_purchases(where: {id: {_eq: $purchaseId}}, _set: {purchaseStatusId: 2}) {
            affected_rows
        }
        update_merchant_codes(where: {code: {_eq: $code}, isRedeemed: {_eq: false}, merchant: {testId: {_eq: $testId}}}, _set: {isRedeemed: true}) {
            affected_rows
        }
    }`;

const VERIFY_PURCHASE = gql`
    mutation VerifyPurchase ($purchaseId: Int!, $razorpayPaymentId: String!, $razorpayOrderId: String!, $razorpaySignature: String!){
        verifyRazorpaySignature(input: {purchaseId: $purchaseId, razorpayOrderId: $razorpayOrderId, razorpayPaymentId: $razorpayPaymentId, razorpaySignature: $razorpaySignature}) {
            code
            message
        }
    }`;

const DELETE_PURCHASE = gql`
    mutation DeletePurchase($purchaseId: Int!) {
        delete_purchases(where: {id: {_eq: $purchaseId}}) {
            affected_rows
        }
    }`;


// CONSTANT HARDCODED VALUES OF TEST ID'S
const TEST_ID = [
    {name: "General Eval", id: 1, icon: GeneralLogo},
    {name: "Eval for Humanities", id: 2, icon: HumanitiesLogo},
    {name: "Eval for Engineering", id: 3, icon: EngineeringLogo},
    {name: "Eval for Science", id: 4, icon: ScienceLogo},
    {name: "Eval for Commerce", id: 5, icon: CommerceLogo},
];

const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
});

const snackbarButtonTheme = createMuiTheme({
    palette: {
        primary: {500: "#C0EEF9"},
    },
});

export default function CheckoutPage() {

    const [email, setEmail] = useState(firebase.auth().currentUser && firebase.auth().currentUser.email);
    const [couponValue, setCouponValue] = useState("");
    const [testItem, setTestItem] = useState(null);
    const [redirect, setRedirect] = useState(false); // Set to true if the user should be redirected to home
    const [checkoutLoading, setCheckoutLoading] = useState(false); // Boolean to show if mutation is loading, decides whether progress or action button is shown
    const [userId, setUserId] = useState(null);
    const [username, setUsername] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [voucherValid, setVoucherValid] = useState(false); // Boolean that shows whether couponValue is a valid voucher, to allow for payment bypass workflow and text changes

    // Snackbar states
    const [snackBarOpen, setSnackbarOpen] = useState(false);
    const [buttonSnackbarOpen, setButtonSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [buttonSnackbarMessage, setButtonSnackbarMessage] = useState("");

    //set purches
    const [purchasedData, setPurchasedData] = useState(null);
    const [purchasedError, setPurchasedError] = useState(false);
    const [purchasedLoading, setPurchasedLoading] = useState(true);
    const [isRespondedTest, setIsRespondedTest] = useState(false);

    //set voucher
    const [voucherCheckData, setVoucherCheckData] = useState(true);
    const [voucherCheckLoading, setVoucherCheckLoading] = useState(false);
    const [voucherCheckError, setVoucherCheckError] = useState(true);

    // Snackbar close function for snackbar without button
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);    
    };

    // Snackbar close function for snackbar with button
    const handleButtonSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setButtonSnackbarOpen(false);    
    };

    const paymentFailMessage = () => {
        setSnackbarMessage("Payment Failed");
        setSnackbarOpen(true);
    };

    const {testId} = useParams();
  
    const history = useHistory();

    const client = useApolloClient();

    const [createPurchase] = useMutation(CREATE_PURCHASE);

    const [updatePurchase] = useMutation(UPDATE_PURCHASE); // USED WHEN USER ENTERS VALID MERCHANT CODE TO BYPASS RAZORPAY PAYMENT AND SET PURCHASE STATUS TO PAID

    const [verifyPurchase] = useMutation(VERIFY_PURCHASE);

    const [paymentFailedMutation] = useMutation(DELETE_PURCHASE); // DELETE CREATED PURCHASE IF THE PAYMENT FAILED FOR CLEANUP

    // SET THE TEST ITEM OBJECT WHICH HAS NAME OF TEST AND ICON USING THE TEST IT FROM URL PARAMS
    useEffect(() => {
        const selectedTest = TEST_ID.filter((test) => test.id === parseInt(testId));
        if (selectedTest && selectedTest.length) {
            setTestItem(selectedTest[0]);
        } else setRedirect(true);
    }, [testId]);


    useEffect(() => {
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getTestPurchasedCheck, {
            testId: parseInt(testId),
      },APP.getToken())
      .then(function (response) {
        console.log("getTestPurchasedCheck response",response);
        if(!response.data.error){
            setPurchasedData(response.data);
        }else{
            setSnackbarMessage(response.data.message);
            setSnackbarOpen(true);
        }
      })
      .catch(function (error) {
        console.log("error",error);
      });
    }, [testId]);

    useEffect(() => {
        if(purchasedData){
            setPurchasedLoading(false);
            setPurchasedError(false);
            setIsRespondedTest(purchasedData.purchases.length && (purchasedData.purchases.filter((item) => !item.responses.length).length));
        }
    }, [purchasedData]);

    // CHECK WHETHER USER ALREADY HAS AN ACTIVE TEST OF SAME TYPE, IF A TEST IS PAID FOR ALREADY, REDIRECT TO SOME OTHER PAGE TO DISALLOW ANOTHER PURCHASE
    // const {data: purchasedData, error: purchasedError, loading: purchasedLoading} = useQuery(TEST_PURCHASED_CHECK, {
    //     variables: {
    //         email: email,
    //         testId: parseInt(testId)
    //     }
    // });
    
    useEffect(() => {
        if(couponValue){
            setVoucherCheckLoading(true);
            axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getVoucherCheckData, {
                code:couponValue,
                testId: parseInt(testId),
          },APP.getToken())
          .then(function (response) {
            if(!response.data.error){
                setVoucherCheckError(false);
                setVoucherCheckData(response.data);
                setVoucherValid(true);
            }else{
                //setSnackbarMessage(response.data.message);
                //setSnackbarOpen(true);
            }
          })
          .catch(function (error) {
            console.log("error",error);
          });
          setVoucherCheckLoading(false);
        }
    }, [couponValue]);

    // CHECK FOR VOUCHER VALIDITY IN MERCHANT CODES TO CHANGE TEXT ON THE SCREEN AND USE DIFFERENT WORKFLOW TO BYPASS PAYMENT
    // const {data: voucherCheckData, loading: voucherCheckLoading, error: voucherCheckError} = useQuery(VOUCHER_CHECK, {
    //     variables: {
    //         code: couponValue,
    //         testId: testId || 1
    //     }
    // });

    // console.log(voucherCheckData);
    // console.log(voucherCheckError);
    // console.log(voucherCheckLoading);

    // useEffect(() => {
    //     console.log(voucherCheckData);
    //     if(voucherCheckData && voucherCheckData.merchant_codes_aggregate.aggregate.count){
    //         console.log(voucherCheckData);
    //         setVoucherValid(true);
    //     } else {
    //         setVoucherValid(false);
    //     }
    // },[voucherCheckData]);


    useEffect(() => {
        if (purchasedData ) {
            axios.get(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getUserDetails,APP.getToken())
            .then(function (response) {
                if(!response.data.error){
                    setUserId(response.data.users.id);
                    setUsername(response.data.users.name);
                    setPhoneNumber(response.data.users.phoneNumber);
                }else{
                }
            })
            .catch(function (error) {
                console.log("error",error);
            });
        }
        // if (purchasedData && purchasedData.users) {
        //     setUserId(purchasedData.users[0].id);
        //     setUsername(purchasedData.users[0].name);
        //     setPhoneNumber(purchasedData.users[0].phoneNumber);
        // }
    }, [purchasedData]);


    const checkoutButtonHandler = () => {
        setCheckoutLoading(true);
        if( voucherValid){
            if(couponValue){
                setVoucherCheckLoading(true);
                axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getVoucherCheckData, {
                    code:couponValue,
                    testId: parseInt(testId),
                },APP.getToken())
                .then(function (response) {
                    if(!response.data.error){
                        if(response.data.count){
                            axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.createMerchantPurchase, {
                                merchantCode:couponValue,
                                testId: parseInt(testId),
                                userId: userId,
                            },APP.getToken())
                            .then(function (response) {
                                if(!response.data.error){
                                    history.push("/profile");
                                }else{
                                    setSnackbarMessage(response.data.message);
                                    setSnackbarOpen(true);
                                }
                            })
                            .catch(function (error) {
                                console.log("error",error);
                            });
                        }
                    }else{
                        setSnackbarMessage(response.data.message);
                        setSnackbarOpen(true);
                    }
                })
                .catch(function (error) {
                    console.log("error",error);
                });
                setVoucherCheckLoading(false);
            }
            // else{
            //     setSnackbarMessage("Enter Coupon");
            //     setSnackbarOpen(true);
            //     setCheckoutLoading(false);
            // }
        }else {
            axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.createPurchase, {
                promoCode:couponValue.toString(),
                testId: parseInt(testId),
                userId: userId,
            },APP.getToken())
            .then(function (createResponse) {
                if(!createResponse.data.error){
                    const userObject = {
                        name: username,
                        email: email,
                        phoneNumber: phoneNumber
                    };
                    const onDismiss = () => {
                        setCheckoutLoading(false);
                        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.deletePurchase, {
                            purchaseId:createResponse.data.purchase.id
                        },APP.getToken())
                        .then(function (deletePurchaseResponse) {
                            if(!deletePurchaseResponse.data.error){
                            }else{
                                setSnackbarMessage(deletePurchaseResponse.data.message);
                                setSnackbarOpen(true);
                            }
                        })
                        .catch(function (error) {
                            console.log("error",error);
                        });
                    };

                    const razorpayResponseMethod = (razorpayResponse) => {
                        console.log(razorpayResponse);
                        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.verifyPurchase, {
                            purchaseId:createResponse.data.purchase.id,
                            razorpayPaymentId: razorpayResponse.razorpay_payment_id,
                            razorpayOrderId: razorpayResponse.razorpay_order_id,
                            razorpaySignature: razorpayResponse.razorpay_signature
                        },APP.getToken())
                        .then(function (verifyPurchaseResponse) {
                            if(!verifyPurchaseResponse.data.error){
                                if (verifyPurchaseResponse.data.purchase.purchaseStatusId === 2) {
                                    history.push("/profile");
                                } else {
                                    paymentFailMessage();
                                    setCheckoutLoading(false);
                                    axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.deletePurchase, {
                                        purchaseId:createResponse.data.purchase.id
                                    },APP.getToken())
                                    .then(function (failDeletePurchaseResponse) {
                                        if(!failDeletePurchaseResponse.data.error){
                                        }else{
                                            setSnackbarMessage(failDeletePurchaseResponse.data.message);
                                            setSnackbarOpen(true);
                                        }
                                    })
                                    .catch(function (error) {
                                        console.log("error",error);
                                    });
                                }
                            }else{
                                setSnackbarMessage(verifyPurchaseResponse.data.message);
                                setSnackbarOpen(true);
                            }
                        })
                        .catch(function (error) {
                                paymentFailMessage();
                                setCheckoutLoading(false);
                                axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.deletePurchase, {
                                    purchaseId:createResponse.data.purchase.id
                                },APP.getToken())
                                .then(function (failDeletePurchaseResponse) {
                                    if(!failDeletePurchaseResponse.data.error){
                                    }else{
                                        setSnackbarMessage(failDeletePurchaseResponse.data.message);
                                        setSnackbarOpen(true);
                                    }
                                })
                                .catch(function (error) {
                                    console.log("error",error);
                                });
                        });
                    };
                    Razorpay(createResponse.data.purchase.razorpayOrderId, testItem.name, userObject, razorpayResponseMethod, onDismiss);
                }else{
                    setSnackbarMessage(createResponse.data.message);
                    setSnackbarOpen(true);
                }
            })
            .catch(function (error) {
                console.log("error",error);
            });
        }        
    };

    // Checkout button handler
    // const checkoutButtonHandler = () => {
    //     setCheckoutLoading(true);
    //     if(firebase.auth().currentUser.emailVerified){
    //         if(voucherValid){
    //             client.query({
    //                 query: VOUCHER_CHECK,
    //                 variables: {
    //                     code: couponValue,
    //                     testId: testItem.id
    //                 }
    //             }).then(res => {
    //                 if(res.data.merchant_codes_aggregate.aggregate.count){
    //                     createPurchase({
    //                         variables: {
    //                             testId: testItem.id,
    //                             userId: userId,
    //                             merchantCode: (couponValue.length === 6) ? couponValue : null, // HARDCODED COUPON LENGTH AT 6 CHARACTERS
    //                         }
    //                     }).then(purchaseResponse => {
    //                         updatePurchase({
    //                             variables: {
    //                                 purchaseId: purchaseResponse.data.createPurchase.purchase.id,
    //                                 code: couponValue,
    //                                 testId: testItem.id
    //                             }
    //                         }).then(updateResponse => {
    //                             console.log(updateResponse);
    //                             history.push("/profile");
    //                         }).catch(err => {
    //                             console.log(err);
    //                             setSnackbarMessage("Something went wrong, check your internet connection");
    //                             setSnackbarOpen(true);
    //                             setCheckoutLoading(false);
    //                             paymentFailedMutation({
    //                                 variables: {
    //                                     purchaseId: purchaseResponse.data.createPurchase.purchase.id
    //                                 }
    //                             }).then().catch();
    //                         })
    //                     }).catch(err => {
    //                         console.log(err.message);
    //                         if (err.message === "Promo code does not exist in the Database") {
    //                             setSnackbarMessage("Invalid coupon/voucher");
    //                             setSnackbarOpen(true);
    //                         }
    //                         setCheckoutLoading(false);
    //                     })
    //                 } else{
    //                     setSnackbarMessage("Invalid coupon/voucher");
    //                     setSnackbarOpen(true);
    //                     setCheckoutLoading(false);
    //                 }
    //             }).catch(err =>{
    //                 console.log(err);
    //                 setSnackbarMessage("Something went wrong, check your internet connection");
    //                 setSnackbarOpen(true);
    //                 setCheckoutLoading(false);
    //             });
    //         } else{
    //             createPurchase({
    //                 variables: {
    //                     testId: testItem.id,
    //                     userId: userId,
    //                     promoCode: (couponValue.length === 6) ? null : couponValue // HARDCODED COUPON LENGTH AT 6 CHARACTERS
    //                 }
    //             }).then(purchaseResponse => {
    //                 const userObject = {
    //                     name: username,
    //                     email: email,
    //                     phoneNumber: phoneNumber
    //                 };
    //                 const onDismiss = () => {
    //                     setCheckoutLoading(false);
    //                     paymentFailedMutation({
    //                         variables: {
    //                             purchaseId: purchaseResponse.data.createPurchase.purchase.id
    //                         }
    //                     }).then().catch();
    //                 };
    //                 const razorpayResponseMethod = (razorpayResponse) => {
    //                     console.log(razorpayResponse);
    //                     verifyPurchase({
    //                         variables: {
    //                             purchaseId: purchaseResponse.data.createPurchase.purchase.id,
    //                             razorpayPaymentId: razorpayResponse.razorpay_payment_id,
    //                             razorpayOrderId: razorpayResponse.razorpay_order_id,
    //                             razorpaySignature: razorpayResponse.razorpay_signature
    //                         }
    //                     }).then(verifyRes => {
    //                         console.log(verifyRes);
    //                         if (verifyRes.data.verifyRazorpaySignature.code === 200) {
    //                             history.push("/profile");
    //                         } else {
    //                             paymentFailMessage();
    //                             setCheckoutLoading(false);
    //                             paymentFailedMutation({
    //                                 variables: {
    //                                     purchaseId: purchaseResponse.data.createPurchase.purchase.id
    //                                 }
    //                             }).then().catch();
    //                         }
    //                     }).catch(err => {
    //                         console.log(err);
    //                         paymentFailMessage();
    //                         setCheckoutLoading(false);
    //                         paymentFailedMutation({
    //                             variables: {
    //                                 purchaseId: purchaseResponse.data.createPurchase.purchase.id
    //                             }
    //                         }).then().catch();
    //                     })
    //                 };
    //                 Razorpay(purchaseResponse.data.createPurchase.razorpayOrderId, testItem.name, userObject, razorpayResponseMethod, onDismiss);
    //             }).catch(err => {
    //                 console.log(err.message);
    //                 if (err.message === "Promo code does not exist in the Database") {
    //                     setSnackbarMessage("Invalid coupon/voucher");
    //                     setSnackbarOpen(true);
    //                 }
    //                 setCheckoutLoading(false);
    //             })
    //         }
    //     }else {
    //         setButtonSnackbarMessage("You must verify your email before proceeding");
    //         setButtonSnackbarOpen(true);
    //         setCheckoutLoading(false);
    //     }        
    // };

    const snackbarButtonHandler = () => {
        const user = firebase.auth().currentUser;
        user.sendEmailVerification().then(res => {
            console.log(res);
            setSnackbarMessage("An email with a verification link has been sent to " + user.email);
            setSnackbarOpen(true);
        }).catch(err => {
            console.log(err);
            if(err.code === "auth/too-many-requests"){
                setSnackbarMessage("You have sent too many requests from this device, please try again later");
                setSnackbarOpen(true);
            }
        });
    };
    //console.log("-->", voucherCheckLoading);return false;
    //purchasedLoading removed
    if (!redirect && (!testItem) && purchasedLoading) return <div/>;
    // REDIRECT LOGIC
    else if (redirect  || purchasedError || isRespondedTest) {
        return <Redirect to={'/profile'}/>
    } else return (
        <div className="checkout_page">
            <div className="checkout_page_card">
                <h2>Checkout</h2>
                <div className="checkout_item_name">
                    <img src={testItem.icon} alt=""/>
                    <p>{testItem.name}</p>
                </div>
                <div className="checkout_page_card_input_item">
                    <p>COUPON / VOUCHER</p>
                    <input type="text" value={couponValue} onChange={(e) => setCouponValue(e.target.value)}/>
                </div>
                <div className="checkout_page_price">
                    <p className="label">TOTAL AMOUNT</p>
                    {/*HARDCODED VALUE OF TEST*/}
                    <p className="value">{voucherValid? "₹0" : "₹500"}</p>
                </div>
                {(checkoutLoading || voucherCheckLoading) ? <div className="checkout_page_card_progress_container">
                        <ThemeProvider theme={progressTheme}>
                            <CircularProgress size={30} color="primary"/>
                        </ThemeProvider>
                    </div> :
                    <button className="checkout_page_card_action" onClick={checkoutButtonHandler}>{voucherValid? "UNLOCK TEST" : "PROCEED TO PAYMENT"}</button>}
            </div>
            <ThemeProvider theme={snackbarButtonTheme}>
                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }} open={buttonSnackbarOpen} autoHideDuration={3000} onClose={handleButtonSnackbarClose} message={buttonSnackbarMessage}
                    action={
                        <Button color="primary" size="small" onClick={snackbarButtonHandler}>RESEND EMAIL</Button>
                    }/>
                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }} open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} message={snackbarMessage}/>
            </ThemeProvider>            
        </div>
    )
}