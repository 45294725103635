import React, {useState} from 'react';
import './Contact.css';
import gql from 'graphql-tag';
import {useMutation} from "@apollo/client";
import {Snackbar} from "@material-ui/core";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import APP from '../services/app-service';
const GLOBAL = require('../services/global-constant');
const axios = require('axios').default;

const INSERT_CONTACT_US = gql`
    mutation MyMutation($email: String!, $name: String!, $phoneNumber: String!, $reference: String!, $query: String!, $subject: String!) {
        insert_contact_us_one(object: {email: $email, name: $name, phoneNumber: $phoneNumber, reference: $reference, query: $query, subject: $subject}) {
            id
        }
    }`;


const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
});

const selectTheme = createMuiTheme({
    overrides: {
        MuiSelect: {
            select: {
                "&:focus": {
                    backgroundColor: "none",
                }
            },
            selectMenu: {
                backgroundColor: "#F1F1F1"
            }
        }
    }
});


export default function Contact(){
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [reference, setReference] = useState("");
    const [subject, setSubject] = useState("");
    const [query, setQuery] = useState("");

    // Snackbar states
    const [snackBarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [longSnackbarOpen, setLongSnackbarOpen] = useState(false);
    const [longSnackbarMessage, setLongSnackbarMessage] = useState("");

    const [submitLoading, setSubmitLoading] = useState(false);

    // Snackbar close function
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // Snackbar close function
    const handleLongSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setLongSnackbarOpen(false);
    };


    const [insertContactRequest] = useMutation(INSERT_CONTACT_US);


    // Submit button click handler function
    const onSubmitHandler = () => {
        setSubmitLoading(true);
        if(name && email && phone && reference && subject && query){
            if(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))){
                setSnackbarMessage("Email address is invalid");
                setSnackbarOpen(true);
                setSubmitLoading(false);
            } else if(/([a-z]|[A-Z])/.test(phone)){
                setSnackbarMessage("Phone number is invalid");
                setSnackbarOpen(true);
                setSubmitLoading(false);
            } else {

            axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getAddContact, {
                email: email,
                name: name,
                phoneNumber: phone,
                reference: reference,
                subject: subject,
                query: query
              })
              .then(function (response) {
                console.log("login response",response);
                if(!response.data.error){
                    setSnackbarMessage("Your query has been submitted");
                    setSnackbarOpen(true);
                    setEmail("");
                    setPhone("");
                    setName("");
                    setQuery("");
                    setSubject("");
                    setReference("");                    
                    setSubmitLoading(false);
                }else{
                    setSnackbarMessage("Something went wrong, check your internet connection");
                    setSnackbarOpen(true);
                    setSubmitLoading(false);
                }
              })
              .catch(function (error) {
                console.log("error",error);
              });

                // insertContactRequest({
                //     variables: {
                //         email: email,
                //         name: name,
                //         phoneNumber: phone,
                //         reference: reference,
                //         subject: subject,
                //         query: query
                //     }
                // }).then(res => {
                //     if(subject === "Partner With Eval"){
                //         setLongSnackbarMessage("Your query has been submitted. Please email your Company Profile to uniqueausmum@gmail.com");
                //         setLongSnackbarOpen(true);
                //     }else {
                //         setSnackbarMessage("Your query has been submitted");
                //         setSnackbarOpen(true);
                //     }
                //     setEmail("");
                //     setPhone("");
                //     setName("");
                //     setQuery("");
                //     setSubject("");
                //     setReference("");                    
                //     setSubmitLoading(false);
                // }).catch(err => {
                //     console.log(err);
                //     setSnackbarMessage("Something went wrong, check your internet connection");
                //     setSnackbarOpen(true);
                //     setSubmitLoading(false);
                // })
            
            
            
            
            
            
            
            }
        } else{
            setSnackbarMessage("All fields are required");
            setSnackbarOpen(true);
            setSubmitLoading(false);
        }
    };

    return(
        [<div className="contact_page" key={"contactpage"}>
            <div className="contact_page_card">
                <div className="contact_page_card_section-1">
                    <h1>Contact Us</h1>
                    <div className="contact_page_card_section-1_inputs">
                        <div className="contact_page_card_section-1_rows_container">
                            <div className="contact_page_card_section-1_row_item">
                                <p>NAME</p>
                                <input value={name} onChange={(e) => setName(e.target.value)}/>
                            </div>
                            <div className="contact_page_card_section-1_row_item">
                                <p>EMAIL</p>
                                <input value={email} onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                            <div className="contact_page_card_section-1_row_item">
                                <p>PHONE NUMBER</p>
                                <input value={phone} onChange={(e) => setPhone(e.target.value)}/>
                            </div>
                            <div className="contact_page_card_section-1_row_item">
                                <p>SUBJECT</p>
                                <div>
                                    <ThemeProvider theme={selectTheme}>
                                        <Select
                                            native
                                            value={subject}
                                            disableUnderline
                                            fullWidth
                                            onChange={(e) => setSubject(e.target.value)}>
                                            <option aria-label="None" value="" />
                                            <option value={"Student Query"}>Student Query</option>
                                            <option value={"Partner With Eval"}>Partner With Eval</option>
                                            <option value={"Other"}>Other</option>                                    
                                        </Select>
                                    </ThemeProvider>
                                </div>
                                {/* <input value={subject} onChange={(e) => setSubject(e.target.value)}/> */}
                            </div>
                            <div className="contact_page_card_section-1_row_item">                                
                                <p>WHERE DID YOU HEAR ABOUT US</p>
                                <div>
                                    <ThemeProvider theme={selectTheme}>
                                        <Select
                                            native
                                            value={reference}
                                            disableUnderline
                                            fullWidth
                                            onChange={(e) => setReference(e.target.value)}>
                                            <option aria-label="None" value="" />
                                            <option value={"News"}>News</option>
                                            <option value={"Friends"}>Friends</option>
                                            <option value={"Google"}>Google</option>
                                            <option value={"Instagram"}>Instagram</option>
                                            <option value={"Facebook"}>Facebook</option>
                                            <option value={"Other"}>Other</option>                                    
                                        </Select>
                                    </ThemeProvider>
                                </div>
                                {/* <input value={reference} onChange={(e) => setReference(e.target.value)}/> */}
                            </div>
                            <div className="contact_page_card_section-1_row_item full_width">
                                <p>TYPE YOUR QUERY HERE</p>
                                <textarea value={query} onChange={(e) => setQuery(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    {submitLoading? <div className="contact_page_progress_container">
                        <ThemeProvider theme={progressTheme}>
                            <CircularProgress size={30} color="primary"/>
                        </ThemeProvider>
                    </div>: <button onClick={() =>  onSubmitHandler()}>SEND QUERY</button>}
                </div>
                <div className="contact_page_card_section-2">
                    <div className="contact_page_card_section-2_item">
                        <h2>Partner with us.</h2>
                        <p>UEMS Ventures is looking for partners who can join us in our mission of reaching out to as many students for Career Clarity Test Series. </p>
                        <p>If you are someone who can work with our team to help students use Eval to find clarity then let's Discuss what we 
                            can do together. Working with our team means you gain full support in understanding the product, its benefits for 
                            the students and yourself.</p>
                    </div>
                    <div className="contact_page_card_section-2_item">
                        <h3>Address</h3>
                        <p>416 Marathon Max</p>
                        <p>LBS Marg</p>
                        <p>Mulund West</p>
                        <p>Mumbai - 400080</p>
                    </div>
                    <div className="contact_page_card_section-2_item">
                        <h3>Mail us</h3>
                        <p className="contact_page_card_section-2_item_small-text">admin@evaltest.com</p>
                        <p className="contact_page_card_section-2_item_small-text">partnerships@evaltest.com</p>
                    </div>
                </div>
            </div>
        </div>, <Snackbar anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }} open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} message={snackbarMessage} key="snackbar"/>, 
        <Snackbar anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }} open={longSnackbarOpen} onClose={handleLongSnackbarClose} message={longSnackbarMessage} key="snackbar"/>]
    )
}