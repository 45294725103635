import React, {useState, useEffect, useRef} from 'react';
import './HomeTopCarousel.css';
import {useSpring, useSprings, animated, useTransition} from "react-spring";


/* Single use component for the home screen at the top-right. Infinite time based scrolling with carousel for both text
and image tied together, custom buttons for control as well.*/

const textItems = ["Identify your passion, strengths and interests",
    "Get personalized education and job recommendations     ",
    "Gain clarity about your career path"];

//Generates styles for the dot markers
const getDotInnerStyles = (itemIndex, selectedIndex) => {
    return {opacity: (itemIndex === selectedIndex) ? "1" : "0.5", margin: "5px 0"}
};


//Generates styles for text position of the carousel
const getTextItemStyles = (itemIndex, selectedIndex) => {
    switch (selectedIndex) {
        case 0:
            if (itemIndex === 0) {
                return {top: "33%", opacity: "1"};
            } else if (itemIndex === 1) {
                return {top: "33%", opacity: "0"};
            } else return {top: "-66%", opacity: "0"};

        case 1:
            if (itemIndex === 0) {
                return {top: "0", opacity: "0"};
            } else if (itemIndex === 1) {
                return {top: "0", opacity: "1"};
            } else return {top: "0", opacity: "0"};

        case 2:
            if (itemIndex === 0) {
                return {top: "66%", opacity: "0"};
            } else if (itemIndex === 1) {
                return {top: "-33%", opacity: "0"};
            } else return {top: "-33%", opacity: "1"};

        default:
            if (itemIndex === 0) {
                return {top: "33%", opacity: "1"};
            } else if (itemIndex === 1) {
                return {top: "33%", opacity: "0"};
            } else return {top: "33%", opacity: "0"};
    }
};

const BorderCircle = (props) => {
    const offset = useSpring({x: -28*Math.PI, from: {x: -14*Math.PI}, config: {duration: 5000}});
    return <animated.circle cx="8" cy="8" r="7" stroke="white" strokeWidth="1px" fill="none"
                            opacity={(props.index === props.currentItem)? 1: 0} strokeDasharray={14*Math.PI}
                            strokeDashoffset={offset.x}/>
};

// Function that returns previous item index
const usePrevItem = index => {
    const ref = useRef(0);
    useEffect(() => {
        ref.current = index;
    });
    return ref.current;
};

// Function that returns whether current value is greater than previous value in an infinite carousel of length = arrLen
const isCurrentGreater = (current, previous, arrLen) =>  {
    if ((current - previous) === 1){
        return true;
    } else if((current - previous) === -1){
        return false;
    } else {
        if(arrLen % 2 === 1){
            const half = Math.floor(arrLen/2);
            if(previous <= half){
                return (0 < (current - previous) && (current - previous) <= half);
            } else {
                return !(0 < (previous - current) && (previous - current) <= half);
            }
        } else {
            const half = arrLen / 2;
            if (previous <= half) {
                return (0 < (current - previous) && (current - previous) <= half);
            } else {
                return !(0 < (previous - current)&&(previous - current) < half);
            }
        }
    }
};

// Text components
const text = textItems.map((data) =>
    ({style}) =>  <animated.p className="home_top_carousel_row_text_item" style={{...style}}> {data} </animated.p>

);


export default function HomeTopCarousel() {
    const [currentItem, setCurrentItem] = useState(0);
    const prevItem = usePrevItem(currentItem);
    const timer = useRef(null);

    const dotInnerStyles = useSprings(textItems.length, textItems.map((x, index) => {
        return getDotInnerStyles(index, currentItem);
    }));

    // const dotBorderStyles = useSprings(textItems.length, textItems.map((x, index) => {
    //     return (index === currentItem)? {x: 100, from: {x: 0}} : {x: 0}
    // }), {config: {duration: 5000}});

    // const textStyles = useSprings(textItems.length, textItems.map((x, index) => {
    //     return getTextItemStyles(index, currentItem);
    // }));

    const textStyles = useTransition(currentItem, p => p, {
        // from: () => {
        //     if (isCurrentGreater(currentItem, prevItem, textItems.length)) {
        //         return {opacity: 0, transform: 'translate3d(0, 120% ,0)'}
        //     } else return {opacity: 0, transform: 'translate3d(0, -120%,0)'}
        // },
        // enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
        // leave: () => {
        //     if (isCurrentGreater(currentItem, prevItem, textItems.length)) {
        //         return {opacity: 0, transform: 'translate3d(0,-120%,0)'}
        //     } else return {opacity: 0, transform: 'translate3d(0, 120% ,0)'}
        // }
        initial: { opacity: 1, top: "33%" },
        from: () => {
            if (isCurrentGreater(currentItem, prevItem, textItems.length)) {
                return {opacity: 0, top: "66%"}
            } else {
                console.log(false);
                return {opacity: 0, top: "0%"}
            }
        },
        enter: { opacity: 1, top: "33%" },
        leave: () => {
            if (isCurrentGreater(currentItem, prevItem, textItems.length)) {
                return {opacity: 0, top: "0%"}
            } else return {opacity: 0, top: "66%"}
        }
    });

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            setCurrentItem((item) => {
                if (item < 2) {
                    return item + 1
                } else return 0
            })
        }, 5000);
        return () => {
            clearTimeout(timer.current);
        }
        // setBorderStyle({x: -28*Math.PI, from: {x: -14*Math.PI}, config: {duration: 5000}});
    }, [currentItem]);

    return (
        <div className="Home_top_carousel">
            <div className="home_top_carousel_imagebox"/>
            <div className="home_top_carousel_row">
                <div className="home_top_carousel_row_textbox">

                    {/*Dots serving as carousel markers*/}
                    <div className="home_top_carousel_row_dots">
                        {dotInnerStyles.map((styles, index) =>
                            <animated.svg height="16" width="16" key={index} style={{...styles, transform: 'rotate(-90deg)'}}>
                                <BorderCircle currentItem={currentItem} index={index} key={currentItem}/>
                                <circle cx="8" cy="8" r="3" stroke="black" strokeWidth="0" fill="white"/>
                            </animated.svg>
                        )}
                    </div>

                    {/*Text carousel */}
                    <div className="home_top_carousel_row_text_container">
                        {textStyles.map(({item, props, key}) => {
                            const Text = text[item];
                            return <Text key={key} style={props}/>
                        })}
                        {/*{textStyles.map((positions, index) =>*/}
                        {/*    <animated.p className="home_top_carousel_row_text_item"*/}
                        {/*                key={index} style={positions}> {textItems[index]} </animated.p>*/}
                        {/*)}*/}
                    </div>
                </div>
                <div className="home_top_carousel_row_emptybox">
                    <div className="home_top_carousel_row_button_up" onClick={() => {
                        if (currentItem > 0) {
                            setCurrentItem((item) => item - 1);
                        } else {
                            setCurrentItem(2);
                        }
                    }}/>
                    <div className="home_top_carousel_row_button_down" onClick={() => {
                        if (currentItem < 2) {
                            setCurrentItem((item) => item + 1);
                        } else {
                            setCurrentItem(0);
                        }
                    }}/>
                </div>
            </div>
        </div>
    )
}

