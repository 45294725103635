import {StyleSheet, Font} from "@react-pdf/renderer";
import hindi from '../Fonts/NIRMALAS.TTF';

Font.register({ family: 'Noto', src: hindi })

const styles = StyleSheet.create({
    // view_report_fixed_icon_row_first_page: {
    //     width: "100%",
    //     height: "30pt",
    //     position: "absolute",
    //     top: "10pt",
    //     display: "flex",
    //     flexDirection: "row",
    //     justifyContent: "space-between",
    //     paddingLeft: "10pt",
    // },
    // view_report_fixed_icon_row_first_page_icon: {
    //     width: "auto",
    //     height: "100%",
    //     marginRight: "10pt"
    // },
    view_report_fixed_icon_row: {
        width: "100%",
        height: "30pt",
        position: "absolute",
        top: "10pt",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: "10pt",
    },
    view_report_fixed_icon_1: {
        width: "auto",
        height: "100%",
    },
    view_report_fixed_icon_2: {
        width: "auto",
        height: "100%",
        position: "relative",
        left: "50pt"
    },
    view_report_fixed_icon_2_page_1: {
        width: "auto",
        height: "100%",
        position: "relative",
        right: '50pt'
    },
    view_report_page_1: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
    },    
    view_report_page_1_svg: {
        position: "absolute",
        width: "20%",
        height: "100%",
        left: "0",
        top: "0",
        backgroundColor: "#024E85",
        zIndex: "0",
    },
    view_report_page_1_image_container: {
        width: "50%",
        height: "100%",
        padding: "100pt 30pt",
        boxSizing: "border-box",
        position: "relative",
    },
    view_report_page_1_image_container_image_large: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: "center",
    },
    view_report_page_1_image_container_logo: {
        width: "50pt",
        height: "auto",
        position: "absolute",
        top: "110pt",
        left: "60pt",
    },
    view_report_page_1_text_container: {
        width: "50%",
        height: "100%",
        boxSizing: "border-box",
        padding: "50pt 100pt"
    },
    view_report_page_1_textbox: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
    },
    view_report_page_1_textbox_h2: {
        fontSize: "26pt",
        //fontFamily: "Gibson-light",
        color: "#024E85",
        marginBottom: 20,
        fontWeight: "bold",
        //letterSpacing: "1pt",
        textAlign: "left",
        width: "100%",
    },
    view_report_page_1_textbox_p: {
        fontSize: "20pt",
        //fontFamily: "Gibson-light",
        color: "black",
        marginBottom: 20,
        fontWeight: "normal",
        //letterSpacing: 1,
        textAlign: "left",
        width: "100%",
    },
    view_report_page_1_textbox_p_subtitle: {
        fontSize: "10pt",
        //fontFamily: "Josefinsans",
        color: "#062D4A",
        marginBottom: 10,
        fontWeight: "bold",
        width: "100%",
        //letterSpacing: "1pt",
        textAlign: "left",
    },


    // ----------------------------------- PAGE 2 GENERAL INSTRUCTIONS -----------------------------
    view_report_page_2: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
    },
    view_report_page_2_first_half: {
        width: "50%",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        paddingLeft: "30pt",
        paddingRight: "30pt",
        backgroundColor: "#EFF6FF",
        justifyContent: "center",
        alignItems: "center",
    },
    view_report_page_2_first_half_title:{
        fontSize: "18pt",
        color: "black",
        marginBottom: "30pt",
        fontWeight: "bold",
        //letterSpacing: "1pt",
        textAlign: "left",
        width: "100%",
    },
    view_report_page_2_first_half_title_hindi:{
        fontSize: "18pt",
        fontFamily: "Noto",
        color: "black",
        marginBottom: "30pt",
        //letterSpacing: "1pt",
        textAlign: "left",
        width: "100%",
    },
    view_report_page_2_first_half_title_subtitle_item: {
        width: "100%"
    },
    view_report_page_2_first_half_subtitle: {
        fontSize: "16pt",
        color: "black",
        marginBottom: "10pt",
        fontWeight: "normal",
        //letterSpacing: "1pt",
        textAlign: "left",
        width: "100%",
    },
    view_report_page_2_first_half_subtitle_hindi: {
        fontSize: "16pt",
        fontFamily: "Noto",
        color: "black",
        marginBottom: "10pt",
        //letterSpacing: "1pt",
        textAlign: "left",
        width: "100%",
    },
    view_report_page_2_first_half_text: {
        fontSize: "10pt",
        color: "black",
        marginBottom: "20pt",
        fontWeight: "normal",
        //letterSpacing: "1pt",
        textAlign: "left",
        width: "100%",
    },
    view_report_page_2_first_half_text_hindi: {
        fontSize: "10pt",
        fontFamily: "Noto",
        color: "black",
        marginBottom: "20pt",
        //letterSpacing: "1pt",
        textAlign: "left",
        width: "100%",
    },
    view_report_page_2_first_half_note_text: {
        fontSize: "10pt",
        color: "black",
        fontWeight: "bold",
        //letterSpacing: "1pt",
        textAlign: "left",
        width: "100%",
    },
    view_report_page_2_first_half_note_text_hindi: {
        fontSize: "10pt",
        fontFamily: "Noto",
        color: "black",
        //letterSpacing: "1pt",
        textAlign: "left",
        width: "100%",
    },
    view_report_page_2_second_half: {
        width: "50%",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        paddingLeft: "30pt",
        paddingRight: "30pt",
        backgroundColor: "#024E85",
        justifyContent: "center",
        alignItems: "center",
    },
    view_report_page_2_second_half_title: {
        fontSize: "16pt",
        color: "white",
        margin: "0 0 50pt",
        fontWeight: "bold",
        //letterSpacing: "1pt",
        textAlign: "left",
        lineHeight: "1.3",
        width: "100%",
    },
    view_report_page_2_second_half_title_hindi: {
        fontSize: "16pt",
        fontFamily: "Noto",
        color: "white",
        margin: "0 0 50pt",
        //letterSpacing: "1pt",
        textAlign: "left",
        lineHeight: "1.3",
        width: "100%",
    },
    view_report_page_2_second_half_text: {
        fontSize: "10pt",
        color: "white",
        margin: "0 0 30pt",
        fontWeight: "normal",
        //letterSpacing: "1pt",
        textAlign: "left",
        lineHeight: "1.3",
        width: "100%",
    },
    view_report_page_2_second_half_text_hindi: {
        fontSize: "10pt",
        fontFamily: "Noto",
        color: "white",
        margin: "0 0 30pt",
        //letterSpacing: "1pt",
        textAlign: "left",
        lineHeight: "1.3",
        width: "100%",
    },
    // ----------------------------------PAGE 3 ------------------------------------------
    view_report_page_3: {
        width: "100%",
        height: "100%",
        padding: "50pt",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    view_report_page_3_top_row: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "auto",
        alignItems: "center",
    },
    view_report_page_3_top_row_h2: {
        fontSize: "22pt",
        //fontFamily: "Gibson-light",
        color: "#024E85",
        marginBottom: "20pt",
        fontWeight: "bold",
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_3_top_row_h2_hindi: {
        fontSize: "22pt",
        //fontFamily: "Gibson-light",
        fontFamily: "Noto",
        color: "#024E85",
        marginBottom: "20pt",
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_3_top_row_p: {
        fontSize: "10pt",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0 auto 20pt",
        fontWeight: "normal",
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_3_top_row_p_hindi: {
        fontSize: "10pt",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        fontFamily: "Noto",
        margin: "0 auto 20pt",
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_3_graphs: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },

    //CAVEAT: TOO MANY ITEMS IN THE CATEGORIES COULD TEAR THE PDF
    view_report_page_3_graphs_column: {
        width: "350pt",
        height: "auto",
        margin: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
    },
    view_report_page_3_graphs_item: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10pt",
    },
    view_report_page_3_graphs_text: {
        fontSize: "10pt",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0 auto 0 0",
        width: "20%",
        fontWeight: "normal",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_3_graphs_text_hindi: {
        fontSize: "10pt",
        //fontFamily: "Gibson-light",
        fontFamily: "Noto",
        color: "#2D2F31",
        margin: "0 auto 0 0",
        width: "20%",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_3_graphs_bar: {
        width: "calc(80% - 30pt)",
        height: "30pt",
        borderRadius: "10pt",
        backgroundColor: "#F4F5F7",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        overflow: "hidden",
    },
    view_report_page_3_graphs_number: {
        position: "relative",
        alignSelf: "center",
        width: "30pt",
        textAlign: "right",
        fontSize: "10pt",
        right: "10pt",
        color: "#024E85",
        //fontFamily: "Gibson-light",
        fontWeight: "normal",
        //letterSpacing: "1pt",
        lineHeight: "1.3",
    },
    view_report_page_3_graphs_number_hindi: {
        position: "relative",
        alignSelf: "center",
        width: "30pt",
        textAlign: "right",
        fontSize: "10pt",
        fontFamily: "Noto",
        right: "10pt",
        color: "#024E85",
        //fontFamily: "Gibson-light",
        //letterSpacing: "1pt",
        lineHeight: "1.3",
    },
    view_report_page_3_graphs_interpretation: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "0",
    },
    view_report_page_3_graphs_interpretation_title: {
        fontSize: "24pt",
        //fontFamily: "Gibson-light",
        color: "#024E85",
        margin: "0",
        fontWeight: "bold",
        //letterSpacing: "2pt",
        textAlign: "left",
        lineHeight: "1.3",
        width: "20%",
        flexGrow: "0",
        flexShrink: "0",
    },
    view_report_page_3_graphs_interpretation_title_hindi: {
        fontSize: "24pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#024E85",
        margin: "0",
        //letterSpacing: "2pt",
        textAlign: "left",
        lineHeight: "1.3",
        width: "20%",
        flexGrow: "0",
        flexShrink: "0",
    },
    view_report_page_3_graphs_interpretation_item: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "0 15pt 0 0",
        // flexGrow: "1",
    },
    view_report_page_3_graphs_interpretation_item_text: {
        // width: "calc(100% - 90pt)",
        width: "100pt",
        // flexShrink: "1",
        alignSelf: "center",
        textAlign: "left",
        color: "#024E85",
        //fontFamily: "Gibson-light",
        fontWeight: "normal",
        fontSize: "10pt",
        //letterSpacing: "1pt",
        lineHeight: "1.3",
        margin: "0",
    },
    view_report_page_3_graphs_interpretation_item_text_hindi: {
        // width: "calc(100% - 90pt)",
        width: "100pt",
        // flexShrink: "1",
        alignSelf: "center",
        textAlign: "left",
        color: "#024E85",
        //fontFamily: "Gibson-light",
        fontSize: "10pt",
        fontFamily: "Noto",
        //letterSpacing: "1pt",
        lineHeight: "1.3",
        margin: "0",
    },
    view_report_page_3_graphs_interpretation_item_color_box_green: {
        width: "30pt",
        height: "30pt",
        borderRadius: "5pt",
        marginLeft: "0",
        backgroundColor: "#B5F2DB",
    },
    view_report_page_3_graphs_interpretation_item_color_box_blue: {
        width: "30pt",
        height: "30pt",
        borderRadius: "5pt",
        marginLeft: "0",
        backgroundColor: "#B5D0F2"
    },
    view_report_page_3_graphs_interpretation_item_color_box_yellow: {
        width: "30pt",
        height: "30pt",
        borderRadius: "5pt",
        marginLeft: "0",
        backgroundColor: "#F2E6B5"
    },
    view_report_page_3_graphs_interpretation_item_color_box_red: {
        width: "30pt",
        height: "30pt",
        borderRadius: "5pt",
        marginLeft: "0",
        backgroundColor: "#F2B5B5"
    },

    // ------------------------------------page 4 -----------------------------------
    view_report_page_4 : {
        padding: "50pt",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F0FFF6"
    },
    view_report_page_4_top_row: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "20pt"
    },
    view_report_page_4_top_row_h2: {
        fontSize: "22pt",
        //fontFamily: "Gibson-light",
        color: "#2C905A",
        marginBottom: "30pt",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "1.3",
        width: "100%"
    },
    view_report_page_4_top_row_h2_hindi: {
        fontSize: "22pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#2C905A",
        marginBottom: "30pt",
        textAlign: "center",
        lineHeight: "1.3",
        width: "100%"
    },
    view_report_page_4_top_row_p: {
        fontSize: "10pt",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0",
        fontWeight: "normal",
        textAlign: "center",
        lineHeight: "1.3",
    },
    view_report_page_4_top_row_p_hindi: {
        fontSize: "10pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0",
        textAlign: "center",
        lineHeight: "1.3",
    },
    view_report_page_4_top_row_p_large_text: {
        fontSize: "12pt",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        marginBottom: "30pt",
        fontWeight: "normal",
        //letterSpacing: "1pt",
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_4_top_row_p_large_text_hindi: {
        fontSize: "12pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        marginBottom: "30pt",
        //letterSpacing: "1pt",
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_4_paragraph: {
        fontSize: "12pt",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0 0 20pt",
        fontWeight: "normal",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_4_paragraph_hindi: {
        fontSize: "12pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0 0 20pt",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_4_recommendation_item: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: "20px",
    },
    view_report_page_4_recommendation_item_h2: {
        fontSize: "20pt",
        //fontFamily: "Gibson-light",
        color: "#2C905A",
        margin: "0 0 30pt",
        fontWeight: "bold",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
        width: "100%",
    },
    view_report_page_4_recommendation_item_h2_hindi: {
        fontSize: "20pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#2C905A",
        margin: "0 0 30pt",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
        width: "100%",
    },
    view_report_page_4_recommendation_subitem: {
        width: "100%",
        boxSizing: "border-box",
        padding: "0 30pt",
        display: "flex",
        flexDirection: "column",
        margin: "0 0 30pt",
    },
    view_report_page_4_recommendation_subitem_subtitle: {
        fontSize: "20pt",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0 0 20pt",
        fontWeight: "bold",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_4_recommendation_subitem_subtitle_hindi: {
        fontSize: "20pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0 0 20pt",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_4_recommendation_subitem_paragraph: {
        fontSize: "12pt",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0 0 20pt",
        fontWeight: "normal",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_4_recommendation_subitem_paragraph_hindi: {
        fontSize: "12pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0 0 20pt",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_4_recommendation_subitem_paragraph_nomargin: {
        fontSize: "12pt",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0",
        fontWeight: "normal",
        textAlign: "left",
        //letterSpacing: 1,
        lineHeight: "1.3",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    view_report_page_4_recommendation_subitem_paragraph_nomargin_hindi: {
        fontSize: "12pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0",
        textAlign: "left",
        //letterSpacing: 1,
        lineHeight: "1.3",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    view_reports_page_4_graphs: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: "auto",
        flexWrap: "wrap",
        marginBottom: "50pt",
    },
    view_reports_page_4_graph_item: {
        width: "350pt",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20pt",
    },
    view_reports_page_4_graph_text: {
        fontSize: "10pt",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0 auto 0 0",
        width: "20%",
        fontWeight: "normal",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_reports_page_4_graph_text_hindi: {
        fontSize: "10pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        margin: "0 auto 0 0",
        width: "20%",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_reports_page_4_graph_bar: {
        width: "calc(80% - 30pt)",
        height: "30pt",
        borderRadius: "10pt",
        backgroundColor: "#DEF5E7",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        overflow: "hidden",
    },
    view_reports_page_4_graph_number: {
        position: "relative",
        alignSelf: "center",
        width: "30pt",
        textAlign: "right",
        fontSize: "10pt",
        right: "10pt",
        color: "#2D905B",
        //fontFamily: "Gibson-light",
        fontWeight: "normal",
        //letterSpacing: "1pt",
        lineHeight: "1.3",
    },
    view_reports_page_4_graph_number_hindi: {
        position: "relative",
        alignSelf: "center",
        width: "30pt",
        textAlign: "right",
        fontSize: "10pt",
        fontFamily: "Noto",
        right: "10pt",
        color: "#2D905B",
        //fontFamily: "Gibson-light",
        //letterSpacing: "1pt",
        lineHeight: "1.3",
    },

    // ORANGE SECTION THAT COMES BEFORE GREEN
    view_report_page_4_orange : {
        padding: "50pt",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFF8EF"
    },
    view_report_page_4_top_row_h2_orange: {
        fontSize: "22pt",
        //fontFamily: "Gibson-light",
        color: "#A35727",
        marginBottom: "30pt",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "1.3",
        width: "100%",
    },
    view_report_page_4_top_row_h2_orange_hindi: {
        fontSize: "22pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#A35727",
        marginBottom: "30pt",
        textAlign: "center",
        lineHeight: "1.3",
        width: "100%",
    },
    view_report_page_4_recommendation_item_h2_orange: {
        fontSize: "20pt",
        //fontFamily: "Gibson-light",
        color: "#A35727",
        margin: "0 0 30pt",
        fontWeight: "bold",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
        width: "100%",
    },
    view_report_page_4_recommendation_item_h2_orange_hindi: {
        fontSize: "20pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#A35727",
        margin: "0 0 30pt",
        //letterSpacing: 1,
        textAlign: "left",
        lineHeight: "1.3",
        width: "100%",
    },

// --------------------------------PAGE 5 ------------------------------
    view_report_page_5 : {
        padding: "50pt",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F7FAFF"
    },
    view_report_page_5_top_row: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "20pt"
    },
    view_report_page_5_top_row_h2: {
        fontSize: "22pt",
        //fontFamily: "Gibson-light",
        color: "#024E85",
        marginBottom: "30pt",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "1.3",
        width: "100%"
    },
    view_report_page_5_top_row_h2_hindi: {
        fontSize: "22pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#024E85",
        marginBottom: "30pt",
        textAlign: "center",
        lineHeight: "1.3",
        width: "100%"
    },
    view_report_page_5_top_row_p_large_text: {
        fontSize: "12pt",
        color: "#2D2F31",
        marginBottom: "30pt",
        fontWeight: "normal",
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_5_top_row_p_large_text_hindi: {
        fontSize: "12pt",
        fontFamily: "Noto",
        color: "#2D2F31",
        marginBottom: "30pt",
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_5_p_note_text: {
        fontSize: "10pt",
        color: "black",
        // fontWeight: "bold",
        textAlign: "left",
        width: "100%",
        marginBottom: "20pt",
        fontStyle: "italic",
    },
    view_report_page_5_p_note_text_hindi: {
        fontSize: "10pt",
        fontFamily: "Noto",
        color: "black",
        // fontWeight: "bold",
        textAlign: "left",
        width: "100%",
        marginBottom: "20pt",
        // fontStyle: "italic",
    },
    view_report_page_5_summary_item: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: "20pt",
    },
    // view_report_page_summary_item > h2 {
    //     font-size: 1.7rem;
    //     font-family: Gibson-light, sans-serif;
    //     color: #2C905A;
    //     margin: 0 0 1.5rem;
    //     font-weight: bold;
    //     letter-spacing: 0.1rem;
    //     text-align: start;
    //     line-height: 1.3;
    //     width: 100%;
    // },
    view_report_page_5_summary_subitem: {
        width: "100%",
        boxSizing: "border-box",
        padding: "0 40pt",
        display: "flex",
        flexDirection: "column",
    },
    view_report_page_5_summary_item_subtitle: {
        fontSize: "18pt",
        color: "#2D2F31",
        marginBottom: "20pt",
        fontWeight: "bold",
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_5_summary_item_subtitle_hindi: {
        fontSize: "18pt",
        fontFamily: "Noto",
        color: "#2D2F31",
        marginBottom: "20pt",
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_5_subitem_table: {
        width: "80%",
        margin: "15pt auto 20pt",
        display: "flex",
        flexDirection: "column",
        borderBottom: "1pt solid #3b404e",
    },
    view_report_page_5_subitem_table_title_row: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        borderRight: "1pt solid #3b404e",
        borderTop: "1pt solid #3b404e",
        borderLeft: "1pt solid #3b404e",
    },
    view_report_page_5_subitem_table_title_label: {
        width: "30%",
        // height: "auto",
        // minHeight: "100%",
        color: "#2D2F31",
        padding: "10pt 20ptrem",
        borderRight: "1pt solid #3b404e",
    },
    view_report_page_5_subitem_table_title_value: {
        width: "30%",
        flexGrow: "1",
        // height: "auto",
        // minHeight: "100%",
        color: "#2D2F31",
        padding: "10pt 20pt",
    },
    view_report_page_5_subitem_table_item_row: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        borderRight: "1pt solid #3b404e",
        borderTop: "1pt solid #3b404e",
        borderLeft: "1pt solid #3b404e",
    },
    view_report_page_5_subitem_table_item_row_label: {
        width: "30%",
        padding: "10pt 20ptrem",
        borderRight: "1pt solid #3b404e",
    },
    view_report_page_5_subitem_table_item_row_value: {
        width: "30%",
        flexGrow: "1",               
        padding: "10pt 20pt",                        
    },
    view_report_page_5_subitem_table_text: {
        fontSize: "10pt",
        color: "#2D2F31",
        fontWeight: "normal",
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_5_subitem_table_text_hindi: {
        fontSize: "10pt",
        fontFamily: "Noto",
        color: "#2D2F31",
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_5_summary_subitem_career_options: {
        width: "100%",
        marginBottom: "20pt",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    view_report_page_5_summary_subitem_career_options_column: {
        width: "270pt",
        display: "flex",
        flexDirection: "column",
        marginRight: "10pt",
    },
    view_report_page_5_summary_subitem_career_options_p: {
        fontSize: "10pt",
        color: "#2D2F31",
        marginTop: "3pt",
        fontWeight: "normal",
        textAlign: "left",
        lineHeight: "1.3",
        width: "100%",
    },
    view_report_page_5_summary_subitem_career_options_p_hindi: {
        fontSize: "10pt",
        fontFamily: "Noto",
        color: "#2D2F31",
        marginTop: "3pt",
        textAlign: "left",
        lineHeight: "1.3",
        width: "100%",
    },
    view_report_page_5_summary_item_bold_p: {
        fontSize: "12pt",
        color: "#2D2F31",
        marginBottom: "20pt",
        fontWeight: "bold",
        textAlign: "left",
        lineHeight: "1.3",
    },
    view_report_page_5_summary_item_bold_p_hindi: {
        fontSize: "12pt",
        fontFamily: "Noto",
        color: "#2D2F31",
        marginBottom: "20pt",
        textAlign: "left",
        lineHeight: "1.3",
    },

// -----------------------------PAGE 6 ------------------------------
    view_report_page_6: {
        width: "100%",
        padding: "50pt",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    view_report_page_6_img: {
        width: "40%",
        height: "50%",
        objectFit: "cover",
        objectPosition: "center"
    },
    view_report_page_6_p: {
        width: "400pt",
        fontSize: "14pt",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        fontWeight: "bold",
        textAlign: "left",
        lineHeight: "2",
    },
    view_report_page_6_p_hindi: {
        width: "400pt",
        fontSize: "14pt",
        fontFamily: "Noto",
        //fontFamily: "Gibson-light",
        color: "#2D2F31",
        textAlign: "left",
        lineHeight: "2",
    }
});

export default styles;