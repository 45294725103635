import React, {useEffect, useRef, useState} from 'react';
import './Home.css';
import TextImageCarousel from "./HomeTopCarousel/HomeTopCarousel";
import { NavLink, Link, useHistory } from 'react-router-dom';
import generalIcon from '../Assets/Homepage/general-eval-icon.png';
import humanitiesIcon from '../Assets/Homepage/humanities-eval-icon.png';
import engineeringIcon from '../Assets/Homepage/engineering-eval-icon.png';
import scienceIcon from '../Assets/Homepage/science-eval-icon.png';
import commerceIcon from '../Assets/Homepage/commerce-eval-icon.png';
import seamlessProcess from '../Assets/Homepage/home-page-seamless-process.png';
import bestReports from "../Assets/Homepage/home-page-reports.png";
import {useTransition, animated} from "react-spring";
import Achievements from "./HomeAchievements/Achievements";
import TrustedBy from "./HomeTrustedBy/TrustedBy";
import { Snackbar } from '@material-ui/core';
import gql from 'graphql-tag';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';

const CHECK_USER_QUERY = gql`
query MyQuery($email: String!) {
    users_aggregate(where: {email: {_eq: $email}}) {
        aggregate {
            count
            }
        }
    }`;
  

// EVAL TEST SERIES INFORMATION ITEMS
const testSeriesData = [
    {icon: generalIcon, url:"/tests-info/#general", title: "General Eval", text: "Designed for students in Grades 8, 9, and 10 who are exploring their interests and need guidance with stream selection."},
    {icon: humanitiesIcon, url:"/tests-info/#humanities", title: "Eval for Humanities", text: "Designed for students that are currently studying arts or humanities subjects or professionals who have completed their degree in it."},
    {icon: engineeringIcon, url:"/tests-info/#engineering", title: "Eval for Engineering", text: "Designed for students that are currently studying Engineering or professionals who have completed their degree in it."},
    {icon: scienceIcon, url:"/tests-info/#science", title: "Eval for Science", text: "Designed for students that are currently studying Science or professionals who have completed their degree in it."},
    {icon: commerceIcon, url:"/tests-info/#commerce", title: "Eval for Commerce", text: "Designed for students that are currently studying Commerce or professionals who have completed their degree in it."},
];

 // Testimonial item data
const testimonialData = [
    {content: "“Eval has been used by over 200 of my students till date at Professors Academy. UEMS Ventures presented Eval to all my students during the webinar and what a wonderful experience that was. It gave a possibility to all the students to understand what Eval is about, how it can give clarity about the student’s interests and how they can use it to improve their skills by learning the right subjects. I wish UEMS  all the very best in taking EVAL to as many students.”", name: "Rajesh Kasat", spanText: "Professor's academy Director"},
    {content: "“I got to know about Eval from my friend as she had taken the test. It is a helpful medium to shortlist on what my interests are and help me in my future prospects.This is a very simple test of evaluation and easy to understand for all. Many aspects covered in different ways were helpful in evaluating self and guided me to decide on which stream I should persue my studies that would help me in my career. I would highly recommend my friends for the same. However I would like to take this test again after my 12th to be more sure about what I want to do.”", name: "Shruti Anand"},
    {content: "“I got to know about Eval from my Aunty and since I am studying in the 9th standard, I thought it would be the best time to take a test like this that would enable me to have a better understanding of what I could possibly do in the future. The Eval test was simple and easy to understand.  Many aspects were covered in Eval as a result of which all the possible career option were explored.  I was pleasantly surprised by my Eval report and will consider the given field in the future. I would recommend Eval to all the student who would like to have a better clarity on a career path they wish to take in the future.”", name: "Misha Wadhawani"},
    {content: "“I gave the Eval test wanting to know which career would be the right one for me.  Going through the test was a little lengthy but easy to understand.  They had covered all the areas of my interest and I was able to give the test without any difficulty.  The test results will surely help me prepare for the future.”", name: "Rohan Leo"},
    {content: "“I was very impressed by the Career Clarity Test... It helped my daughter Shreya to focus on a completely new industry from the one she had been thinking, before taking tests. It identified her strengths and career-learnings, which  we as parents  always knew she haed, but never realized her potential inn  terms of her career.”", name: "Shreya Bhatkar's mother"},
    {content: "“I am very impressed by the Eval Test and its interpretation. The Eval test shows precise results and such is feedback received by the clients too. The way Eval test questions are formed demonstrates that it is carefully designed by experts and is in sync with strengths and interests of the client. When I read the report for the first time I was content with the simplicity in language and clarity it holds that even  a school student can understand.”", name: "Shalaka", spanText:"Careerwiser"},
    {content: "“... the counselling was very good and clarified all our doubts. We are satisfied with the [Eval] team. Thank you!”", name: "Asmi Gawandalkar's mother"},
];

// Circular progress theme
const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#1E2432"},
    },
});


// Function that returns previous testimonial index
const usePrevTestimonial = index => {
    const ref = useRef(0);
    useEffect(() => {
        ref.current = index;
    });
    return ref.current;
};

// Testimonial components
const testimonials = testimonialData.map((data) =>
    ({style}) =>  <animated.div className="home_testimonials_content_item" style={{...style}}>
            <p className="home_testimonials_content_item_text">{data.content}</p>
            <p className="home_testimonials_content_item_name">
                {data.name}{data.spanText?<span>{", " + data.spanText}</span>:null}
            </p>
    </animated.div>
);

// Function that returns whether current value is greater than previous value in an infinite carousel of length = arrLen
const isCurrentGreater = (current, previous, arrLen) =>  {
    if ((current - previous) === 1){
        return true;
    } else if((current - previous) === -1){
        return false;
    } else {
        if(arrLen % 2 === 1){
            const half = Math.floor(arrLen/2);
            if(previous <= half){
                return (0 < (current - previous) && (current - previous) <= half);
            } else {
                return !(0 < (previous - current) && (previous - current) <= half);
            }
        } else {
            const half = arrLen / 2;
            if (previous <= half) {
                return (0 < (current - previous) && (current - previous) <= half);
            } else {
                return !(0 < (previous - current)&&(previous - current) < half);
            }
        }
    }
};



export default function Home(props) {
    const [enteredEmail1, setEnteredEmail1] = useState("");
    const [enteredEmail2, setEnteredEmail2] = useState("");

    // testimonial indices
    const [currentTestimonial, setCurrentTestimonial] = useState(0);
    const prevTestimonial = usePrevTestimonial(currentTestimonial);


    const testimonialTransitions = useTransition(currentTestimonial, p => p, {
        from: () => {
            if (isCurrentGreater(currentTestimonial, prevTestimonial, testimonialData.length)) {
                return {opacity: 0, transform: 'translate3d(0, 120% ,0)'}
            } else return {opacity: 0, transform: 'translate3d(0, -120%,0)'}
        },
        enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
        leave: () => {
            if (isCurrentGreater(currentTestimonial, prevTestimonial, testimonialData.length)) {
                return {opacity: 0, transform: 'translate3d(0,-50%,0)'}
            } else return {opacity: 0, transform: 'translate3d(0,50%,0)'}
        }
    });

    const [userLoading1, setUserLoading1] = useState(false);
    const [userLoading2, setUserLoading2] = useState(false);


    // Snackbar states
    const [snackBarOpen, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    // Snackbar close function
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const client = useApolloClient();

    const history = useHistory();

    const signUpButtonHandler = (enteredEmail, btnNumber) => {
        if(btnNumber === 1){
            setUserLoading1(true);
        }else {
            setUserLoading2(true);
        }
        if(enteredEmail.length){
            client.query({
                query: CHECK_USER_QUERY,
                variables: {
                    email: enteredEmail
                }
            }).then((res) => {
                if(res.data.users_aggregate.aggregate.count === 1){
                    setSnackbarMessage("User with this email already exists");
                    setOpen(true);
                    setUserLoading1(false);
                    setUserLoading2(false);
                } else {
                    history.push("/login/sign-up/" + enteredEmail);
                }
            }).catch(err => {
                setUserLoading1(false);
                setUserLoading2(false);
                console.log(err);
            });
        }else{
            history.push("/login/sign-up");
        }
            
        // history.push("/login/sign-up/" + enteredEmail);
    };




    return(
        <div className="Home">

            {/*----------------First 100vh of the home page------------------------*/}
            <div className="home_flex_row_1">
                <div className="home_flex_row_1_infobox_1">
                    <h2>Achieve Career</h2>
                    <h2>Clarity with <span className="blue_span">Eval</span></h2>
                    <p>A career interest assessment tool, specifically designed to help students learn about their career interests, identify areas of growth, and gain clear understanding of their ideal career path. </p>
                    {props.user? null: <div className="home_flex_row_1_signup">
                        <input onChange={(e) => setEnteredEmail1(e.target.value)}
                               value={enteredEmail1} placeholder="Enter your email ..."/>
                        
                        {userLoading1? 
                            <div className="home_page_progress_container">
                                <ThemeProvider theme={progressTheme}>
                                    <CircularProgress size={"2rem"} color="primary"/>
                                </ThemeProvider>
                            </div> :
                        <button onClick={() => signUpButtonHandler(enteredEmail1, 1)}>SIGN UP</button>}
                    </div>}
                </div>
                <TextImageCarousel/>
            </div>

            {/*------------------------Test series information links at home page----------------*/}
            <div className="home_test_series_info_textbox_mobile">
                <h2>Eval Test Series</h2>
                <p>Pick the most appropriate Eval Test for your education background and career goals.</p>
            </div>
            <div className="home_test_series_info">
                <div className="home_test_series_info_textbox_desktop">
                    <h2>Eval Test Series</h2>
                    <p>Pick the most appropriate Eval Test for your education background and career goals.</p>
                </div>
                {
                    testSeriesData.map((data, index) => {
                        return(
                            <div className="home_test_series_info_item" key={index}>
                                <img src={data.icon} alt={data.title}/>
                                <p className="home_test_series_info_item_title">{data.title}</p>
                                <p className="home_test_series_info_item_text">{data.text}</p>
                                <Link to={data.url} className="home_test_series_info_item_action-button"/>
                            </div>
                        )
                    })
                }
            </div>

            {/*--------------Why eval section----------------------- */}
            <h1 className="home_why_eval_title_main" id="why-eval">Why Eval</h1>

            {/*seamless process*/}
            <div className="home_why_eval_item-1">
                <div className="home_why_eval_item-1_imagebox">
                    <svg className="blue_circle" height="150%" width="150%">
                        <circle cx="-5%" cy="0" r="50%" stroke="black" strokeWidth="0" fill="#8AC0DE"/>
                    </svg>
                    <img src={seamlessProcess} alt="Our seamless process"/>
                </div>
                <div className="home_why_eval_item-1_textbox">
                    <h2>Our Seamless Process</h2>
                    <p>Eval uses a unique process of assessing and quantifying test takers’ interests in diverse career
                        categories and work style indicators to help identify their ideal career path. </p>
                    <Link to="/process">LEARN MORE</Link>
                </div>
            </div>

            {/*best in class reports*/}
            <div className="home_why_eval_item-2">
                <div className="home_why_eval_item-2_textbox">
                    <h2 className="home_why_eval_item-2_textbox_title">Best in class Reports</h2>
                    <p className="home_why_eval_item-2_textbox_text">Eval reports are designed to empower test takers with
                        information about their own strengths and interests, so that they can make an informed decision about their educational or professional future.</p>
                    <Link to="/reports-info">LEARN MORE</Link>
                </div>
                <div className="home_why_eval_item-2_imagebox">
                    <svg className="red_circle" height="150%" width="150%">
                        <circle cx="66%" cy="0" r="50%" stroke="black" strokeWidth="0" fill="#FEB68E"/>
                    </svg>
                    <img src={bestReports} alt="Best in class reports"/>
                </div>
            </div>

            {/*------------------- Testimonials section --------------------*/}
            <div className="home_testimonials">
                <svg className="home_testimonials_background">
                    <rect fill="#F9FAFB"/>
                    <ellipse />
                </svg>
                <div className="home_testimonials_buttons">
                    <button className="home_testimonials_buttons_button-up" onClick={() => {
                        setCurrentTestimonial((c) => {
                            return (c === 0)? (testimonialData.length - 1) : (c-1)
                        })
                    }}/>
                    <button className="home_testimonials_buttons_button-down" onClick={() => {
                        setCurrentTestimonial((c) => {
                            return (c === testimonialData.length - 1)? 0 : (c+1)
                        })
                    }}/>
                </div>
                <div className="home_testimonials_content">
                    <h2>Testimonials</h2>
                    <div className="home_testimonials_content_item_container">
                        {testimonialTransitions.map(({item, props, key}) => {
                            const Testimonial = testimonials[item];
                            return <Testimonial key={key} style={props}/>
                        })}
                    </div>
                </div>
            </div>

            {/*Achievements section*/}
            {/*<h1 className="home_achievements_title_main" id="achievements">Achievements</h1>*/}
            {/*<Achievements/>*/}

            {/*Trusted by section*/}
            <h1 className="home_trusted_by_title" id="trusted-by">Trusted by</h1>

            <TrustedBy />

            {/*Setup account*/}
            {props.user? null: <div className="home_setup_account_section">
                <h2>Set up your account and start exploring</h2>
                <input onChange={(e) => setEnteredEmail2(e.target.value)}
                       value={enteredEmail2} placeholder="Enter your email ..."/>
                {userLoading2? 
                    <div className="home_page_progress_container">
                        <ThemeProvider theme={progressTheme}>
                            <CircularProgress size={"2rem"} color="primary"/>
                        </ThemeProvider>
                    </div>: 
                    <button onClick={() => signUpButtonHandler(enteredEmail2, 2)}>SET UP</button>}
            </div>}
            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }} style={{position: "fixed"}} open={snackBarOpen} autoHideDuration={3000} 
            onClose={handleSnackbarClose} message={snackbarMessage} key="snackbar"/>
        </div>
    )
}