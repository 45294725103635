import TestOptionButton from "./TestOptionButton";
import React, {useEffect, useRef, useState} from "react";
import {formatISO} from 'date-fns';
import gql from 'graphql-tag';
import {useMutation, useLazyQuery, useQuery, useApolloClient} from "@apollo/client";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Redirect, useHistory} from "react-router";
import {Snackbar} from "@material-ui/core";
import {ReportPdfAsBlob} from '../ViewReportPage/ReportPdf';
import APP from '../services/app-service'
const GLOBAL = require('../services/global-constant');
const axios = require('axios').default;



// Called when user clicks submit after answering all questions in the normal workflow
const TEST_RESPONSE_MUTATION = gql`
    mutation InsertTestResponse($duration: timestamptz!, 
    $level: String!, 
    $location: String!, 
    $profession: String!, 
    $year: String!, 
    $purchaseId: Int!, 
    $scoreObject: [scores_insert_input!]!) {
        insert_responses_one(object: {duration: $duration, 
        onboardings: {data: {level: $level, location: $location, profession: $profession, year: $year}}, purchaseId: $purchaseId, scores: {data: $scoreObject}}) {
            id
        }
    }`;


// Called at the start of vcura workflow to check if the user already exists in our db    
const CHECK_VCURA_USER = gql`
    query CheckVcuraUser($vcuraCustId: String!) {
        users(where: {vcuraCustomerId: {_eq: $vcuraCustId}}) {
            id
        }
    }`;   


    // Does not create a vcura user, only adds a response to an existing user
const INSERT_TEST_RESPONSE_ONLY_VCURA = gql`
    mutation InsertVcuraResponse($userId: Int!, 
        $testId: Int!, 
        $duration: timestamptz!, 
        $level: String!, 
        $location: String!, 
        $profession: String!, 
        $year: String!, 
        $scoreObject: [scores_insert_input!]!) {
            insert_purchases(objects: {
                userId: $userId,
                purchaseStatusId: 2, 
                testId: $testId,
                responses: {
                    data: {
                        duration: $duration, 
                        onboardings: {
                            data: {
                                level: $level, 
                                location: $location, 
                                profession: $profession, 
                                year: $year
                            }
                        }, scores: {
                            data: $scoreObject
                        }
                    }
                },
            }) {
            affected_rows
            returning {
                responses {
                    id
                }
            }
        }
    }`;    

// Called when user clicks submit after answering all questions in the vcura workflow, creates user and adds response
const TEST_RESPONSE_VCURA_MUTATION = gql`
    mutation InsertVcuraUserAndResponse($name: String!, 
        $vcuraCustId: String!, 
        $testId: Int!, 
        $duration: timestamptz!, 
        $level: String!, 
        $location: String!, 
        $profession: String!, 
        $year: String!, 
        $scoreObject: [scores_insert_input!]!) {
            insert_users(objects: {name: $name, 
                vcuraCustomerId: $vcuraCustId, 
                purchases: {
                    data: {
                        testId: $testId, 
                        purchaseStatusId: 2, 
                        responses: {
                            data: {
                                duration: $duration, 
                                onboardings: {
                                    data: {
                                        level: $level, 
                                        location: $location, 
                                        profession: $profession, 
                                        year: $year
                                    }
                                }, 
                                scores: {
                                    data: $scoreObject
                                }
                            }
                        }
                    }
                }
            }) {
            affected_rows
            returning {
                purchases {
                    responses {
                        id
                    }
                }
            }
        }
  }`;


// Gets questions of selected test
const TEST_QUESTIONS_QUERY = gql`
query QuestionsQuery($testId: Int!, $languageId: Int!) {
    questions(where: {testId: {_eq: $testId}, isDeleted: {_eq: false}}, order_by: {priority: asc}) {
        categoryId
        question_languages(where: {languageId: {_eq: $languageId}}) {
            question
        }        
    }
}`;    

// Gets response from response id, used only to generate pdf document for vcura users
const RESPONSE_QUERY = gql`
    query PurchaseQuery($responseId: Int!) {
        responses(where: {id: {_eq: $responseId}}) {
            scores {
                score
                category {
                    id
                    name
                }
                createdAt
            }     
            purchase {
                testId
                user {
                    name
                }
            }       
            createdAt
            id
        }
    }`;

const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
});

// HARDCODED LANGUAGE IDS

const LANGUAGE_ID = [
    {name: "English", id: 1, index: 0}, 
    {name: "हिंदी", id: 3, index: 1}, 
    {name: "मराठी", id: 4, index: 2}, 
    {name: "ગુજરાતી", id: 5, index: 3},
];


export default function QuestionsSection (props){
    const [pageNumber, setPageNumber] = useState(0); // Sets current page, 0 for language selection and 1 for the actual test
    const [languageId, setLanguageId] = useState(1); // Selected language for the test, value is id from LANGUAGE_ID object

    const [testQuestionsData, setTestQuestionsData] = useState(null);
    const [testQuestionsError, setTestQuestionsError] = useState(false);
    const [testQuestionsLoading, setTestQuestionsLoading] = useState(false);

    // TODO: READD THE FOLLOWING WHEN LANGUAGES ARE READY
    //const [testQuestionsQuery, {data: testQuestionsData, error: testQuestionsError, loading: testQuestionsLoading}] = useLazyQuery(TEST_QUESTIONS_QUERY);

    useEffect(() => {
        if(pageNumber === 1){

            setTestQuestionsLoading(true);
            axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getTest, {
                testId: parseInt(props.testItem.id),
                languageId:languageId
          },APP.getToken())
          .then(function (response) {
            console.log("setTestQuestionsData response",response);
            if(!response.data.error){
                setTestQuestionsData(response.data)
                testQuestionsError(false);
                setTestQuestionsLoading(false);
            }else{
                testQuestionsError(true);
                // setSnackbarMessage(response.data.message);
                // setSnackbarOpen(true);
            }
          })
          .catch(function (error) {
            console.log("error",error);
            setTestQuestionsLoading(false);
          });


            // testQuestionsQuery({variables: {
            //     testId: props.testItem.id,
            //     languageId
            // }})
        }
    },[pageNumber, languageId]);

    console.log(testQuestionsError);
    if(pageNumber === 0){
        return(
            <div className="test_page_language_section">
                <h2>Select test language</h2>
                <div className="test_page_language_section_language_selection">
                    {LANGUAGE_ID.map((languageItem, index) => {
                        return(
                            <button key={"test_page_test_section_language_selection_item" + index} className={(languageId === languageItem.id)? "selected" : ""} onClick={() => {
                                if(languageId !== languageItem.id){
                                    setLanguageId(languageItem.id);
                                }
                            }}>{languageItem.name}</button>
                        )
                    })}
                </div>
                <button className="test_page_language_section_language_selection_action_button" onClick={() => setPageNumber(1)}>PROCEED</button>
            </div>
        )
    } else {
        if(testQuestionsData)return <ActualTest questionsData={testQuestionsData&&testQuestionsData.questions} 
        langId={LANGUAGE_ID.filter(item => item.id === parseInt(languageId))[0].index} {...props}/>
        else if(testQuestionsError) return <Redirect to="/profile" />
        else return <div className="test_page_progress_container">
            <ThemeProvider theme={progressTheme}>
                <CircularProgress size={"2.5rem"} color="primary"/>
            </ThemeProvider>
        </div>
    }

}


const ActualTest = ({questionsData, categoriesData, ...props}) => {

    // Test page states
    const [attemptedTotal, setAttemptedTotal] = useState(0); // Number of questions attempted
    // Array of objects that hold question response value and category id associated with question
    const [questionResponses, setQuestionResponses] =
        useState(questionsData.map((data) => {
            return {
                categoryId: data.categoryId,
                value: null
            }
        }));
    const [submitLoading, setSubmitLoading] = useState(false); // Boolean, shows circular progress when true, submit button when false
    const [submitToVcura, setSubmitToVcura] = useState(false);//Boolean, when true render blob generating component
    const [testResponse, setTestResponse] = useState(null); // Test response after user has from vcura or external source has given test

    // Snackbar states
    const [snackBarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const client = useApolloClient();

    // Snackbar close function
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const startTime = useRef(new Date());

    const history = useHistory();    


    // Change total each time a new response is recorded
    useEffect(() => {
        console.log('questionResponses',questionResponses);
        setAttemptedTotal(questionResponses.filter((data) =>
            data.value !== null
        ).length);
    },[questionResponses]);

    const [insertResponse] = useMutation(TEST_RESPONSE_MUTATION);
    const [insertVcuraResponse] = useMutation(INSERT_TEST_RESPONSE_ONLY_VCURA);
    const [insertVcuraUserAndResponse] = useMutation(TEST_RESPONSE_VCURA_MUTATION);



    // Test page option  button onClick Handler
    const testButtonClickHandler = (questionIndex, buttonType) => {
        let newArr = [...questionResponses];
        if(questionResponses[questionIndex].value === buttonType){
            newArr[questionIndex].value = null;
            setQuestionResponses(newArr);
        } else{
            newArr[questionIndex].value = buttonType;
            setQuestionResponses(newArr);
        }
    };

    // Test page submit button action handler. Calculating final scores under each category is done here.
    const testSubmitHandler = () => {
        // RE ADD
        if (attemptedTotal !== questionResponses.length) {
            setSnackbarMessage("Please make sure all questions are attempted");
            setSnackbarOpen(true);
            const unattemptedQuestionId = questionResponses.findIndex((data) =>
                data.value === null
            );
            const unattemptedQuestionElement = document.querySelector("#question" + unattemptedQuestionId);
            unattemptedQuestionElement.scrollIntoView({behavior: "smooth", block: "start"});
        } else{
            setSubmitLoading(true);
            const duration = new Date() - startTime.current;
            const categoryTotal = categoriesData.map((data) => {
                return {
                    id: data.id,
                    name: data.name,
                    max: 0,
                    total: 0
                }
            });
            questionResponses.forEach((questionItem) => {
                const categoryIndex = categoryTotal.findIndex((categoryItem) => categoryItem.id === questionItem.categoryId);
                categoryTotal[categoryIndex].max += 3;
                categoryTotal[categoryIndex].total += questionItem.value;
            });

            //----------out from the mail submit old------
            const categoryFinalScores = categoryTotal.map((data) => {
                return {
                    id: data.id,
                    name: data.name,
                    score: Math.round((data.total*100)/data.max)
                }
            }); 

            //MAIN EVAL WORKLFLOW
            console.log(categoryFinalScores.map((item) => {
                return {
                    categoryId: item.id,
                    score: item.score,
                }
            }));

            console.log('all',
            formatISO(duration),
            props.onboardingData.studyInfo,
            props.onboardingData.location,
            props.onboardingData.profession,
            props.onboardingData.yearOfStudy,
            props.purchaseId,
            categoryFinalScores.map((item) => {
                        return {
                            categoryId: item.id,
                            score: item.score,
                        }
                    })
            )

            axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.setTest, {
                    duration: formatISO(duration),
                    level: props.onboardingData.studyInfo,
                    location: props.onboardingData.location,
                    profession: props.onboardingData.profession,
                    year: props.onboardingData.yearOfStudy,
                    purchaseId: props.purchaseId,
                    scoreObject: categoryFinalScores.map((item) => {
                        return {
                            categoryId: item.id,
                            score: item.score,
                        }
                    })
            },APP.getToken())
            .then(function (response) {
                console.log("setTest response",response);
                if(!response.data.error){
                    const responseId = response.data.responseId;
                    history.push("/view-report/" + responseId);
                }else{
                    history.push("/profile");
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push("/profile");
            });

            //----------out from the mail submit old----END--

            // WORKFLOW WHEN THE USER COMES IN FROM VCURA
            // if (props.externalStates&&props.externalStates.vcura){                
            //     client.query({
            //         query: CHECK_VCURA_USER,
            //         variables: {
            //             vcuraCustId: props.externalStates.vcura.userId
            //         }
            //     }).then(userCheckResponse => {
            //         if(userCheckResponse.data.users.length){
            //             insertVcuraResponse({
            //                 variables: {
            //                     userId: userCheckResponse.data.users[0].id,
            //                     testId: props.testItem.id,
            //                     duration: formatISO(duration),
            //                     level: props.onboardingData.studyInfo,
            //                     location: props.onboardingData.location,
            //                     profession: props.onboardingData.profession,
            //                     year: props.onboardingData.yearOfStudy,
            //                     scoreObject: categoryFinalScores.map((item) => {
            //                         return {
            //                             categoryId: item.id,
            //                             score: item.score,
            //                         }
            //                     })
            //                 }
            //             }).then(res => {
            //                 console.log(res); 
            //                 client.query({
            //                     query: RESPONSE_QUERY,
            //                     variables: {
            //                         responseId: res.data.insert_purchases.returning[0].responses[0].id 
            //                     }
            //                 }).then(testResponse => {
            //                     console.log(testResponse);   
            //                     setTestResponse(testResponse);
            //                     setSubmitToVcura(true);                                           
            //                 }).catch(testError => {
            //                     console.log(testError);
            //                 });
            //             }).catch(err => {
            //                 console.log(err);
            //                 history.push("/profile");
            //             });
            //         }else{
            //             insertVcuraUserAndResponse({
            //                 variables: {
            //                     name: props.externalStates.vcura.profileName,
            //                     vcuraCustId: props.externalStates.vcura.userId,
            //                     testId: props.testItem.id,
            //                     duration: formatISO(duration),
            //                     level: props.onboardingData.studyInfo,
            //                     location: props.onboardingData.location,
            //                     profession: props.onboardingData.profession,
            //                     year: props.onboardingData.yearOfStudy,
            //                     scoreObject: categoryFinalScores.map((item) => {
            //                         return {
            //                             categoryId: item.id,
            //                             score: item.score,
            //                         }
            //                     })
            //                 }
            //             }).then(res => {
            //                 console.log(res); 
            //                 client.query({
            //                     query: RESPONSE_QUERY,
            //                     variables: {
            //                         responseId: res.data.insert_users.returning[0].purchases[0].responses[0].id 
            //                     }
            //                 }).then(testResponse => {
            //                     console.log(testResponse);   
            //                     setTestResponse(testResponse);
            //                     setSubmitToVcura(true);                                           
            //                 }).catch(testError => {
            //                     console.log(testError);
            //                 });
            //             }).catch(err => {
            //                 console.log(err);
            //                 history.push("/profile");
            //             });

            //         }
            //     })
            //     //----------------------\END OF VCURA WORKFLOW ------------------------------                
            // } else {
                // //MAIN EVAL WORKLFLOW
                // console.log(categoryFinalScores.map((item) => {
                //     return {
                //         categoryId: item.id,
                //         score: item.score,
                //     }
                // }));
                // insertResponse({
                //     variables: {
                //         duration: formatISO(duration),
                //         level: props.onboardingData.studyInfo,
                //         location: props.onboardingData.location,
                //         profession: props.onboardingData.profession,
                //         year: props.onboardingData.yearOfStudy,
                //         purchaseId: props.purchaseId,
                //         scoreObject: categoryFinalScores.map((item) => {
                //             return {
                //                 categoryId: item.id,
                //                 score: item.score,
                //             }
                //         })
                //     }
                // }).then(res => {
                //     console.log(res);
                //     const responseId = res.data.insert_responses_one.id;
                //     history.push("/view-report/" + responseId);
                // }).catch(err => {
                //     console.log(err);
                //     history.push("/profile");
                // });
            //}
        } 
    };
    if(submitToVcura){
        return <ReportPdfAsBlob responseData={testResponse&&testResponse.data} 
        name={props.externalStates.vcura.profileName}
        langId={props.langId}
        fileName={props.externalStates.vcura.assessmentName? (props.externalStates.vcura.assessmentName + ".pdf"): "EvalReport.pdf"}
        userCareerCounsellingId={props.externalStates.vcura&&props.externalStates.vcura.userCareerCounsellingId}/>;
    } else {
        return (
            [<div className="test_page_test_section" key={"test_page_test_section"}>
                <p>Remember to read each question carefully and understand what it is asking before selecting an answer.</p>
                <p>Please attempt the test keeping in mind that it's a Career Clarity test.</p>
                <p>We hope you have fun as you learn more about yourself through the test!</p>
                {questionsData.map((data, index, arr) => {
                    const length = arr.length;
                    const qNumber = (index + 1).toString().padStart(length.toString().length, '0');
                    return(<div className="test_page_question_item" key={"test-item" + index} id={"question" + index}>
                        <p className="test_page_question_item_number">{qNumber + " / " + length}</p>
                        <h2>{data.question_languages[0].question}</h2>
                        <div className="test_page_question_item_options_row">
                            <div className="test_page_question_item_options_row_item">
                                <TestOptionButton type={3} selected={questionResponses[index].value === 3}
                                                  onClick={() => testButtonClickHandler(index, 3)}/>
                                <p className={"test_page_question_item_subtitle" + ((questionResponses[index].value === 3)? " selected" : "")}>
                                    Very Interested</p>
                            </div>
                            <div className="test_page_question_item_options_row_item">
                                <TestOptionButton type={2} selected={questionResponses[index].value === 2}
                                                  onClick={() => testButtonClickHandler(index, 2)}/>
                                <p className={"test_page_question_item_subtitle" + ((questionResponses[index].value === 2)? " selected" : "")}>
                                    Interested</p>
                            </div>
                            <div className="test_page_question_item_options_row_item">
                                <TestOptionButton type={1} selected={questionResponses[index].value === 1}
                                                  onClick={() => testButtonClickHandler(index, 1)}/>
                                <p className={"test_page_question_item_subtitle" + ((questionResponses[index].value === 1)? " selected" : "")}>
                                    Slightly Interested</p>
                            </div>
                            <div className="test_page_question_item_options_row_item">
                                <TestOptionButton type={0} selected={questionResponses[index].value === 0}
                                                  onClick={() => testButtonClickHandler(index, 0)}/>
                                <p className={"test_page_question_item_subtitle" + ((questionResponses[index].value === 0)? " selected" : "")}>
                                    Not Interested</p>
                            </div>
                        </div>
                    </div>)
                })}
                <div className="test_page_test_section_dash">
                    <div className="test_page_test_section_summary">
                        <img src={props.testItem.icon} alt="Eval logo"/>
                        <h2>{props.testItem.name}</h2>
                        <p>Questions Attempted</p>
                        <p className="total">
                            <span>{attemptedTotal? attemptedTotal.toString().padStart(questionResponses.length.toString().length, '0'):
                                attemptedTotal.toString()}</span>
                            {"/" + questionResponses.length}
                        </p>
                    </div>
                    {submitLoading? <div className="questions_page_progress_container">
                        <ThemeProvider theme={progressTheme}>
                            <CircularProgress size={56} color="primary"/>
                        </ThemeProvider>
                    </div> :
                        <button onClick={() => testSubmitHandler()}>Submit</button>}
                </div>
            </div>,
                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }} open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} message={snackbarMessage} key="snackbar"/>]
        )
    }    
}