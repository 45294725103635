import React, {useEffect, useState} from 'react';
import './ResetPassword.css';
import {Route, Switch, Redirect, useLocation, useHistory, useParams, useRouteMatch} from "react-router";
import { Link } from 'react-router-dom';
import {useTransition, animated} from 'react-spring';
import firebase from '../Firebase/Firebase';
import {Snackbar} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import {useMutation} from "@apollo/client";
import gql from 'graphql-tag';
import APP from '../services/app-service'
const GLOBAL = require('../services/global-constant');
const axios = require('axios').default;

const datePickerTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
    overrides: {
       MuiInput: {
           input: {
               fontFamily: "Gibson-light, sans-serif",
               color: "black",
           }
       },
   }
});

const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
});

const selectTheme = createMuiTheme({
    overrides: {
        MuiSelect: {
            select: {
                "&:focus": {
                    backgroundColor: "none",
                }
            },
            selectMenu: {
                backgroundColor: "#F1F1F1"
            }
        }
    }
});

export default function ResetPassword(){

    const {token} = useParams();

    // Sign in card input states
    const [email1, setEmail1] = useState("");
    const [password1, setPassword1] = useState("");
    const [signInLoading, setSignInLoading] = useState(false);

    // Sign up card input states
    const [name, setName] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [email2, setEmail2] = useState("");
    const [gender, setGender] = useState("");
    const [password2, setPassword2]  = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [reference, setReference] = useState("");
    const [signUpLoading, setSignUpLoading] = useState(false);

    // Forgot password input states
    const [email3, setEmail3] = useState("");
    const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);


    // Snackbar states
    const [snackBarOpen, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    // Snackbar close function
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const history = useHistory();
    const emailMatch = useRouteMatch("/login/sign-up/:email");
    useEffect(()=> {
        setEmail2(emailMatch && emailMatch.params.email);
    },[]);



    // Transition based on location to make login pages look like one seamless page
    const location = useLocation();
    const transitions = useTransition(location, location => location.pathname, {
        initial: {  transform: 'translate3d(0%,0,0)' },
        from: { transform: 'translate3d(100%,0,0)' },
        enter: {  transform: 'translate3d(0%,0,0)' },
        leave: {  transform: 'translate3d(-100%,0,0)' },
        config: { unique : true},
    });


    // Sign in function
    const signInHandler = () => {

        if(!password2){
            setSnackbarMessage("Password cannot be empty");
            setOpen(true);
            setSignUpLoading(false);
            return;
        }else if(password2.length < 6){
            setSnackbarMessage("Password should be atleast 6 characters");
            setOpen(true);
            setSignUpLoading(false);
            return;
        }else if(confirmPassword !== password2){
            setSnackbarMessage("Passwords don't match");
            setOpen(true);
            setSignUpLoading(false);
            return;
        }

        

        setSignInLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.setResetPassword, {
                password: password2,
                token:token
        })
        .then(function (response) {
            if(!response.data.error){
                setSnackbarMessage("Password reset Successfully!");
                setOpen(true);
                history.push('/login');
            }else{
                setSnackbarMessage(response.data.message);
                setOpen(true);
            }
        })
        .catch(function (error) {
            console.log("error",error);
        });
        setSignInLoading(false);

    };

    // ---------------------- JSX ----------------------
    return [transitions.map(({ item: location, props, key}) => (
        <animated.div className="login_page mui-fixed" key={key} style={props}>
            <div className="login_page_content">
                <div className="sign-in_card">
                    <h2> Reset Password</h2>
                    <div className="login_page_input_item">
                        <p>ENTER NEW PASSWORD</p>
                        <input type="password"  value={password2} onKeyDown={event => {
                            if(event.key==="Enter"){
                                event.preventDefault();
                                signInHandler();
                            }
                        }} onChange={(e) => {
                            setPassword2(e.target.value)
                        }}/>
                    </div>
                    <div className="login_page_input_item">
                        <p>CONFIRM NEW PASSWORD</p>
                        <input type="password" value={confirmPassword} onKeyDown={event => {
                            if(event.key==="Enter"){
                                event.preventDefault();
                                signInHandler();
                            }
                        }} onChange={(e) => setConfirmPassword(e.target.value)}/>
                    </div>
                    {signInLoading?
                        <div className="login_page_progress_container">
                            <ThemeProvider theme={progressTheme}>
                                <CircularProgress size={30} color="primary"/>
                            </ThemeProvider>
                        </div>
                        : <button className="login_page_button_1" onClick={() => signInHandler()}>SUBMIT</button>}
                </div>
            </div>
        </animated.div>
    )), <Snackbar anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
    }} open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} message={snackbarMessage} key="snackbar"/>]

}

