import React, {useEffect, useState} from 'react';
import './Login.css';
import {Route, Switch, Redirect, useLocation, useHistory, useParams, useRouteMatch} from "react-router";
import { Link } from 'react-router-dom';
import {useTransition, animated} from 'react-spring';
import firebase from '../Firebase/Firebase';
import {Snackbar} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import {useMutation} from "@apollo/client";
import gql from 'graphql-tag';
import APP from '../services/app-service'
const GLOBAL = require('../services/global-constant');
const axios = require('axios').default;

const USER_MUTATION = gql`
    mutation MyMutation($DOB: Date!, 
        $email: String!, 
        $name: String!, 
        $password: String!, 
        $phone: String!, 
        $reference: String!, 
        $sex: String!) {
            signUp(input: {DOB: $DOB, 
            email: $email, 
            name: $name, 
            password: $password, 
            phoneNumber: $phone, 
            reference: $reference, 
            sex: $sex}){
                id
            }
        }
`;


const datePickerTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
    overrides: {
       MuiInput: {
           input: {
               fontFamily: "Gibson-light, sans-serif",
               color: "black",
           }
       },
   }
});

const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
});

const selectTheme = createMuiTheme({
    overrides: {
        MuiSelect: {
            select: {
                "&:focus": {
                    backgroundColor: "none",
                }
            },
            selectMenu: {
                backgroundColor: "#F1F1F1"
            }
        }
    }
});

export default function Login(){
    // Sign in card input states
    const [email1, setEmail1] = useState("");
    const [password1, setPassword1] = useState("");
    const [signInLoading, setSignInLoading] = useState(false);

    // Sign up card input states
    const [name, setName] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [email2, setEmail2] = useState("");
    const [gender, setGender] = useState("");
    const [password2, setPassword2]  = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [reference, setReference] = useState("");
    const [signUpLoading, setSignUpLoading] = useState(false);

    // Forgot password input states
    const [email3, setEmail3] = useState("");
    const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);


    // Snackbar states
    const [snackBarOpen, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    // Snackbar close function
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    // Apollo mutation for user insertion
    const [createUser] = useMutation(USER_MUTATION);

    const history = useHistory();
    const emailMatch = useRouteMatch("/login/sign-up/:email");
    useEffect(()=> {
        setEmail2(emailMatch && emailMatch.params.email);
    },[]);



    // Transition based on location to make login pages look like one seamless page
    const location = useLocation();
    const transitions = useTransition(location, location => location.pathname, {
        initial: {  transform: 'translate3d(0%,0,0)' },
        from: { transform: 'translate3d(100%,0,0)' },
        enter: {  transform: 'translate3d(0%,0,0)' },
        leave: {  transform: 'translate3d(-100%,0,0)' },
        config: { unique : true},
    });

    // ----------------- Validation and snackbar activation function(s) ------------------------
    // Sign up function
    const signupHandler =  () => {
        setSignUpLoading(true);
        if(!name){
            setSnackbarMessage("Name is required");
            setOpen(true);
            setSignUpLoading(false);
        }else if(!selectedDate){
            setSnackbarMessage("Date of birth is required");
            setOpen(true);
            setSignUpLoading(false);
        }else if(!email2){
            setSnackbarMessage("Email is required");
            setOpen(true);
            setSignUpLoading(false);
        }else if(!gender){
            setSnackbarMessage("Gender is required");
            setOpen(true);
            setSignUpLoading(false);
        }else if(!password2){
            setSnackbarMessage("Password cannot be empty");
            setOpen(true);
            setSignUpLoading(false);
        }else if(password2.length < 6){
            setSnackbarMessage("Password should be atleast 6 characters");
            setOpen(true);
            setSignUpLoading(false);
        }else if(confirmPassword !== password2){
            setSnackbarMessage("Passwords don't match");
            setOpen(true);
            setSignUpLoading(false);
        }else if(!phone){
            setSnackbarMessage("Phone number is required");
            setOpen(true);
            setSignUpLoading(false);
        }else if(/([a-z]|[A-Z])/.test(phone)){
            setSnackbarMessage("Phone number is invalid");
            setOpen(true);
            setSignUpLoading(false);
        }else if(!reference){
            setSnackbarMessage("Please tell us how you heard about eval");
            setOpen(true);
            setSignUpLoading(false);
        }else{
            // TODO: FOLLOWING MANUAL PHONE VALIDATION HARDCODES REQUIREMENT FOR INDIAN NUMBERS
            // USE SAME LOGIC IN PROFILE PAGE EDIT USER INFO SECTION FOR PHONE NUMBER VALIDATION
            let newPhone = "";
            if(phone.length === 10){
                newPhone = "+91" + phone;
            } else if((phone.length === 11 && phone.substr(0,1) !== "+")||(phone.length === 12 && phone.substr(0,1) !== "+")){
                newPhone = "+" + phone;
            } else if(phone.length === 13 || (phone.length === 12 && phone.substr(0,1) === "+")){
                newPhone = phone;
            } else {
                setSnackbarMessage("Invalid phone number, please enter your number without any dashes or special symbols");
                setOpen(true);
                setSignUpLoading(false);
                return null;
            }
    
            let sendDate = APP.getDateFormat(selectedDate);

            axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.register, {
                    name: name,
                    DOB: sendDate,
                    email: email2.toLowerCase().trim(),
                    password: password2,
                    password_confirmation:password2,
                    phoneNumber: newPhone,
                    sex: gender,
                    reference: reference
              })
              .then(function (response) {
                console.log("register response",response);
                if(!response.data.error){
                    APP.removeRouteComponentData(GLOBAL.STORAGE.ls_token);
                    APP.storeRouteComponentData(GLOBAL.STORAGE.ls_token,response.data.token);
                    history.push('/profile');
                }else{
                    setSnackbarMessage(response.data.message);
                    setOpen(true);
                    setSignUpLoading(false);
                }
              })
              .catch(function (error) {
                console.log("error",error);
              });

            //--------old register api
            /*
            createUser({
                variables: {
                    name: name,
                    DOB: selectedDate,
                    email: email2.toLowerCase().trim(),
                    password: password2,
                    phone: newPhone,
                    sex: gender,
                    reference: reference
                }
            }).then((response) => {
                console.log(response);
                firebase.auth().signInWithEmailAndPassword(email2, password2).then(res => {
                    console.log("signInWithEmailAndPassword",res)
                    firebase.auth().currentUser.sendEmailVerification().then(res => {
                        console.log(res);
                        history.push('/profile');
                    })              
                }).catch(err => {
                    console.log(err);
                });
            }).catch((err) => {
                const errorMessage = err.message;
                console.log('errorMessage',errorMessage);
                if(errorMessage.search("email address is improperly formatted")!== -1){
                    setSnackbarMessage("Email is invalid");
                    setOpen(true);
                }else if(errorMessage.search("email address is already in use by another account")!== -1){
                    setSnackbarMessage("Email address is already in use by another account");
                    setOpen(true);
                }else if(errorMessage.search("phone number already exists") !== -1){
                    setSnackbarMessage("Entered phone number is already in use by another account");
                    setOpen(true);
                }else {
                    setSnackbarMessage("Something went wrong");
                    setOpen(true);
                }
                setSignUpLoading(false);
            })
            */
            
        }
    };


    // Sign in function
    const signInHandler = () => {
        setSignInLoading(true);


        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.login, {
            email: email1,
            password: password1
      })
      .then(function (response) {
        console.log("login response",response);
        if(!response.data.error){
            APP.removeRouteComponentData(GLOBAL.STORAGE.ls_token);
            APP.storeRouteComponentData(GLOBAL.STORAGE.ls_token,response.data.token);
            history.push('/profile');
        }else{
            setSnackbarMessage(response.data.message);
            setOpen(true);
            if(response.data.message=="Please reset your password"){
                history.push('/login/forgot-password');
            }
        }
      })
      .catch(function (error) {
        console.log("error",error);
      });

      setSignInLoading(false)

        // firebase.auth().signInWithEmailAndPassword(email1, password1).then((response) => {
        // }).catch((err) => {
        //     const errorMessage = err.message;
        //     // TODO: REMOVE CONSOLE LOGS IN PRODUCTION
        //     console.log(errorMessage);
        //     if(err.code === "auth/invalid-email"){
        //         setSnackbarMessage("Email address is invalid");
        //         setOpen(true);
        //     }else if(err.code === "auth/user-not-found"){
        //         setSnackbarMessage("User with this email does not exist");
        //         setOpen(true);
        //     }else if(err.code === "auth/wrong-password"){
        //         setSnackbarMessage("Incorrect password");
        //         setOpen(true);
        //     } else{
        //         setSnackbarMessage("Something went wrong");
        //         setOpen(true);
        //     }
        //     setSignInLoading(false);
        // })
    };

    // Forgot password function
    const forgotPasswordHandler = () => {

        setForgotPasswordLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.resetPasswordMailSend, {
            email: email3
        })
        .then(function (response) {
            if(!response.data.error){
                setSnackbarMessage("A password reset link has been sent to your email");
                setOpen(true);
                setForgotPasswordLoading(false);
            }else{
                setSnackbarMessage(response.data.message);
                setOpen(true);
            }
            setForgotPasswordLoading(false);
        })
        .catch(function (error) {
            console.log("error",error);
        });


        // setForgotPasswordLoading(true);
        // firebase.auth().sendPasswordResetEmail(email3).then(res => {
        //     console.log(res);
        //     setSnackbarMessage("A password reset link has been sent to your email");
        //     setOpen(true);
        //     setForgotPasswordLoading(false);
        // }).catch(err => {
        //     console.log(err.code);
        //     if(err.code === "auth/invalid-email"){
        //         setSnackbarMessage("Invalid email");
        //         setOpen(true);
        //     } else if(err.code === "auth/user-not-found"){
        //         setSnackbarMessage("User with this email does not exist");
        //         setOpen(true);
        //     }
        //     setForgotPasswordLoading(false);
        // });
    }


    // ---------------------- JSX ----------------------
    return [transitions.map(({ item: location, props, key}) => (
        <animated.div className="login_page mui-fixed" key={key} style={props}>
            <div className="login_page_content">
                <Switch location={location}>
                    {/*---------------  sign in card -----------------------*/}
                    <Route path="/login" exact >
                        <div className="sign-in_card">
                            <h2> Sign In</h2>
                            <div className="login_page_input_item">
                                <p>EMAIL</p>
                                <input value={email1} onKeyDown={event => {
                                    if(event.key==="Enter"){
                                        event.preventDefault();
                                        signInHandler();
                                    }
                                }} onChange={(e) => {
                                    setEmail1(e.target.value)
                                }}/>
                            </div>
                            <div className="login_page_input_item">
                                <p>PASSWORD</p>
                                <input type="password" value={password1} onKeyDown={event => {
                                    if(event.key==="Enter"){
                                        event.preventDefault();
                                        signInHandler();
                                    }
                                }} onChange={(e) => setPassword1(e.target.value)}/>
                            </div>
                            {signInLoading?
                                <div className="login_page_progress_container">
                                    <ThemeProvider theme={progressTheme}>
                                        <CircularProgress size={30} color="primary"/>
                                    </ThemeProvider>
                                </div>
                                : <button className="login_page_button_1" onClick={() => signInHandler()}>SIGN IN</button>}
                            <div className="login_page_divider">
                                <span />
                                <div className="login_page_divider_textbox">
                                    <p>OR</p>
                                </div>
                            </div>
                            <Link to="/login/sign-up">
                                <button className="login_page_button_2">CREATE AN ACCOUNT</button>
                            </Link>
                            <Link to="/login/forgot-password" className="login_page_link_text">FORGOT YOUR PASSWORD?</Link>
                        </div>
                    </Route>

                    {/*-------------------- sign up card -------------------------*/}
                    <Route path="/login/sign-up" >
                        <div className="sign-up_card">
                            <h2> Sign Up</h2>
                            <div className="login_page_input_rows">
                                <div className="login_page_input_item">
                                    <p>NAME</p>
                                    <input value={name} onChange={(e) => setName(e.target.value)}/>
                                </div>
                                <div className="login_page_input_item">
                                    <p>DATE OF BIRTH</p>
                                    <div>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <ThemeProvider theme={datePickerTheme}>
                                                <DatePicker
                                                    InputProps={{disableUnderline: true}}
                                                    fullWidth={true}
                                                    maxDate={new Date()}
                                                    format="dd/MM/yyyy"
                                                    value={selectedDate} onChange={setSelectedDate} variant={"inline"} />
                                            </ThemeProvider>
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div className="login_page_input_item">
                                    <p>EMAIL</p>
                                    <input value={email2} onChange={(e) => setEmail2(e.target.value)}/>
                                </div>
                                <div className="login_page_input_item">
                                    <p>GENDER</p>
                                    <div>
                                        <ThemeProvider theme={selectTheme}>
                                            <Select
                                                native
                                                value={gender}
                                                disableUnderline
                                                fullWidth
                                                onChange={(e) => setGender(e.target.value)}>
                                                <option aria-label="None" value="" />
                                                <option value={"Male"}>Male</option>
                                                <option value={"Female"}>Female</option>
                                                <option value={"Other"}>Other</option>
                                                <option value={"Rather not say"}>Rather not say</option>
                                            </Select>
                                        </ThemeProvider>
                                    </div>
                                </div>
                                <div className="login_page_input_item">
                                    <p>PASSWORD</p>
                                    <input type="password" value={password2} onChange={(e) => setPassword2(e.target.value)}/>
                                </div>
                                <div className="login_page_input_item">
                                    <p>CONFIRM PASSWORD</p>
                                    <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                                </div>
                                <div className="login_page_input_item">
                                    <p>PHONE NUMBER</p>
                                    <input value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                </div>
                                <div className="login_page_input_item">
                                    <p>HOW DID YOU HEAR ABOUT EVAL</p>
                                    <input value={reference} onChange={(e) => setReference(e.target.value)}/>
                                </div>
                            </div>
                            {signUpLoading?
                                <div className="login_page_progress_container">
                                    <ThemeProvider theme={progressTheme}>
                                        <CircularProgress size={30} color="primary"/>
                                    </ThemeProvider>
                                </div>
                                : <button className="login_page_button_1" onClick={()=> signupHandler()}>CREATE ACCOUNT</button>}
                            <p className="login_page_link_text">HAVE AN ACCOUNT? <Link to="/login/sign-in">SIGN IN</Link></p>
                        </div>
                    </Route>

                    {/*--------- forgot password card -------------------*/}

                    <Route path="/login/forgot-password" >
                        <div className="forgot_password_card">
                            <h2>Forgot Password</h2>
                            <div className="login_page_input_item">
                                <p>ENTER YOUR EMAIL</p>
                                <input value={email3} onChange={(e) => setEmail3(e.target.value)}/>
                            </div>
                            {forgotPasswordLoading? 
                                <div className="login_page_progress_container">
                                    <ThemeProvider theme={progressTheme}>
                                        <CircularProgress size={30} color="primary"/>
                                    </ThemeProvider>
                                </div>: 
                                <button className="login_page_button_1" disabled={!email3.length} 
                                onClick={forgotPasswordHandler}>SEND RESET LINK</button>}
                            <div className="login_page_divider">
                                <span />
                                <div className="login_page_divider_textbox">
                                    <p>OR</p>
                                </div>
                            </div>
                            <Link to="/login/sign-in">
                                <button className="login_page_button_2" >SIGN IN INSTEAD</button>
                            </Link>
                        </div>
                    </Route>
                    <Redirect to="/login" />
                </Switch>
            </div>
        </animated.div>
    )), <Snackbar anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
    }} open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} message={snackbarMessage} key="snackbar"/>]

}

