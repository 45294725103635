import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import ScrollToTop from "./ScrollToTop";
// import {InMemoryCache} from "apollo-cache-inmemory";
// import {createHttpLink} from "apollo-link-http";
// import {ApolloProvider} from '@apollo/react-hooks'
// import { setContext } from 'apollo-link-context';
// import ApolloClient from "apollo-boost";
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import firebase from './Firebase/Firebase';

// firebase.auth().signOut().then(()=>console.log("signed out"));
// New Firebase async function for getting JWT token from firebase auth.
const getToken = () => {
    return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
                resolve("");
            } else {
                // const token = user
                // 	? await user.getIdToken(/* forceRefresh */ true)
                // 	: "";
                // console.log("token ======>", token);
                // resolve(token);

                const token = await user.getIdToken();
                const idTokenResult = await user.getIdTokenResult();
                const hasuraClaim =
                    idTokenResult.claims["https://hasura.io/jwt/claims"];

                if (hasuraClaim) {
                    resolve(token);
                } else {
                    // Check if refresh is required.
                    const metadataRef = firebase
                        .database()
                        .ref("metadata/" + user.uid + "/refreshTime");

                    metadataRef.on("value", async (data) => {
                        if (!data.exists) return;
                        // Force refresh to pick up the latest custom claims changes.
                        const token = await user.getIdToken(true);
                        resolve(token);
                    });
                }
            }
        });
    });
};


// Changing setContext to async and awaiting the token from firebase if user exist
// const authLink = setContext(async (_, { headers }) => {
    // const token = await getToken();
//
//     return {
//         headers: {
//             ...headers,
//             "x-hasura-admin-secret": "Evaltest123$"
//             // Authorization: {
//             //     'x-hasura-admin-secret': "Evaltest123$"
//             // }
//         },
//     };
    // return token
    //     ? {
    //         headers: {
    //             ...headers,
    //             Authorization: `Bearer ${token}`,
    //         },
    //     }
    //     : {
    //         headers: {
    //             ...headers,
    //             'x-hasura-admin-secret': "Evaltest123$"
    //         },
    //     };
// });

// const client = new ApolloClient({
//     cache: cache,
//     // uri: 'https://evalcareertest.herokuapp.com/v1/graphql'
//     link: authLink.concat(httpLink),
// });

// const uri = "https://evalcareertest.herokuapp.com/v1/graphql";
const uri = "https://hasura.evaltest.com/v1/graphql";

const httpLink = createHttpLink({
    uri,
});

const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();
     return token
        ? {
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
            },
        }
        : {
            headers: {
                ...headers,
                // "x-hasura-admin-secret": "Evaltest@123"                
            },
        };
});

const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "network-only",
        },
        query: {
            fetchPolicy: "network-only",
        },
    },
});


ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <ScrollToTop/>
          <ApolloProvider client={client}>
              <App />
          </ApolloProvider>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
