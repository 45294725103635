import React, {useState} from 'react';
import './Process.css';
import LandingImage from '../Assets/ProcessPage/seamless.png';
import QuestionsAndAnswers from '../Assets/ProcessPage/q&a.png';
import CareerCategories from '../Assets/ProcessPage/career-categories.png';
import InterestScore from '../Assets/ProcessPage/final-interest.png';
import CareerOptions from '../Assets/ProcessPage/career-options.png'
import {useHistory} from "react-router";
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Snackbar } from '@material-ui/core';

const CHECK_USER_QUERY = gql`
query MyQuery($email: String!) {
    users_aggregate(where: {email: {_eq: $email}}) {
        aggregate {
            count
            }
        }
    }`;

// Circular progress theme
const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#1E2432"},
    },
});


export default function Process (props) {
    const [enteredEmail, setEnteredEmail] = useState("");
    
    const [userLoading, setUserLoading] = useState(false);


    // Snackbar states
    const [snackBarOpen, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    // Snackbar close function
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const client = useApolloClient();

    const history = useHistory();

    const signUpButtonHandler = (enteredEmail) => {
        setUserLoading(true);
        if(enteredEmail.length){
            client.query({
                query: CHECK_USER_QUERY,
                variables: {
                    email: enteredEmail
                }
            }).then((res) => {
                if(res.data.users_aggregate.aggregate.count === 1){
                    setSnackbarMessage("User with this email already exists");
                    setOpen(true);
                    setUserLoading(false);            
                } else {
                    history.push("/login/sign-up/" + enteredEmail);
                }
            }).catch(err => {
                setUserLoading(false);                
                console.log(err);
            });
        }else{
            history.push("/login/sign-up");
        }        
    };




    return(
        <div className="process_page">

            {/*Top 100vh of process page*/}
            <div className="process_page_top_row">
                <h2>Our Seamless Test <span>Process</span></h2>
                <img src={LandingImage} alt="placeholder"/>
            </div>


            {/*Items*/}
            <div className="process_page_item_blue">
                <div className="process_page_item_imagebox">
                    <img src={QuestionsAndAnswers} alt="placeholder"/>
                </div>
                <div className="process_page_item_contentbox">
                    {/*<p className="process_page_item_numbered-title">1. Lorem Ipsum</p>*/}
                    <h2>Professional Task Based Questions</h2>
                    <p className="process_page_item_text">Each test asks you upto 60 questions to identify your interest level
                        in various tasks that professionals are required to do in diverse career fields. Your answers reveal
                        the career field most suitable for you. </p>
                </div>
            </div>

            <div className="process_page_item_red">
                <div className="process_page_item_imagebox">
                    <img src={CareerCategories} alt="placeholder"/>
                </div>
                <div className="process_page_item_contentbox">
                    {/*<p className="process_page_item_numbered-title">2. Lorem Ipsum</p>*/}
                    <h2>Diverse Career Categories</h2>
                    <p className="process_page_item_text">Your interest is evaluated in upto 17 diverse career categories and 4
                        work style indicators so that you have a clear understanding of the variety of career options you
                        have and your level of interest in all of them. </p>
                </div>
            </div>

            <div className="process_page_item_blue">
                <div className="process_page_item_imagebox">
                    <img src={InterestScore} alt="placeholder"/>
                </div>
                <div className="process_page_item_contentbox">
                    {/*<p className="process_page_item_numbered-title">3. Lorem Ipsum</p>*/}
                    <h2>Career Interest Scores</h2>
                    <p className="process_page_item_text">Your interest level in diverse career fields is quantified to give
                        you an ultimate career score, which helps you determine which career path will be most satisfying for
                        you to pick and which career fields you could avoid.</p>
                </div>
            </div>

            <div className="process_page_item_red">
                <div className="process_page_item_imagebox">
                    <img src={CareerOptions} alt="placeholder"/>
                </div>
                <div className="process_page_item_contentbox">
                    {/*<p className="process_page_item_numbered-title">4. Lorem Ipsum</p>*/}
                    <h2>Education and Career Guidance</h2>
                    <p className="process_page_item_text">Your scores are used to identify your ideal career path and you are
                        given detailed recommendations about the soft skills you need to have and develop and the formal education
                        you need to pursue to build a successful career in your field of interest. </p>
                </div>
            </div>

            {/*Setup account*/}
            {props.user? null:<div className="process_page_setup_account_section">
                <h2>Set up your account and start exploring</h2>
                <input onChange={(e) => setEnteredEmail(e.target.value)}
                       value={enteredEmail} placeholder="Enter your email ..."/>
                {userLoading?
                    <div className="process_page_progress_container">
                        <ThemeProvider theme={progressTheme}>
                            <CircularProgress size={"2rem"} color="primary"/>
                        </ThemeProvider>
                    </div>:
                    <button onClick={() => signUpButtonHandler(enteredEmail)}>SET UP</button>
                }
            </div>}
            
            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }} style={{position: "fixed"}} open={snackBarOpen} autoHideDuration={3000} 
            onClose={handleSnackbarClose} message={snackbarMessage} key="snackbar"/>
        </div>
    )
}