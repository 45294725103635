import React, {useState} from 'react';
import './Reports.css';
import LandingImage from "../Assets/ReportPage/best-reports-main.png";
import EvalReport from '../Assets/ReportPage/eval-reports.png';
import SampleReport from '../Assets/ReportPage/sample-reports.png';
import {Link} from "react-router-dom";
import Icon1 from "../Assets/ReportPage/icon1.png";
import Icon2 from "../Assets/ReportPage/icon2.png";
import Icon3 from "../Assets/ReportPage/icon3.png";
import Icon4 from "../Assets/ReportPage/icon4.png";
import Icon5 from "../Assets/ReportPage/icon5.png";
import sampleGeneral from '../Assets/SampleIcons/sample-general-eval.png';
import sampleHumanities from '../Assets/SampleIcons/sample-humanities.png';
import sampleEngineering from '../Assets/SampleIcons/sample-engineering.png';
import sampleScience from '../Assets/SampleIcons/sample-science.png';
import sampleCommerce from '../Assets/SampleIcons/sample-commerce.png';
import {useHistory} from "react-router";
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Snackbar } from '@material-ui/core';
import firebase from '../Firebase/Firebase';

const CHECK_USER_QUERY = gql`
query MyQuery($email: String!) {
    users_aggregate(where: {email: {_eq: $email}}) {
        aggregate {
            count
            }
        }
    }`;

// Circular progress theme
const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#1E2432"},
    },
});

// EVAL REPORT FEATURE INFORMATION ITEMS
const reportFeatureData = [
    {icon: Icon1, title: "Quantitative and Descriptive Reports"},
    {icon: Icon2, title: "Graphical Representation of Interest Scores"},
    {icon: Icon3, title: "Detailed and Diverse Career Categories"},
    {icon: Icon4, title: "Complete Guidance on Education Options"},
    {icon: Icon5, title: "Exhaustive List of Job Recommendations"},
];

// DOWNLOAD SAMPLE REPORT INFORMATION ITEMS
const sampleReportData = [
    {icon: sampleGeneral, pdfIdentifier: "general", title: "General Eval"},
    {icon: sampleHumanities, pdfIdentifier: "humanities", title: "Eval for Humanities"},
    {icon: sampleEngineering, pdfIdentifier: "engineering", title: "Eval for Engineering"},
    {icon: sampleScience, pdfIdentifier: "science", title: "Eval for Science"},
    {icon: sampleCommerce, pdfIdentifier: "commerce", title: "Eval for Commerce"},
];


export default function Reports(props) {
    const [enteredEmail, setEnteredEmail] = useState("");

    const [userLoading, setUserLoading] = useState(false);


    // Snackbar states
    const [snackBarOpen, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    // Snackbar close function
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const client = useApolloClient();

    const history = useHistory();

    const downloadButtonHandler = (identifier) => {

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            console.log(xhr.response);
            const blob = xhr.response;
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.setAttribute("download", identifier + "-sample-report.pdf");
            a.click();
        };
        xhr.open('GET', "/sample-reports/"+identifier + "-sample-report.pdf");
        xhr.send();

        // const storage = firebase.storage();
        // const reference = storage.ref(identifier + "-sample-report.pdf");
        // console.log(reference);
        // reference.getDownloadURL().then(url => {
        //     console.log(url);
        //     const xhr = new XMLHttpRequest();
        //     xhr.responseType = 'blob';
        //     xhr.onload = (event) => {
        //         console.log(xhr.response);
        //         const blob = xhr.response;
        //         var a = document.createElement("a");
        //         a.href = URL.createObjectURL(blob);
        //         a.setAttribute("download", identifier + "-sample-report.pdf");
        //         a.click();
        //     };
        //     xhr.open('GET', url);
        //     xhr.send();
        // })
    };

    const signUpButtonHandler = (enteredEmail) => {
        setUserLoading(true);
        if(enteredEmail.length){
            client.query({
                query: CHECK_USER_QUERY,
                variables: {
                    email: enteredEmail
                }
            }).then((res) => {
                if(res.data.users_aggregate.aggregate.count === 1){
                    setSnackbarMessage("User with this email already exists");
                    setOpen(true);
                    setUserLoading(false);            
                } else {
                    history.push("/login/sign-up/" + enteredEmail);
                }
            }).catch(err => {
                setUserLoading(false);                
                console.log(err);
            });
        }else{
            history.push("/login/sign-up");
        }        
    };


    return (
        <div className="report_page">

            {/*Top 100vh of report page*/}
            <div className="report_page_top_row">
                <h2>Our Best in Class <span>Reports</span></h2>
                <img src={LandingImage} alt="placeholder"/>
            </div>


            {/*Items*/}
            <div className="report_page_item_blue" >
                <div className="report_page_item_imagebox">
                    <img src={EvalReport} alt="placeholder"/>
                </div>
                <div className="report_page_item_contentbox">
                    {/*<p className="report_page_item_numbered-title">1. Lorem Ipsum</p>*/}
                    <h2>Eval Reports</h2>
                    <p className="report_page_item_text">Eval Reports are holistic, detailed, and insightful. They help test
                        takers gain an in-depth understanding of their strengths, interests, work style indicators, further
                        education options, and ideal career path. </p>
                </div>
            </div>

            <div className="report_eval_feature_info_textbox_mobile">
                <h2>Eval Report Features</h2>
            </div>
            <div className="report_eval_feature_info">
                <div className="report_eval_feature_info_textbox_desktop">
                    <h2>Eval Report Features</h2>
                </div>
                {
                    reportFeatureData.map((data, index) => {
                        return(
                            <div className="report_eval_feature_info_item" key={index}>
                                <img src={data.icon} alt={data.title}/>
                                <p className="report_eval_feature_info_item_title">{data.title}</p>
                                {/*<p className="report_eval_feature_info_item_text">{data.text}</p>*/}
                                {/*<Link to={data.url} className="report_eval_feature_info_item_action-button"/>*/}
                            </div>
                        )
                    })
                }
            </div>

            {/* <div className="report_page_item_red">
                <div className="report_page_item_imagebox">
                    <img src={SampleReport} alt="placeholder"/>
                </div>
                <div className="report_page_item_contentbox">
                    <p className="report_page_item_numbered-title">2. Lorem Ipsum</p>
                    <h2>Sample Reports</h2>
                    <p className="report_page_item_text">Take a look at the Eval Reports below to know more about the detailed
                        information each report provides and pick the test that would be most appropriate for your academic
                        background and professional goals!</p>
                </div>
            </div> */}

            <div className="report_sample_download_info_textbox_mobile">
                <h2>Download Sample Reports</h2>
            </div>
            <div className="report_sample_download_info">
                <div className="report_sample_download_info_textbox_desktop">
                    <h2>Download Sample Reports</h2>
                </div>
                {
                    sampleReportData.map((data, index) => {
                        return(
                            <div className="report_sample_download_info_item" key={index}>
                                <img src={data.icon} alt={data.title}/>
                                <p className="report_sample_download_info_item_title">{data.title}</p>
                                {/*<p className="report_sample_download_info_item_text">{data.text}</p>*/}
                                {/*TODO: IMPLEMENT DOWNLOAD PDF */}
                                {/* <button className="report_sample_download_info_item_action-button"/>
                                <Link to="/sample-reports/commerce-sample-report.pdf" target="_blank" download>
                                    <button className="report_sample_download_info_item_action-button"/> 
                                </Link> */}



                                <button onClick={() => downloadButtonHandler(data.pdfIdentifier)} className="report_sample_download_info_item_action-button"/>
                            </div>
                        )
                    })
                }
            </div>

            {/*Setup account*/}
            {props.user? null: <div className="report_page_setup_account_section">
                <h2>Set up your account and start exploring</h2>
                <input onChange={(e) => setEnteredEmail(e.target.value)}
                       value={enteredEmail} placeholder="Enter your email ..."/>
                {userLoading?
                    <div className="report_page_progress_container">
                        <ThemeProvider theme={progressTheme}>
                            <CircularProgress size={"2rem"} color="primary"/>
                        </ThemeProvider>
                    </div>:
                    <button onClick={() => signUpButtonHandler(enteredEmail)}>SET UP</button>
                }
            </div>}

            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }} style={{position: "fixed"}} open={snackBarOpen} autoHideDuration={3000} 
            onClose={handleSnackbarClose} message={snackbarMessage} key="snackbar"/>
        </div>
    )
}