const GLOBAL = require('./services/global-constant');

const Razorpay = (orderId, description, user, setResponse, onDismiss) => {
    //TODO: CHANGE KEYS TO LIVE
    const options = {
        order_id: orderId,
        key: GLOBAL.RAZOR_KEY,
        name: 'Eval Test',
        description: description,
        handler: (res) => {
            setResponse(res);
        },
        "modal": {
            "ondismiss": function(){
                onDismiss();
            }
        },
        prefill: {
            name: user.name,
            email: user.email,
            contact: user.phoneNumber
        }
    };

    const openPayModal = () => {
        const rzp1 = new window.Razorpay(options);
        // console.log(rzp1);
        rzp1.open();
    };

    openPayModal();
};

export default Razorpay;