import React, {useState, useEffect} from 'react';
import './AboutTests.css';
import LandingImage from '../Assets/AboutTestsPage/test-series.png';
import General from '../Assets/AboutTestsPage/general.png';
import Humanities from '../Assets/AboutTestsPage/humanities.png';
import Engineering from '../Assets/AboutTestsPage/engineering.png';
import Science from '../Assets/AboutTestsPage/science.png';
import Commerce from '../Assets/AboutTestsPage/commerce.png';
import GeneralLogo from "../Assets/Homepage/general-eval-icon.png";
import HumanitiesLogo from "../Assets/Homepage/humanities-eval-icon.png";
import EngineeringLogo from "../Assets/Homepage/engineering-eval-icon.png";
import ScienceLogo from "../Assets/Homepage/science-eval-icon.png";
import CommerceLogo from "../Assets/Homepage/commerce-eval-icon.png";
import {useHistory, useLocation, useParams} from "react-router";
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Snackbar } from '@material-ui/core';

const CHECK_USER_QUERY = gql`
query MyQuery($email: String!) {
    users_aggregate(where: {email: {_eq: $email}}) {
        aggregate {
            count
            }
        }
    }`;

// Circular progress theme
const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#1E2432"},
    },
});

// CONSTANT HARDCODED VALUES OF TEST ID'S
const TEST_ID = [
    {name: "General Eval", id: 1, icon: GeneralLogo},
    {name: "Eval for Humanities", id: 2, icon: HumanitiesLogo},
    {name: "Eval for Engineering", id: 3, icon: EngineeringLogo},
    {name: "Eval for Science", id: 4, icon: ScienceLogo},
    {name: "Eval for Commerce", id: 5, icon: CommerceLogo},
];


export default function AboutTests (props) {
    const [enteredEmail, setEnteredEmail] = useState("");

    const [userLoading, setUserLoading] = useState(false);


    // Snackbar states
    const [snackBarOpen, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    // Snackbar close function
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const client = useApolloClient();

    const history = useHistory();

    const location = useLocation();

    useEffect(() => {
        if(location.hash){
            const testElement = document.querySelector(location.hash);
            testElement && testElement.scrollIntoView({behavior: "smooth", block: "start"});
        }        
    },[]);

    const signUpButtonHandler = (enteredEmail) => {
        setUserLoading(true);
        if(enteredEmail.length){
            client.query({
                query: CHECK_USER_QUERY,
                variables: {
                    email: enteredEmail
                }
            }).then((res) => {
                if(res.data.users_aggregate.aggregate.count === 1){
                    setSnackbarMessage("User with this email already exists");
                    setOpen(true);
                    setUserLoading(false);            
                } else {
                    history.push("/login/sign-up/" + enteredEmail);
                }
            }).catch(err => {
                setUserLoading(false);                
                console.log(err);
            });
        }else{
            history.push("/login/sign-up");
        }        
    };


    return(
        <div className="about_tests">

            {/*Top 100vh of process page*/}
            <div className="about_tests_top_row">
                <h2>Eval Test <span>Series</span></h2>
                <img src={LandingImage} alt="placeholder"/>
            </div>


            {/*Items*/}
            <div className="about_tests_item_blue" id="general">
                <div className="about_tests_item_imagebox">
                    <img src={General} alt="general eval"/>
                </div>
                <div className="about_tests_item_contentbox">
                    <h2>General Eval</h2>
                    <p className="about_tests_item_text">General Eval is for students who are over 13 years old and wish to
                        explore diverse career options. The test will evaluate their interests and strengths in various career
                        categories and make recommendations about career and further education options. The test will also
                        help them narrow down which stream is best for them, after Grade 10.</p>
                    <button className="about_tests_item_button" onClick={()=> history.push("/checkout/1")}>Get test - ₹500</button>
                </div>
            </div>

            <div className="about_tests_item_red" id="humanities">
                <div className="about_tests_item_imagebox">
                    <img src={Humanities} alt="eval for humanities"/>
                </div>
                <div className="about_tests_item_contentbox">
                    <h2>Eval for Humanities</h2>
                    <p className="about_tests_item_text">Eval for Humanities can be used by students in Grade 11 and 12 that need
                        guidance on what subject to specialise in for their Bachelor’s. It can also be used by students doing their
                        B.A or those who have completed their B.A. and are seeking guidance about the kind of jobs that would
                        be ideal for them. This test will also help students and professionals identify what subjects they could pursue a Master’s degree in.</p>
                    <button className="about_tests_item_button" onClick={()=> history.push("/checkout/2")}>Get test - ₹500</button>
                </div>
            </div>


            <div className="about_tests_item_blue" id="commerce">
                <div className="about_tests_item_imagebox">
                    <img src={Commerce} alt="eval for commerce"/>
                </div>
                <div className="about_tests_item_contentbox">
                    <h2>Eval for Commerce</h2>
                    <p className="about_tests_item_text">Eval for Commerce can be used by students in Grade 11 and 12 that
                        need guidance on what subject to specialise in for their BCom. It can also be used by students currently
                        enrolled in B.Com or those who have completed it,  and are seeking guidance about the kind of jobs
                        that would be ideal for them. The test will evaluate their interests and strengths in finance and non-finance
                        related topics and also suggest what they can do their Master’s in.</p>
                    <button className="about_tests_item_button" onClick={()=> history.push("/checkout/5")}>Get test - ₹500</button>
                </div>
            </div>


            <div className="about_tests_item_red" id="science">
                <div className="about_tests_item_imagebox">
                    <img src={Science} alt="eval for science"/>
                </div>
                <div className="about_tests_item_contentbox">
                    <h2>Eval for Science</h2>
                    <p className="about_tests_item_text">Eval for Science can be used by students in Grade 11 and 12 that need
                        guidance on what subjects to choose for their Bachelor’s. It will help them pick between studying pure
                        sciences, doing Engineering, or going into the Healthcare field. It will also help students that are
                        either pursuing or have completed their B.Sc. by recommending ideal job options and Master’s options for them.</p>
                    <button className="about_tests_item_button" onClick={()=> history.push("/checkout/4")}>Get test - ₹500</button>
                </div>
            </div>

            <div className="about_tests_item_blue" id="engineering">
                <div className="about_tests_item_imagebox">
                    <img src={Engineering} alt="eval for engineering"/>
                </div>
                <div className="about_tests_item_contentbox">
                    <h2>Eval for Engineering</h2>
                    <p className="about_tests_item_text">Eval for Engineering is a unique test specifically designed for Engineering
                        students and Engineering graduates who wish to explore career options that are not directly related
                        to Engineering. The test helps students identify fields that they could pursue a career in with their
                        Engineering degree, but not have to work as an Engineer. It also guides them about different fields they could pursue a Master’s in.</p>
                    <button className="about_tests_item_button" onClick={()=> history.push("/checkout/3")}>Get test - ₹500</button>
                </div>
            </div>

            {/*Setup account*/}
            {props.user? null: <div className="about_tests_setup_account_section">
                <h2>Set up your account and start exploring</h2>
                <input onChange={(e) => setEnteredEmail(e.target.value)}
                       value={enteredEmail} placeholder="Enter your email ..."/>
                {userLoading?
                    <div className="about_tests_progress_container">
                        <ThemeProvider theme={progressTheme}>
                            <CircularProgress size={"2rem"} color="primary"/>
                        </ThemeProvider>
                    </div>:
                    <button onClick={() => signUpButtonHandler(enteredEmail)}>SET UP</button>
                }
            </div>}

            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }} style={{position: "fixed"}} open={snackBarOpen} autoHideDuration={3000} 
            onClose={handleSnackbarClose} message={snackbarMessage} key="snackbar"/>
        </div>
    )
}