import React, {useEffect, useState} from 'react';
import './App.css';
import Home from "./Homepage/Home";
import {Switch, Route, Redirect, useLocation} from 'react-router';
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Process from "./ProcessPage/Process";
import Reports from "./ReportsPage/Reports";
import AboutTests from "./AboutTestsPage/AboutTests";
import About from "./AboutPage/About";
import Contact from "./ContactPage/Contact";
import Login from "./LoginPage/Login";
import ProfilePage from "./ProfilePage/ProfilePage";
import TestPage from "./TestPage/TestPage";
import CheckoutPage from "./CheckoutPage/CheckoutPage";
import ViewReportPage from "./ViewReportPage/ViewReportPage";
import BlogsPage from './BlogsPage/BlogsPage';
import PrivacyPolicyPage from './PolicyAndTermsPage/PrivacyPolicyPage';
import TermsOfService from './PolicyAndTermsPage/TermsOfService';
import ReportPdf from "./ViewReportPage/ReportPdf";
import MobileNav from './MobileNav/MobileNav';
import Dialog from '@material-ui/core/Dialog';
import firebase from './Firebase/Firebase';
import Slide from '@material-ui/core/Slide';
import APP from './services/app-service'
import ResetPassword from './ResetPassword/ResetPassword';
const GLOBAL = require('./services/global-constant');


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });


function App() {
    const [user, setUser] = useState(null);
    const [loadingFinished, setLoadingFinished] = useState(false);
    const [navOpen, setNavOpen] = useState(false);


    // TODO: CHECK IF THE USEFFECT WORKS IF USER IS LOGGED OUT AT START
    useEffect(() => {
        console.log("running in")
        firebase.auth().onAuthStateChanged(userAuth => {
            setUser(userAuth);
            setLoadingFinished(true);
        })
    },[]);

    const location = useLocation();

    useEffect(() => {
        setNavOpen(false);
    }, [location]); 


    if(loadingFinished) {
        return (
            <div className="App">
                <Dialog maxWidth={false} open={navOpen} onClose={() => setNavOpen(false)} TransitionComponent={Transition}>
                    <MobileNav open={navOpen} setOpen={setNavOpen} user={user}/>
                </Dialog>
                <Switch>
                    <Route path="/test" component={null} />
                    <Route path="/view-report" component={null} />
                    <Route path="/view-report-pdf" component={null} />
                    <Route path="/">
                        <Header user={APP.getRouteComponentData(GLOBAL.STORAGE.ls_token)?true:false} navOpen={navOpen} setNavOpen={setNavOpen}/>
                    </Route>
                </Switch>

                {/*--------content ---------------*/}
                {/* <div className={"main_content " + (navOpen? "navOpen" : "navClosed")}> */}
                    <Switch>
                        <Route path="/home">
                            <Home user={user}/>
                        </Route>
                        <Route path="/process">
                            <Process user={user}/>
                        </Route>
                        <Route path="/reports-info">
                            <Reports user={user}/>
                        </Route>
                        <Route path="/tests-info">
                            <AboutTests user={user}/>
                        </Route>
                        <Route path="/about">
                            <About user={user}/>
                        </Route>
                        <Route path="/contact">
                            <Contact/>
                        </Route>
                        <Route path="/blogs">
                            <BlogsPage/>
                        </Route>
                        <Route path="/privacy-policy" exact>
                            <PrivacyPolicyPage/>
                        </Route>
                        <Route path="/terms-of-service" exact>
                            <TermsOfService/>
                        </Route>
                        {/* <Route path="/test/:testId/:languageId"> */}
                        <Route path="/test/:testId">
                            <TestPage />
                        </Route>
                        {/* <Redirect to={user?"/profile": "/login"} from={user? "/login" : "/profile"}/>
                        {user? null : <Redirect to={"/login"} from={"/checkout"} />} */}
                        {/* {user? null : <Redirect to={"/home"} from={"/view-report"} />} */}
                        <Route path="/profile">
                            <ProfilePage/>
                        </Route>
                        <Route path="/login">
                            <Login/>
                        </Route>
                        <Route path="/reset-password/:token">
                            <ResetPassword/>
                        </Route>
                        <Route path={"/checkout/:testId"}>
                            <CheckoutPage/>
                        </Route>
                        <Route path="/test/:testId">
                            <TestPage />
                        </Route>
                       
                        <Route path="/view-report/:responseId">
                            <ViewReportPage/>
                        </Route>
                        <Route path="/view-report-pdf/:responseId/:languageId">
                            <ReportPdf/>
                        </Route>
                        <Redirect to="/home"/>
                    </Switch>
                {/* </div> */}
                
                {/*--------footer-----------*/}
                <Switch>
                    <Route path="/contact" component={null} />
                    <Route path="/login" component={null} />
                    <Route path="/test" component={null} />
                    <Route path="/checkout" component={null} />
                    <Route path="/view-report" component={null} />
                    <Route path="/view-report-pdf" component={null} />
                    <Route path="/">
                        <Footer/>
                    </Route>
                </Switch>
            </div>
        );
    }else return <div />
}

export default App;
