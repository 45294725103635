import React, {useEffect, useState} from 'react';
import './About.css';
import UemsLogo from '../Assets/uems-ventures-logo.png'
import Image1 from '../Assets/AboutPage/about-top-image-large.JPEG';
import Image2 from '../Assets/ProcessPage/seamless.png';
import Image from "../Assets/Homepage/placeholder-person-circle.png";
import {animated, useTransition} from "react-spring";
import {useHistory} from "react-router";
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Snackbar } from '@material-ui/core';

const CHECK_USER_QUERY = gql`
query MyQuery($email: String!) {
    users_aggregate(where: {email: {_eq: $email}}) {
        aggregate {
            count
            }
        }
    }`;

// Array containing all the logos
const imageArr = [{image: Image, id: 1}, {image: Image, id: 2},
    {image: Image, id: 3}, {image: Image, id: 4}, {image: Image, id: 5}];

// Container height
const HEIGHT = 200;

// Circular progress theme
const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#1E2432"},
    },
});

export default function About(props) {
    const [enteredEmail, setEnteredEmail] = useState("");
    const [currentItem, setCurrentItem] = useState(0); // The index of the item that disappeared to the left

    const [userLoading, setUserLoading] = useState(false);

    const [height, setHeight] = useState(200);
    
    // Add event listener that sets height of the carousel
    useEffect(() => {
        if(window.innerWidth >= ((HEIGHT + 30) * 4)){
            setHeight(HEIGHT);
        } else{
            setHeight(((window.innerWidth)/3) - 15);
        }
        const windowResizeHandler = () => {
            if(window.innerWidth >= ((HEIGHT + 30) * 4)){
                setHeight(HEIGHT);
            } else{
                setHeight(((window.innerWidth)/3) - 15);
            }
        };
        window.addEventListener('resize', windowResizeHandler);
    },[]);


    // Snackbar states
    const [snackBarOpen, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    // Snackbar close function
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const client = useApolloClient();

    const history = useHistory();

    const signUpButtonHandler = (enteredEmail) => {
        setUserLoading(true);
        if(enteredEmail.length){
            console.log("here");
            client.query({
                query: CHECK_USER_QUERY,
                variables: {
                    email: enteredEmail
                }
            }).then((res) => {
                console.log(res);
                if(res.data.users_aggregate.aggregate.count === 1){
                    setSnackbarMessage("User with this email already exists");
                    setOpen(true);
                    setUserLoading(false);            
                } else {
                    history.push("/login/sign-up/" + enteredEmail);
                }
            }).catch(err => {
                setUserLoading(false);                
                console.log(err);
            });
        }else{
            history.push("/login/sign-up");
        }        
    };



    useEffect(( )=> {
        setCurrentItem(item => {
            return (item + 1)%(imageArr.length);
        });
        const id = setInterval(() => {
            setCurrentItem(item => {
                return (item + 1)%(imageArr.length);
            })
        }, 3000);
        return(() => clearInterval(id))
    },[]);


    const transitions = useTransition(currentItem, item => item, {
        from: {left: "100%"},
        leave: {left: (window.innerWidth >= ((HEIGHT + 30) * 4))? "-25%" : "-35%"},
        config: {duration: (window.innerWidth >= ((HEIGHT + 30) * 4))? 12000 : 9000}
    });

    return(
        <div className="about_page">
            <h2 className="about_page_top_title_mobile">About Us</h2>
            {/*Top 100 vh of the page*/}
            <div className="about_page_top">                
                <div className="about_page_top_content">
                    <h2>About Us</h2>
                    <p>Eval is created by UEMS Ventures, an education consulting
                        company that offers career guidance, study abroad and
                        migration counselling. The creators of Eval have had several
                        years of experience in student counselling and have met
                        several students and professionals who were dissatisfied with
                        what they were studying or where they were working. Since,
                        individual counselling is time consuming and subjective, the
                        UEMS team sought a more logical and objective way to help
                        students and professionals make the right career decision.
                        The desire to simplify the career decision process and make it
                        more logical led to the birth of Eval Career Clarity Test Series.</p>
                    <a href="https://www.uemsventures.com/">
                        <img src={UemsLogo} alt="UEMS Ventures" />
                    </a>
                </div>
                <img src={Image1} alt="The eval team" />
            </div>

            {/*Middle section*/}
            <div className="about_page_middle_section">
                <img src={Image2} alt="eval test"/>
                <p>These tests are ​<span>easy to understand</span>, <span>affordable</span>, ​and ​<span>effective </span>
                    in providing clarity to the test taker. They are <span>grounded in data and logic</span>, and would provide a
                    <span>comprehensive and holistic</span> career report with education and job recommendations, all in realtime.
                    After research, pilot testing and ensuring its effectiveness, UEMS is confident that ​Eval Career
                    Clarity Test Series lives up to its name and can help anyone who is seeking career guidance.</p>
            </div>

            {/*Mission section*/}
            <div className="about_page_mission_section">
                <div className="about_page_mission_item">
                    <h2>Our <span>Vision</span></h2>
                    <p>To empower individuals to make an informed decision about their career path and achieve career clarity</p>
                </div>
                <div className="about_page_mission_item">
                    <h2>Our <span>Mission</span></h2>
                    <p>To demystify the career decision process by undertaking a quantitative approach to evaluate an
                        individual’s strengths and interests</p>
                </div>
            </div>

            {/*Associates section*/}
            {/* <h2 className="about_page_associates_title">Our <span>Associates</span></h2>
            <div className="about_page_associate_container" 
            style={(window.innerWidth >= ((HEIGHT + 30) * 4))?{width: ((height + 30) * 4 ) + "px", height: height + "px"}
            :{width: ((height + 15) * 3 ) + "px", height: height + "px"}}>
                {transitions.map(({item, props, key}) =>
                    <animated.img className="about_page_associate_item" src={imageArr[item].image} key={key} style={{...props, width: height}}/>
                )}
            </div> */}

            {/*Setup account section*/}
            {props.user? null: <div className="about_page_setup_account_section">
                <h2>Set up your account and start exploring</h2>
                <input onChange={(e) => setEnteredEmail(e.target.value)}
                       value={enteredEmail} placeholder="Enter your email ..."/>
                {userLoading?
                    <div className="about_page_progress_container">
                        <ThemeProvider theme={progressTheme}>
                            <CircularProgress size={"2rem"} color="primary"/>
                        </ThemeProvider>
                    </div>:
                    <button onClick={() => signUpButtonHandler(enteredEmail)}>SET UP</button>
                }
            </div>}
            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }} style={{position: "fixed"}} open={snackBarOpen} autoHideDuration={3000} 
            onClose={handleSnackbarClose} message={snackbarMessage} key="snackbar"/>
        </div>
    )
}