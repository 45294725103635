import React, {useEffect, useState} from "react";
import './TestPage.css';
import Logo from '../Assets/Eval-logo.png';
import vcuraLogo from '../Assets/Vcura-no-background.png'
import Cross from '../Assets/TestIcons/cross-button-icon.png';
import Checkbox from "@material-ui/core/Checkbox";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import GeneralLogo from '../Assets/Homepage/general-eval-icon.png';
import HumanitiesLogo from '../Assets/Homepage/humanities-eval-icon.png';
import ScienceLogo from '../Assets/Homepage/science-eval-icon.png';
import EngineeringLogo from '../Assets/Homepage/engineering-eval-icon.png';
import CommerceLogo from '../Assets/Homepage/commerce-eval-icon.png';
import {useHistory, useParams, useLocation} from "react-router";
import QuestionsSection from "./QuestionsSection";
import gql from 'graphql-tag';
import {useQuery} from "@apollo/client";
import firebase from '../Firebase/Firebase';
import {Redirect} from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import axios from 'axios';


const TEST_CATEGORIES_QUERY = gql`
    query CategoriesQuery($testId: Int!) {
        categories(where: {testId: {_eq: $testId}, isDeleted: {_eq: false}}, order_by: {id: asc}) {
            id
            name
        }
    }`;




// TEST PAGE HAS THREE PAGES CONTROLLED BY THE currPage STATE, IT COULD BE ONE OF THREE VALUES:
//     "onboarding"
//     "instructions"
//     "test"
// CONTENT IS SWITCHED ACCORDING TO THIS STATE, NO ROUTING OR HISTORY CAN BE USED DURING THE TEST


// CONSTANT HARDCODED VALUES OF TEST ID'S
const TEST_ID = [
    {name: "General Eval", id: 1, icon: GeneralLogo},
    {name: "Eval for Humanities", id: 2, icon: HumanitiesLogo},
    {name: "Eval for Engineering", id: 3, icon: EngineeringLogo},
    {name: "Eval for Science", id: 4, icon: ScienceLogo},
    {name: "Eval for Commerce", id: 5, icon: CommerceLogo},
];


// Material ui theme for the select element
const selectTheme = createMuiTheme({
    overrides: {
        MuiSelect: {
            select: {
                "&:focus": {
                    backgroundColor: "none",
                }
            },
            selectMenu: {
                backgroundColor: "#F1F1F1"
            }
        }
    }
});


// Material UI theme for circular progress bar
const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
});


// Material ui theme for the checkbox
const checkboxTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
    overrides: {
        MuiCheckbox: {
            root: {
                height: '50px',
                width: '50px'
            }
        },
        MuiSvgIcon: {
            root: {
                height: '50px',
                width: '50px',
                strokeWidth: "1px"
            }
        }
    }
});

const TestPageContent = (props) => {
    // Test page states
    const [currPage, setCurrPage] = useState("onboarding");
    const [testItem, setTestItem] = useState(TEST_ID.filter((item) => item.id === props.urlParams.testId)[0]);

    //Onboarding states
    const [location, setLocation] = useState("");
    const [yearOfStudy, setYearOfStudy] = useState("");
    const [profession, setprofession] = useState("");
    const [studyInfo, setStudyInfo] = useState("");

    const [onboardingButtonActive, setOnboardingButtonActive] = useState(false);



    // Instructions page state
    const [instructionsChecked, setInstructionsChecked] = useState(false);
    // Onboarding submit button will be active only when all other inputs are non-null
    useEffect(() =>{
        if(location && yearOfStudy && profession && studyInfo){
            setOnboardingButtonActive(true);
        } else setOnboardingButtonActive(false);
    },[location, yearOfStudy, profession, studyInfo]);

    const {data: testCategoriesData, error: testCategoriesError, loading: testCategoriesLoading} = useQuery(TEST_CATEGORIES_QUERY, {
        variables: {
            testId: testItem.id,
        }
    });

    // Change Instructions page to Test page when checkbox is checked
    useEffect(()=> {
        if(instructionsChecked){
            setTimeout(() => {
                setCurrPage("test");
            }, 1000);
        }
    },[instructionsChecked]);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currPage]);



    // Onboarding page submit action handler
    const onBoardingSubmitHandler = () => {
        setCurrPage("instructions");
    };


    let currentPage;
    switch (currPage) {

        // -------------------------- Onboarding -----------------------------------
        case "onboarding": currentPage = <div className="test_page_onboarding">
            <h2>Tell Us About Yourself</h2>
            <div className="test_page_onboarding_item">
                <p>Where are you from?</p>
                <input value={location} onChange={(e) => setLocation(e.target.value)}/>
            </div>
            <div className="test_page_onboarding_item">
                <p>Current year of study?</p>
                <input value={yearOfStudy} onChange={(e) => setYearOfStudy(e.target.value)}/>
            </div>
            <div className="test_page_onboarding_item">
                <p>Do you have a profession you're already passionate about? If yes, please mention the profession you aspire to pursue.</p>
                <input value={profession} onChange={(e) => setprofession(e.target.value)}/>
            </div>
            <div className="test_page_onboarding_item">
                <p>Are you considering studying abroad? If yes, where would you like to study? Please mention
                    the level (Bachelor's, Master's, Diploma, PhD)</p>
                <input value={studyInfo} onChange={(e) => setStudyInfo(e.target.value)}/>
            </div>
            <button onClick={() => {
                if(onboardingButtonActive){                    
                    onBoardingSubmitHandler();
                }
            }} className={onboardingButtonActive? "" : "disabled"}>PROCEED TO TEST</button>
        </div>;
            break;

        // ------------------------------- Instructions -----------------------------
        case "instructions": currentPage = <div className="test_page_instructions">
            <div className="test_page_instructions_main_title">
                <img src={testItem.icon} alt=""/>
                <h1>{testItem.name}</h1>
            </div>
            <h3>INSTRUCTIONS</h3>
            <p>The questions in this test will help you discover what career paths suit you best</p>
            <p>Please read through the following instructions before you start the test:</p>
            <p className="instruction_item">1. Select ONLY ONE option as your answer. Every question must be attempted.</p>
            <p className="instruction_item">2. When answering the questions, think about whether you are "interested"
                in the task and like doing it. Do not worry about your educational qualifications to perform a task mentioned
                in the questions. Focus on whether you would like to do that task</p>
            <p className="instruction_item">3. Answer the questions based on your own interests and do not ask others
                to answer for you. There are no right or wrong answers in this test. The test wants to see what YOU like.</p>
            <p className="instruction_item">4. If you do not understand a question, ask someone around you what it
                means or use the internet. Please answer a question only after you have understood it.</p>
            <p className="instruction_item">5. There is no time limit on the test, you can take as long as you need
                to complete the test. However, ensure that you finish the test in one sitting.</p>
            <div className="test_page_instruction_action">
                <ThemeProvider theme={checkboxTheme}>
                    <Checkbox checked={instructionsChecked} onChange={(e) => setInstructionsChecked(e.target.checked)}
                              color="primary"/>
                </ThemeProvider>
                <p>I have understood the instructions and will follow them during the test.</p>
            </div>
        </div>;
            break;

        case "test": currentPage = <QuestionsSection categoriesData={testCategoriesData&&testCategoriesData.categories}
                                                     onboardingData={{location, yearOfStudy, profession, studyInfo}}
                                                     testItem={testItem}
                                                     externalStates={props.externalStates}/>;

            break;

        default: currentPage = null;
    }

    //TODO: ERROR PAGE IMPLEMENTATION
    return(
        <div className="test_page">
            <div className="test_page_header">
                <img src={Logo} alt="Eval Logo" />
                {props.externalStates.vcura?<img src={vcuraLogo} alt="Vcura Logo" className="vcura"/>:null}
                <button onClick={() => props.openModal(true)}><img src={Cross} alt="cross" /></button>
            </div>
            <div className="test_page_content">
                {currentPage}
            </div>

        </div>
    )
};

export default function ExternalTestPage(){    
    const [modalOpen, setModalOpen] = useState(false);

    const params = new URLSearchParams(useLocation().search);
    // VCURA STATES
    const [userCareerCounsellingId, setUserCareerCounsellingId] = useState(params.get("UserCareerCounsellingId"));
    const [careerCounsellingId, setcareerCounsellingId] = useState(params.get("CareerCounsellingId"));
    const [userId, setUserId] = useState(params.get("UserId"));
    const [profileId, setProfileId] = useState(params.get("ProfileId"));
    const [profileName, setProfileName] = useState(params.get("ProfileName"));
    const [assessmentName, setAssessmentName] = useState(params.get("AssessmentName"));
    const [authorized, setAuthorized] = useState(false);
    const [invalidUser, setInvalidUSer] = useState(false);

    console.log(userCareerCounsellingId);
    console.log(careerCounsellingId);
    console.log(userId);
    console.log(profileId);
    console.log(profileName);
    console.log(assessmentName);

    // URL parameters
    let {testId} = useParams();

    const history = useHistory();

    useEffect(() => {       
        const authenticate = () => {
            axios({
                method: 'GET',
                url: ('https://api.vcura.in/Counselling/CheckUserExists?UserCareerCounsellingId='+ 
                userCareerCounsellingId + '&UserId=' + userId),
                crossDomain: true,
                headers: {
                    "accept": "application/json",
                    "Authorization" : "Bearer 5c91f2e6af5843299a514d7047b495d1:YmI4MDUwZDUzYjU3ZTcxOA==:" + Math.trunc(new Date().getTime()/100),
                    "cache-control": "no-cache"
                }
            }).then(res => {
                console.log(res);
                if(res.data.Status === 1 && res.data.Result === true && res.data.ErrorMessage === null){
                    setAuthorized(true);
                    console.log("authorized");
                } else {
                    setInvalidUSer(true);
                }
            }).catch(error => {
                console.log(error);
                authenticate();
            }); 
        };
        if(userCareerCounsellingId && careerCounsellingId && userId && profileId && profileName && assessmentName){
            // authenticate();
            axios({
                method: 'GET',
                url: ('https://api.vcura.in/Counselling/CheckUserExists?UserCareerCounsellingId='+ 
                userCareerCounsellingId + '&UserId=' + userId),
                crossDomain: true,        
                headers: {
                    // "accept": "application/json",
                    "Authorization" : "Bearer 5c91f2e6af5843299a514d7047b495d1:YmI4MDUwZDUzYjU3ZTcxOA==:" + Math.trunc(new Date().getTime()/100),
                    // "cache-control": "no-cache"
                }
            
            }).then(res => {
                console.log(res);
                if(res.data.Status === 1 && res.data.Result === true && res.data.ErrorMessage === null){
                    setAuthorized(true);
                    console.log("authorized");
                } else {
                    setInvalidUSer(true);                     
                }
            }).catch(err => {
                console.log(err);
            })           
        } 
    },[userCareerCounsellingId, careerCounsellingId, userId, profileId, profileName, assessmentName]);
    

    const modalBody = <div className="test_page_close_dialog">
        <h2>Close the test?</h2>
        <p>You will lose all progress and will have to reattempt the test from the beginning.</p>
        <div className="test_page_close_dialog_buttons">
            <button className="button_1" onClick={() => history.push("/home")}>CONFIRM</button>
            <button className="button_2" onClick={() => setModalOpen(false)}>CANCEL</button>
        </div>
    </div>;

    if(invalidUser) return <Redirect to={"/test/"+ testId} />
    else {
        if(authorized){
            return [
                <Dialog maxWidth={false} open={modalOpen} onClose={() => setModalOpen(false)}>
                    {modalBody}
                </Dialog>,
                <TestPageContent openModal={setModalOpen} urlParams={{testId: parseInt(testId)}} externalStates={{
                    vcura: (userCareerCounsellingId && careerCounsellingId && userId && profileId && profileName && assessmentName)?
                        {
                            userCareerCounsellingId,
                            careerCounsellingId,
                            userId,
                            profileId,
                            profileName, 
                            assessmentName
                        }: null
                }}/>
            ];
        } 
        else {
            return <div className="test_page_progress_container">
            <ThemeProvider theme={progressTheme}>
                <CircularProgress size={"2.5rem"} color="primary"/>
            </ThemeProvider>
        </div>;
        }
    }
}