import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDn70J0aO4fFRNekR-4vMKwONgqVx_YRM8",
    authDomain: "evaltest-dev.firebaseapp.com",
    databaseURL: "https://evaltest-dev.firebaseio.com",
    projectId: "evaltest-dev",
    storageBucket: "evaltest-dev.appspot.com",
    messagingSenderId: "1024552574905",
    appId: "1:1024552574905:web:4c4b92fed4ddd5e9cee068",
    measurementId: "G-TTYRGP9988"
};

export default firebase.initializeApp(firebaseConfig);