import React from 'react';
import './BrowseBlogs.css';
import {Link} from 'react-router-dom';
import careervsskills from '../Assets/BlogsPage/careervsskills.jpg';
import ikigai from '../Assets/BlogsPage/ikigai.png';
import CareerAfter12th from '../Assets/BlogsPage/career-after-12th.jpg';
import CareerInterestAssessments from '../Assets/BlogsPage/career-interest-assessments.png';
import TopFiveFactors from '../Assets/BlogsPage/top-5-factors-career-selection.jpg';
import SafetyNet from '../Assets/BlogsPage/education-safety-net.jpg';

// Keep this in sync with items in ViewBlog page
const BLOGS_LIST_DATA = [
    {
        img: careervsskills,
        title: "Introduction to Career Exploration",
        text: "Your profession is the primary identity you develop as an adult.",
        url: "/blogs/read/career-vs-skills"
    },
    {
        img: ikigai,
        title: "How to find your career path?",
        text: "The ancient Japanese concept Ikigai is an insightful model to explore one’s life’s purpose and understand...",
        url: "/blogs/read/how-to-find-your-career-path"
    },
    {
        img: CareerAfter12th,
        title: "Which Career to Choose after 12th?",
        text: "The right career for you is the one where your skills, interests, and personality align with the requirements and responsibilities of the job.",
        url: "/blogs/read/which-career-to-choose-after-12th"
    },
    {
        img: CareerInterestAssessments,
        title: "What are career interest assessments?",
        text: "Career Interest Assessments (CIA) are a type of tool used to help people seeking clarity on which career path to pick.",
        url: "/blogs/read/what-are-career-interest-assessments"
    },
    {
        img: TopFiveFactors,
        title: "Top 5 factors to consider when selecting your career",
        text: "There will always exist a difference of opinion on which factors are most important to consider when picking a career. Here are our top 5 picks for you!",
        url: "/blogs/read/top-5-factors-to-consider-when-selecting-your-career"
    },
    {
        img: SafetyNet,
        title: "How to make Education your safety net?",
        text: "Acquiring transferable skills are essential towards ensuring you’re employable…",
        url: "/blogs/read/how-to-make-education-your-safety-net"
    },    
];


export default function BrowseBlogs(){
    return(
        <div className="browse_blogs_page">
            <div className="browse_blogs_page_title">
                <div className="browse_blogs_page_title_content">
                    <h2>Blogs</h2>
                    <p>Shaping careers with eval</p>
                </div>                
            </div>
            <div className="browse_blogs_page_list">
                {
                    BLOGS_LIST_DATA.map((item, index) => 
                    <div className="blog_item" key={"blog_item" + index}>
                        <img src={item.img} alt={item.title}/>
                        <div className="blog_item_content">
                            <h2>{item.title}</h2>
                            <p>{item.text}</p>
                            <Link to={item.url}>Read Blog</Link>
                        </div>
                    </div>)
                }                
            </div>
        </div>
    )
}