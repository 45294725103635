const GLOBAL = require('../services/global-constant');

const App = {
    getToken:function(){
        return {
            headers:{
                Authorization: "Bearer "+localStorage.getItem(GLOBAL.STORAGE.ls_token),
            }
          };
    },
    storeRouteComponentData:function(key, value) {
        localStorage.setItem(key,value);
    },
    getRouteComponentData:function(key) {
        const valueData = localStorage.getItem( key);
        return valueData ? valueData:'';
    },
    removeRouteComponentData:function(key) {
        localStorage.removeItem(key);
    },
    getDateFormat:function(inDate){
        var d = new Date(inDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }
}

export default App;