import React from 'react';


// TestOptionButton can be of 4 types, determined by the type prop supplied
//     type = 0: NotInterested
//     type = 1: SlightlyInterested
//     type = 2: Interested
//     type = 3: VeryInterested



const COLOR = "#75ADCC";


const VeryInterested = ({selected, ...props}) => {
    return <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props} >
        <circle cx={100} cy={100} r={98} strokeWidth="1px" stroke={selected? "none" : "#E7E7EA"} fill={!selected? "white" : COLOR}/>
        <g viewBox="0 0 122 122" transform="translate(39 39)">
            <g >
                <path d="M81.479,123.9H45.521A43.024,43.024,0,0,1,2.5,80.879V44.921A43.024,43.024,0,0,1,45.521,1.9H81.479A43.024,43.024,0,0,1,124.5,44.921V80.751A43.133,43.133,0,0,1,81.479,123.9ZM45.521,5.881a39.1,39.1,0,0,0-39.04,39.04V80.751a39.023,39.023,0,0,0,39.04,39.04H81.479a39.023,39.023,0,0,0,39.04-39.04V44.921a39.1,39.1,0,0,0-39.04-39.04Z" transform="translate(-2.5 -1.9)" fill={selected? "white" : COLOR}/>
            </g>
            <g transform="translate(19.152 26.455)">
                <path d="M45.264,34.058A1.142,1.142,0,0,0,44.621,32H36.274a.977.977,0,0,1-1.027-.771l-2.568-7.962a1.158,1.158,0,0,0-2.183,0l-2.568,7.962A1.076,1.076,0,0,1,26.9,32H18.552a1.121,1.121,0,0,0-.642,2.055l6.678,4.88a1.162,1.162,0,0,1,.385,1.284L22.4,48.184a1.168,1.168,0,0,0,1.8,1.284l6.678-4.88a1.166,1.166,0,0,1,1.284,0l6.678,4.88a1.182,1.182,0,0,0,1.8-1.284l-2.568-7.962a1.162,1.162,0,0,1,.385-1.284Z" transform="translate(-17.413 -22.5)" fill={selected? "white" : COLOR}/>
            </g>
            <g transform="translate(74.501 26.455)">
                <path d="M88.364,34.058A1.142,1.142,0,0,0,87.721,32H79.374a.977.977,0,0,1-1.027-.771l-2.568-7.962a1.158,1.158,0,0,0-2.183,0l-2.568,7.962A1.076,1.076,0,0,1,70,32H61.652a1.121,1.121,0,0,0-.642,2.055l6.678,4.88a1.162,1.162,0,0,1,.385,1.284L65.5,48.184a1.168,1.168,0,0,0,1.8,1.284l6.678-4.88a1.166,1.166,0,0,1,1.284,0l6.678,4.88a1.182,1.182,0,0,0,1.8-1.284l-2.568-7.962a1.162,1.162,0,0,1,.385-1.284Z" transform="translate(-60.513 -22.5)" fill={selected? "white" : COLOR}/>
            </g>
            <g transform="translate(33.252 64.082)">
                <path d="M81.183,51.8H31.1a2.773,2.773,0,0,0-2.7,2.954C29.044,61.046,33.154,75.3,56.012,75.3S82.981,61.046,83.623,54.754A2.446,2.446,0,0,0,81.183,51.8ZM56.141,72.476c-6.806,0-11.943-1.284-15.924-3.211a11.664,11.664,0,0,1,6.421-4.623c7.192-1.541,9.375.642,9.375.642s2.183-2.183,9.375-.642a9.879,9.879,0,0,1,6.421,4.623C68.084,71.192,62.947,72.476,56.141,72.476Z" transform="translate(-28.393 -51.8)" fill={selected? "white" : COLOR}/>
            </g>
        </g>
    </svg>
};

const Interested = ({selected, ...props}) => {
    return <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props} >
        <circle cx={100} cy={100} r={98} strokeWidth="1px" stroke={selected? "none" : "#E7E7EA"} fill={!selected? "white" : COLOR}/>
        <g viewBox="0 0 122 122" transform="translate(39 39)">
            <g>
                <path d="M81.479,124.5H45.521A43.024,43.024,0,0,1,2.5,81.479V45.521A43.024,43.024,0,0,1,45.521,2.5H81.479A43.024,43.024,0,0,1,124.5,45.521V81.479A43.024,43.024,0,0,1,81.479,124.5ZM45.521,6.481a39.188,39.188,0,0,0-39.04,39.04V81.479a39.023,39.023,0,0,0,39.04,39.04H81.479a39.023,39.023,0,0,0,39.04-39.04V45.521a39.188,39.188,0,0,0-39.04-39.04Z" transform="translate(-2.5 -2.5)" fill={selected? "white" : COLOR}/>
            </g>
            <g transform="translate(77.438 39.939)">
                <path d="M82.834,43.1a1,1,0,0,1-1.8.514,11.714,11.714,0,0,0-3.082-3.082,9.332,9.332,0,0,0-10.274,0A10.061,10.061,0,0,0,64.6,43.617a1,1,0,0,1-1.8-.514,9.758,9.758,0,0,1,1.8-5.522A10.349,10.349,0,0,1,72.688,33.6a10.16,10.16,0,0,1,8.091,3.981A7.293,7.293,0,0,1,82.834,43.1Z" transform="translate(-62.8 -33.6)" fill={selected? "white" : COLOR}/>
            </g>
            <g transform="translate(38.912 65.256)">
                <path d="M74.408,54.323a39.232,39.232,0,0,1-19.52,5.651,39.233,39.233,0,0,1-19.52-5.651c-1.926-1.284-2.568-1.156-2.568-.642,0,5.651,11.044,9.118,22.088,9.118s22.088-3.467,22.088-9.118C76.977,53.168,76.335,53.039,74.408,54.323Z" transform="translate(-32.8 -53.314)" fill={selected? "white" : COLOR}/>
            </g>
            <g transform="translate(24.4 40.039)">
                <path d="M41.533,42.581a1,1,0,0,1-1.8.514,11.714,11.714,0,0,0-3.082-3.082,9.332,9.332,0,0,0-10.274,0A10.06,10.06,0,0,0,23.3,43.095a1,1,0,0,1-1.8-.514,9.758,9.758,0,0,1,1.8-5.522,10.349,10.349,0,0,1,8.09-3.981,10.16,10.16,0,0,1,8.09,3.981A8.443,8.443,0,0,1,41.533,42.581Z" transform="translate(-21.5 -33.079)" fill={selected? "white" : COLOR}/>
            </g>
        </g>
    </svg>
};

const SlightlyInterested = ({selected, ...props}) => {
    return <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props} >
        <circle cx={100} cy={100} r={98} strokeWidth="1px" stroke={selected? "none" : "#E7E7EA"} fill={!selected? "white" : COLOR}/>
        <g viewBox="0 0 122 122" transform="translate(39 39)">
            <g>
                <path d="M81.479,123.9H45.521A43.024,43.024,0,0,1,2.5,80.879V44.921A43.024,43.024,0,0,1,45.521,1.9H81.479A43.024,43.024,0,0,1,124.5,44.921V80.751A43.133,43.133,0,0,1,81.479,123.9ZM45.521,5.881a39.1,39.1,0,0,0-39.04,39.04V80.751a39.023,39.023,0,0,0,39.04,39.04H81.479a39.023,39.023,0,0,0,39.04-39.04V44.921a39.1,39.1,0,0,0-39.04-39.04Z" transform="translate(-2.5 -1.9)" fill={selected? "white" : COLOR}/>
            </g>
            <g transform="translate(24.4 40.039)">
                <path d="M41.533,42.581a1,1,0,0,1-1.8.514,11.714,11.714,0,0,0-3.082-3.082,9.332,9.332,0,0,0-10.274,0A10.06,10.06,0,0,0,23.3,43.095a1,1,0,0,1-1.8-.514,9.758,9.758,0,0,1,1.8-5.522,10.349,10.349,0,0,1,8.09-3.981,10.16,10.16,0,0,1,8.09,3.981A8.443,8.443,0,0,1,41.533,42.581Z" transform="translate(-21.5 -33.079)" fill={selected? "white" : COLOR}/>
            </g>
            <g transform="translate(77.437 40.039)">
                <path d="M82.833,42.581a1,1,0,0,1-1.8.514,11.714,11.714,0,0,0-3.082-3.082,9.332,9.332,0,0,0-10.274,0A10.061,10.061,0,0,0,64.6,43.095a1,1,0,0,1-1.8-.514,9.758,9.758,0,0,1,1.8-5.522,10.349,10.349,0,0,1,8.09-3.981,10.16,10.16,0,0,1,8.09,3.981A7.293,7.293,0,0,1,82.833,42.581Z" transform="translate(-62.8 -33.079)" fill={selected? "white" : COLOR}/>
            </g>
            <g transform="translate(31.592 74.778)">
                <ellipse cx="29.408" cy="1.541" rx="29.408" ry="1.541" fill={selected? "white" : COLOR}/>
            </g>
        </g>
    </svg>
};

const NotInterested = ({selected, ...props}) => {
    return <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props} >
        <circle cx={100} cy={100} r={98} strokeWidth="1px" stroke={selected? "none" : "#E7E7EA"} fill={!selected? "white" : COLOR}/>
        <g viewBox="0 0 122 122" transform="translate(39 39)">
            <g>
                <path d="M81.222,124.5H45.778A43.3,43.3,0,0,1,2.5,81.222V45.778A43.3,43.3,0,0,1,45.778,2.5H81.222A43.3,43.3,0,0,1,124.5,45.778V81.222A43.3,43.3,0,0,1,81.222,124.5ZM45.778,7.765A38.091,38.091,0,0,0,7.765,45.778V81.222a38.091,38.091,0,0,0,38.013,38.013H81.222a38.091,38.091,0,0,0,38.013-38.013V45.778A38.091,38.091,0,0,0,81.222,7.765Z" transform="translate(-2.5 -2.5)" fill={selected? "white" : COLOR}/>
            </g>
            <g transform="translate(26.093 37.192)">
                <path d="M45.012,38.446l-3.082-1.284c-1.8-.771-3.724-1.541-5.651-2.183L30.5,33.052a44.273,44.273,0,0,0-5.907-1.541h0a1.285,1.285,0,0,0-1.027,2.312h0c1.669,1.027,3.467,2.055,5.265,3.082,1.027.514,1.926,1.027,2.954,1.541a1.328,1.328,0,0,1,0,2.312c-1.027.514-2.055,1.027-2.954,1.541a43.356,43.356,0,0,0-5.265,3.082h-.128a1.277,1.277,0,0,0,1.027,2.312h.128c1.926-.514,3.981-1.027,5.907-1.541l5.779-1.926A24.981,24.981,0,0,1,39.1,43.2l2.825-1.156,3.082-1.284C46.039,40.5,46.039,38.96,45.012,38.446Z" transform="translate(-22.818 -31.461)" fill={selected? "white" : COLOR}/>
            </g>
            <g transform="translate(72.943 37.192)">
                <path d="M60.071,38.446l3.082-1.284c1.8-.771,3.724-1.541,5.651-2.183l5.779-1.926a44.273,44.273,0,0,1,5.907-1.541h0a1.285,1.285,0,0,1,1.027,2.312h0c-1.669,1.027-3.467,2.055-5.265,3.082-1.027.514-1.926,1.027-2.954,1.541a1.328,1.328,0,0,0,0,2.312c1.027.514,2.055,1.027,2.954,1.541a43.355,43.355,0,0,1,5.265,3.082h.128a1.277,1.277,0,0,1-1.027,2.312h-.128c-1.926-.514-3.981-1.027-5.907-1.541L68.8,44.225A24.981,24.981,0,0,0,65.978,43.2l-2.825-1.156-3.082-1.284C59.043,40.5,59.043,38.96,60.071,38.446Z" transform="translate(-59.3 -31.461)" fill={selected? "white" : COLOR}/>
            </g>
            <g transform="translate(48.671 56.762)">
                <path d="M63.387,61.212a1.7,1.7,0,0,1-1.669-1.669s0-4.366-2.568-7.063a8.436,8.436,0,0,0-6.293-2.312,8.436,8.436,0,0,0-6.293,2.312c-2.825,2.7-2.825,6.935-2.825,7.063a1.669,1.669,0,1,1-3.339,0c0-.257-.128-5.779,3.6-9.5A11.911,11.911,0,0,1,52.729,46.7a11.911,11.911,0,0,1,8.733,3.339c3.724,3.724,3.6,9.246,3.6,9.5A1.7,1.7,0,0,1,63.387,61.212Z" transform="translate(-40.4 -46.7)" fill={selected? "white" : COLOR}/>
            </g>
        </g>
    </svg>
};


export default function TestOptionButton({type, ...props}){

    const getButton = (buttonType) => {
        switch(buttonType){
            case 0: return <NotInterested {...props}/>;

            case 1: return <SlightlyInterested {...props} />;

            case 2: return <Interested {...props} />;

            case 3: return <VeryInterested {...props} />;

            default: return <NotInterested {...props}/>;
        }
    };

    const button = getButton(type);


    return(
        <button className="test-option">
            {button}
        </button>
    )
}