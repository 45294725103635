import React, {useEffect, useState} from "react";
import './TestPage.css';
import Logo from '../Assets/Eval-logo.png';
import Cross from '../Assets/TestIcons/cross-button-icon.png';
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import GeneralLogo from '../Assets/Homepage/general-eval-icon.png';
import HumanitiesLogo from '../Assets/Homepage/humanities-eval-icon.png';
import ScienceLogo from '../Assets/Homepage/science-eval-icon.png';
import EngineeringLogo from '../Assets/Homepage/engineering-eval-icon.png';
import CommerceLogo from '../Assets/Homepage/commerce-eval-icon.png';
import TestOptionButton from "./TestOptionButton";
import {useHistory, useParams, useLocation} from "react-router";
import QuestionsSection from "./QuestionsSection";
import gql from 'graphql-tag';
import {useQuery} from "@apollo/client";
import firebase from '../Firebase/Firebase';
import {Redirect} from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import ExternalTestPage from './ExternalTestPage';
import APP from '../services/app-service'
const GLOBAL = require('../services/global-constant');
const axios = require('axios').default;

const TEST_PURCHASED_CHECK_QUERY = gql`
    query TestPurchasedCheck($email: String!, $testId: Int!) {
        purchases(where: {purchaseStatusId: {_eq: 2}, user: {email: {_eq: $email}}, testId: {_eq: $testId}}) {
            id
            responses {
              id
            }
        }
    }`;

const TEST_QUESTIONS_QUERY = gql`
    query QuestionsQuery($testId: Int!, $languageId: Int!) {
        questions(where: {testId: {_eq: $testId}, isDeleted: {_eq: false}}, order_by: {priority: asc}) {
            categoryId
            question_languages(where: {languageId: {_eq: $languageId}}) {
                question
            }
        }
    }`;

const TEST_CATEGORIES_QUERY = gql`
    query CategoriesQuery($testId: Int!) {
        categories(where: {testId: {_eq: $testId}, isDeleted: {_eq: false}}, order_by: {id: asc}) {
            id
            name
        }
    }`;




// TEST PAGE HAS THREE PAGES CONTROLLED BY THE currPage STATE, IT COULD BE ONE OF THREE VALUES:
//     "onboarding"
//     "instructions"
//     "test"
// CONTENT IS SWITCHED ACCORDING TO THIS STATE, NO ROUTING OR HISTORY CAN BE USED DURING THE TEST


// CONSTANT HARDCODED VALUES OF TEST ID'S
const TEST_ID = [
    {name: "General Eval", id: 1, icon: GeneralLogo},
    {name: "Eval for Humanities", id: 2, icon: HumanitiesLogo},
    {name: "Eval for Engineering", id: 3, icon: EngineeringLogo},
    {name: "Eval for Science", id: 4, icon: ScienceLogo},
    {name: "Eval for Commerce", id: 5, icon: CommerceLogo},
];


// Material ui theme for the select element
const selectTheme = createMuiTheme({
    overrides: {
        MuiSelect: {
            select: {
                "&:focus": {
                    backgroundColor: "none",
                }
            },
            selectMenu: {
                backgroundColor: "#F1F1F1"
            }
        }
    }
});


// Material UI theme for circular progress bar
const progressTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
});


// Material ui theme for the checkbox
const checkboxTheme = createMuiTheme({
    palette: {
        primary: {500: "#024E85"},
    },
    overrides: {
        MuiCheckbox: {
            root: {
                height: '50px',
                width: '50px'
            }
        },
        MuiSvgIcon: {
            root: {
                height: '50px',
                width: '50px',
                strokeWidth: "1px"
            }
        }
    }
});

const TestPageContent = (props) => {
    // Test page states
    const [currPage, setCurrPage] = useState("onboarding");
    const [testItem, setTestItem] = useState(TEST_ID.filter((item) => item.id === props.urlParams.testId)[0]);

    //Onboarding states
    const [location, setLocation] = useState("");
    const [yearOfStudy, setYearOfStudy] = useState("");
    const [profession, setprofession] = useState("");
    const [studyInfo, setStudyInfo] = useState("");

    const [onboardingButtonActive, setOnboardingButtonActive] = useState(false);

    const [testCategoriesData, setTestCategoriesData] = useState(null);
    const [testCategoriesError, setTestCategoriesError] = useState(false);
    const [testCategoriesLoading, setTestCategoriesLoading] = useState(false);

    // Instructions page state
    const [instructionsChecked, setInstructionsChecked] = useState(false);
    // Onboarding submit button will be active only when all other inputs are non-null
    useEffect(() =>{
        if(location && yearOfStudy && profession && studyInfo){
            setOnboardingButtonActive(true);
        } else setOnboardingButtonActive(false);
    },[location, yearOfStudy, profession, studyInfo]);


    // Test page states
    // const {data: testQuestionsData, error: testQuestionsError, loading: testQuestionsLoading} = useQuery(TEST_QUESTIONS_QUERY, {
    //     variables: {
    //         testId: testItem.id,
    //         languageId: props.urlParams.languageId
    //     }
    // });



    // const {data: testCategoriesData, error: testCategoriesError, loading: testCategoriesLoading} = useQuery(TEST_CATEGORIES_QUERY, {
    //     variables: {
    //         testId: testItem.id,
    //     }
    // });

    useEffect(() => {
        setTestCategoriesLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getTestCategories, {
            testId: parseInt(props.urlParams.testId),
      },APP.getToken())
      .then(function (response) {
        console.log("getTestCategories response",response);
        if(!response.data.error){
            setTestCategoriesData(response.data)
            setTestCategoriesError(false);
            setTestCategoriesLoading(false);
        }else{
            setTestCategoriesError(true);
            // setSnackbarMessage(response.data.message);
            // setSnackbarOpen(true);
        }
      })
      .catch(function (error) {
        console.log("error",error);
        setTestCategoriesLoading(false);
      });
    }, [testItem]);

    // Change Instructions page to Test page when checkbox is checked
    useEffect(()=> {
        if(instructionsChecked){
            setTimeout(() => {
                setCurrPage("test");
            }, 1000);
        }
    },[instructionsChecked]);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currPage]);



    // Onboarding page submit action handler
    const onBoardingSubmitHandler = () => {
        setCurrPage("instructions");
    };


    let currentPage;
    switch (currPage) {

        // -------------------------- Onboarding -----------------------------------
        case "onboarding": currentPage = <div className="test_page_onboarding">
            <h2>Tell Us About Yourself</h2>
            <div className="test_page_onboarding_item">
                <p>Where are you from?</p>
                <input value={location} onChange={(e) => setLocation(e.target.value)}/>
            </div>
            <div className="test_page_onboarding_item">
                <p>Current year of study?</p>
                <input value={yearOfStudy} onChange={(e) => setYearOfStudy(e.target.value)}/>
            </div>
            <div className="test_page_onboarding_item">
                <p>Do you have a profession you're already passionate about? If yes, please mention the profession you aspire to pursue.</p>
                <input value={profession} onChange={(e) => setprofession(e.target.value)}/>
            </div>
            <div className="test_page_onboarding_item">
                <p>Are you considering studying abroad? If yes, where would you like to study? Please mention
                    the level (Bachelor's, Master's, Diploma, PhD)</p>
                <input value={studyInfo} onChange={(e) => setStudyInfo(e.target.value)}/>
            </div>
            <button onClick={() => {
                if(onboardingButtonActive){
                    onBoardingSubmitHandler();
                }
            }} className={onboardingButtonActive? "" : "disabled"}>PROCEED TO TEST</button>
        </div>;
            break;

        // ------------------------------- Instructions -----------------------------
        case "instructions": currentPage = <div className="test_page_instructions">
            <div className="test_page_instructions_main_title">
                <img src={testItem.icon} alt=""/>
                <h1>{testItem.name}</h1>
            </div>
            <h3>INSTRUCTIONS</h3>
            <p>The questions in this test will help you discover what career paths suit you best</p>
            <p>Please read through the following instructions before you start the test:</p>
            <p className="instruction_item">1. Select ONLY ONE option as your answer. Every question must be attempted.</p>
            <p className="instruction_item">2. When answering the questions, think about whether you are "interested"
                in the task and like doing it. Do not worry about your educational qualifications to perform a task mentioned
                in the questions. Focus on whether you would like to do that task</p>
            <p className="instruction_item">3. Answer the questions based on your own interests and do not ask others
                to answer for you. There are no right or wrong answers in this test. The test wants to see what YOU like.</p>
            <p className="instruction_item">4. If you do not understand a question, ask someone around you what it
                means or use the internet. Please answer a question only after you have understood it.</p>
            <p className="instruction_item">5. There is no time limit on the test, you can take as long as you need
                to complete the test. However, ensure that you finish the test in one sitting.</p>
            <div className="test_page_instruction_action">
                <ThemeProvider theme={checkboxTheme}>
                    <Checkbox checked={instructionsChecked} onChange={(e) => setInstructionsChecked(e.target.checked)}
                              color="primary"/>
                </ThemeProvider>
                <p>I have understood the instructions and will follow them during the test.</p>
            </div>
        </div>;
            break;

        // ----------------------------- Test page --------------------------------
        // case "test": currentPage = <QuestionsSection questionsData={testQuestionsData&&testQuestionsData.questions}
        //                                              categoriesData={testCategoriesData&&testCategoriesData.categories}
        //                                              onboardingData={{location, yearOfStudy, profession, studyInfo}}
        //                                              testItem={testItem}
        //                                              purchaseId={props.purchaseId}/>;

        case "test": currentPage = <QuestionsSection categoriesData={testCategoriesData&&testCategoriesData.categories}
                                                     onboardingData={{location, yearOfStudy, profession, studyInfo}}
                                                     testItem={testItem}
                                                     purchaseId={props.purchaseId}/>;

            break;

        default: currentPage = null;
    }

    //TODO: ERROR PAGE IMPLEMENTATION
    return(
        <div className="test_page">
            <div className="test_page_header">
                <img src={Logo} alt="Eval Logo" />
                <button onClick={() => props.openModal(true)}><img src={Cross} alt="cross" /></button>
            </div>
            <div className="test_page_content">
                {/* {testQuestionsLoading? <div className="profile_page_progress_container">
                    <ThemeProvider theme={progressTheme}>
                        <CircularProgress size={30} color="primary"/>
                    </ThemeProvider>
                </div>:null} */}
                {currentPage}
                {/* {testQuestionsData? currentPage : null} */}
            </div>

        </div>
    )
};

export default function TestPage(){

    const [userEmail, setUserEmail] = '';//useState(firebase.auth().currentUser&&firebase.auth().currentUser.email);
    const [purchaseId, setPurchaseId] = useState(null); //ID of the valid test purchase associated with test session
    const [modalOpen, setModalOpen] = useState(false);

    const location = useLocation();    

    //TEST
    const [testPurchaseData, setTestPurchaseData] = useState(null);
    const [testPurchaseError, setTestPurchaseError] = useState(false);
    const [testPurchaseLoading, setTestPurchaseLoading] = useState(false);

    // URL parameters
    let {testId} = useParams();

    const history = useHistory();

    // const {data: testPurchaseData ,  error: testPurchaseError, loading: testPurchaseLoading} = useQuery(TEST_PURCHASED_CHECK_QUERY,{
    //     variables: {
    //         email: userEmail,
    //         testId: testId
    //     }
    // });

    // Filter all purchases to find the valid purchase item and bind its id to the state
   
    useEffect(() => {
        setTestPurchaseLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getTestPurchasedCheck, {
            testId: parseInt(testId),
      },APP.getToken())
      .then(function (response) {
        console.log("getTestPurchasedCheck response",response);
        if(!response.data.error){
            setTestPurchaseData(response.data)
            if(response.data.purchases.length&&response.data.purchases.filter((item) => !item.responses.length)[0]){
                setPurchaseId(response.data.purchases.filter((item) => !item.responses.length)[0].id);
            }
            setTestPurchaseError(false);
            setTestPurchaseLoading(false);
        }else{
            setTestPurchaseError(true);
            // setSnackbarMessage(response.data.message);
            // setSnackbarOpen(true);
        }
      })
      .catch(function (error) {
        console.log("error",error);
        setTestPurchaseLoading(false);
      });
    }, [testId]);

    // useEffect(() => {
    //     if(testPurchaseData&&testPurchaseData.purchases.length&&testPurchaseData.purchases.filter((item) => !item.responses.length)[0]){
    //         setPurchaseId(testPurchaseData.purchases.filter((item) => !item.responses.length)[0].id);
    //     }
    // },[testPurchaseData]);

    const modalBody = <div className="test_page_close_dialog">
        <h2>Close the test?</h2>
        <p>You will lose all progress and will have to reattempt the test from the beginning.</p>
        <div className="test_page_close_dialog_buttons">
            <button className="button_1" onClick={() => history.push("/profile")}>CONFIRM</button>
            <button className="button_2" onClick={() => setModalOpen(false)}>CANCEL</button>
        </div>
    </div>;
    // If url has search parameters treat it like a vcura user
    if(location.search) return <ExternalTestPage />;

    // Non vcura, normal workflow
    if(testPurchaseLoading){
        return <div className="test_page_progress_container">
        <ThemeProvider theme={progressTheme}>
            <CircularProgress size={"2.5rem"} color="primary"/>
        </ThemeProvider>
    </div>;
    } 
    //testPurchaseError || (!testPurchaseData.purchases.length || !testPurchaseData.purchases.filter((item) => !item.responses.length).length)
    else if(testPurchaseData && (testPurchaseError || (!testPurchaseData.purchases.length || !testPurchaseData.purchases.filter((item) => !item.responses.length).length))){
        //TODO : IMPLEMENT AN ERROR 404 PAGE OR SOMETHING
        return <Redirect to={"/profile"} />
    }
    else return(
        [
            <Dialog maxWidth={false} open={modalOpen} onClose={() => setModalOpen(false)}>
                {modalBody}
            </Dialog>,
            <TestPageContent openModal={setModalOpen} urlParams={{testId: parseInt(testId)}} purchaseId={purchaseId}/>
        ]
    )
}